import React, {useState, useContext} from "react";
import {
  Typography,
  Modal,
  Button,
  TextField,
  Box,
  Stack,
  Select,
  MenuItem,
  Snackbar,
  FormHelperText,
  FormControl,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useAxios from "../../utils/useAxios";
import { useDispatch } from "react-redux";
import { fetchDashboardUsers } from "../Reducers/dashboardUserReducer";
import { fetchTeamData, fetchTeamRawData } from "../Reducers/teamReducer";
import AuthContext from "../../context/AuthContext";
import DeleteEmployeeButton from "../DeleteEmployeeButton/DeleteEmployeeButton";

export default function EditEmployeeModal({employee, teams}) {
    const api = useAxios();
    const [modalOpen, setModalOpen] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("Employee Updated Successfully");
    const [editMode, setEditMode] = useState(false);
    const [inputFirstName, setInputFirstName] = useState(employee.first_name);
    const [inputLastName, setInputLastName] = useState(employee.last_name);

    const [inputEmail, setEmail] = useState(employee.email); //make email editable 

    const [selectedTeam, setSelectedTeam] = useState(employee.team_name);
    const dispatch = useDispatch();
    const { user } = useContext(AuthContext);

    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => {
        setModalOpen(false);
        setEditMode(false);
    }
    const handleSnackbarOpen = () => setSnackbarOpen(true);
    const handleSnackbarClose = () => setSnackbarOpen(false);

    const action = (
        <React.Fragment>
            <Button color="primary" size="small" onClick={handleSnackbarClose}>
                Close
            </Button>
        </React.Fragment>
    );

    const refreshEmployees = () => {
        dispatch(fetchDashboardUsers([user.company, api]));
    }

    const refreshTeams = () => {
        dispatch(fetchTeamData([api, user.company])); 
        dispatch(fetchTeamRawData([user.company, api])); 
    }




    const handleSave = () => {
        api.put(`/dashboard_user/${employee.id}/`, {
            first_name: inputFirstName,
            last_name: inputLastName,
            email : inputEmail,
            team: teams.find((team) => team.team_name === selectedTeam).id
        }).then((res) => {
            if (res.status === 200) {
                console.log("Employee Updated Successfully")
                setSnackbarMessage("Employee Updated Successfully");
                handleSnackbarOpen();
                handleModalClose();
                refreshEmployees();
                refreshTeams();
            }
        }).catch((err) => {
            console.log(err);
            setSnackbarMessage("Employee Update Failed: " + err.message);
            handleSnackbarOpen();
            handleModalClose();
          });
    };

    const textFieldStyle = {
        width: "100%",
    }

  return (
    <>
        <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            message={snackbarMessage}
            action={action}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
        />

        <Modal open={modalOpen} onClose={handleModalClose}>
            <Box
            // .modalStyle{
            //     width: 600px;
            //     box-sizing: border-box;
            //     margin-top: 10%;
            //     margin-bottom: auto;
            //     margin-left: auto;
            //     margin-right:auto;
            //     border-radius: 5px;
            // }
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    bgcolor: "background.paper",
                    borderRadius: "5px",
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <CloseIcon
                    sx={{
                        float: "right",
                        cursor: "pointer",
                    }}
                    onClick={handleModalClose}
                />
                <Stack
                    direction="column"
                    justifyContent={"center"}
                    alignItems={"center"}
                    spacing={3}
                    width={"100%"}
                >
                    <Typography variant="h4" component="h2">
                        Employee Details
                    </Typography>
                    <TextField
                        label="First Name"
                        variant="outlined"
                        value={inputFirstName}
                        onChange={(e) => setInputFirstName(e.target.value)}
                        disabled={!editMode}
                        sx={textFieldStyle}
                    />
                    <TextField
                        label="Last Name"
                        variant="outlined"
                        value={inputLastName}
                        onChange={(e) => setInputLastName(e.target.value)}
                        disabled={!editMode}
                        sx={textFieldStyle}
                    />
                    <TextField
                        label="Email"
                        variant="outlined"
                        value={inputEmail}
                        onChange={(e) => setEmail(e.target.value)}
                        disabled={!editMode}
                        sx={textFieldStyle}
                    />

        <FormControl
        sx={{width: "100%"}}
        >
                                <FormHelperText>{"Team"}</FormHelperText>

                    <Select
                        labelId="team-select-label"
                        id="team-select"
                        value={selectedTeam}
                        label="Team"
                        onChange={(e) => setSelectedTeam(e.target.value)}
                        disabled={!editMode}
                        sx={textFieldStyle}
                    >
                        {teams.length > 0 && teams.map((team) => (
                            <MenuItem key={team.id} value={team.team_name}>
                                {team.team_name}
                            </MenuItem>
                        ))}
                    </Select>
                    </FormControl>
                    <DeleteEmployeeButton user_id={employee.id} disabled={!editMode}/>
                    <Button
              // should say "edit" if editMode is false, "save" if editMode is true
              // when clicked, should toggle editMode and if the "save" button is clicked,
              // should update the device details in the database
              onClick={() => {
                if (editMode) {
                  handleSave();
                }
                setEditMode(!editMode);
                // console.log("deviceName: ", deviceName);
              }}
              sx={{color:"white"}}
            >
              {editMode ? "Save" : "Edit"}
            </Button>
            </Stack>
        </Box>
        </Modal>
        <Button
            variant="contained"
            onClick={handleModalOpen}
            sx={{color:"white"}}
        >
            Edit
        </Button>
    </>
  )
}
