
import { useState } from "react";
import { useSelector } from "react-redux";

import Box from "@mui/material/Box";
import { Paper, Stack } from "@mui/material";

export default function TeamDataMonitoring() {

  const [show, setShow] = useState(false)

  const teams = useSelector( store => store.team.teams) // fetchTeamData
  const teams_raw = useSelector( store => store.team.teams_raw) // fetchTeamRawData
  const team_monthly_activity = useSelector( store => store.team.team_monthly_activity) // fetchMonthlyTeamActivity
  const admin_teams = useSelector( store => store.team.admin_teams) // fetchAllTeams

  function handleClick(){
    setShow(!show)
  }

  return (
    <Paper
      sx={{
        padding: 2,
        mt: 1
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>

        <Stack>
        <div>
          <button onClick={handleClick}>
            Show/Hide Team Fetch Status
          </button>
        </div>

        { show == true &&
          <div>
            <h1>
              Team Data Monitoring
            </h1>
            { teams? <p>  fetchTeamData ✅</p> : <p>  fetchTeamData  ❌</p> }
            { teams_raw?.length >0  ? <p>  fetchTeamRawData ✅</p> : <p>  fetchTeamRawData  ❌</p> }
            { team_monthly_activity?.length >0  ? <p>  fetchMonthlyTeamActivity ✅</p> : <p>  fetchMonthlyTeamActivity  ❌</p> }
            { admin_teams?.length >0  ? <p>  fetchAllTeams ✅</p> : <p>  fetchAllTeams  ❌</p> }

          </div>
        }
        </Stack>

      </Box>
    </Paper>
  );
}
