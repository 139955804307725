import { createContext, useContext, useState } from "react";
import AuthContext from "./AuthContext";
import useAxios from '../utils/useAxios'

const ThemeContext = createContext();
export default ThemeContext;

export const ThemeContextProvider = ({ children }) => {
  // SET THEME WILL NEED TO OCCUR HERE (Light is user not logged in, User.value IF User )

  const api = useAxios()

  const { user } = useContext(AuthContext);

  const [theme, setTheme] = useState("light");

  if (user) {

    try {
      api
        .get(`/dashboard_user/${user.user_id}/`)
        .then((response) => setTheme(response.data.theme));
    } catch (error) {
      console.log(error);
      alert("Error: Could not get user theme: ", error);
    }

  }

  function toggleTheme() {
    const updated_theme = theme === "light" ? "dark" : "light";

    if (user) {

      //this doesn't handle error 

      let endpoint = `/dashboard_user/${user.user_id}/`
      const response = api.put(endpoint, {theme:updated_theme})
      const data = response.data    
      console.log("Toggled the User's Theme", data)
  
      // try {
      //   fetch(
      //     `{{url}}/dashboard_user/${user.user_id}/`,
      //     {
      //       method: "PUT",
      //       headers: {
      //         "Content-Type": "application/json",
      //       },
      //       body: JSON.stringify({
      //         theme: updated_theme,
      //       }),
      //     }
      //   );
      // } catch (error) {
      //   console.log(error);
      // }

      setTheme(theme === "light" ? "dark" : "light");
    }
  }

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
