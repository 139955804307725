
// import * as React from 'react';
// import {Button} from '@material-ui/core'

import { configureStore } from '@reduxjs/toolkit'
import companyReducer from './Reducers/companyReducer'
import teamReducer from './Reducers/teamReducer'
import deviceReducer from './Reducers/deviceReducer'
import dashboardUserReducer from './Reducers/dashboardUserReducer';
import hourlyDeskUpsertReducer from './Reducers/hourlyDeskUpsertReducer';
import hourlyBikeUpsertReducer from './Reducers/hourlyBikeUpsertReducer';
import leaderboardReducer from './Reducers/leaderboardReducer';
import challengeReducer from './Reducers/challengeReducer';
import streakReducer from './Reducers/streakReducer';
import routineReducer from './Reducers/routineReducer';
import exportReducer from './Reducers/exportReducer';

export const store = configureStore({
    initialState : {
      "company_to_fetch" : 1,
      "team_to_fetch" : 1,
      "device_to_fetch" : 1,
      "device_to_fetch_hourly_data_for" : 9,
    },//not sure if this is required but I will try
    reducer: {
      company: companyReducer,
      team: teamReducer,
      device : deviceReducer,
      dashboard_user : dashboardUserReducer,
      hourly_desk_upsert : hourlyDeskUpsertReducer,
      hourly_bike_upsert : hourlyBikeUpsertReducer,
      leaderboard : leaderboardReducer,
      challenge : challengeReducer,
      streak : streakReducer,
      routine : routineReducer,
      csv_export : exportReducer,
    }
})