import { createSlice , createAsyncThunk } from '@reduxjs/toolkit'

export const fetchUserLeaderboard = createAsyncThunk('dashboard_users/fetchUserLeaderboard', async ([companyId, api]) => {
  let endpoint =  `/dashboard_user/get_company_leaderboard/${companyId}/`
  const response = await api.get(endpoint);
  const data = response.data
  // This just gets the top users, we need to sort them by descending activity
  const sortedData = data.sort((a, b) => b.activity - a.activity)
  return sortedData
})

export const fetchTeamLeaderboard = createAsyncThunk('company/fetchTeamLeaderboard', async ([companyId, api]) => {
    let endpoint =  `/company/get_company_team_leaderboard/${companyId}/`
    const response = await api.get(endpoint);
    const data = response.data
    return data;
})

const leaderboardDataSlice = createSlice({
  name: 'leaderboard',
  initialState: {"users":[], "teams":[]},
  reducers: {
    //this reducer is actually not being used for anything anymore.
    deviceDataFetched(state, action) {
      //encapsulated this in a for loop so that it adds each item in the payload to the state
      state = action.payload
    },
  }, 
  extraReducers(builder) {
    builder
      .addCase(fetchUserLeaderboard.pending, (state, action) => {
        //
      })
      .addCase(fetchUserLeaderboard.fulfilled, (state, action) => {
        state.users = action.payload
      })
      .addCase(fetchUserLeaderboard.rejected, (state, action) => {
        console.log("error in extra reducers (fetchUserLeaderboard)")
      })
      .addCase(fetchTeamLeaderboard.pending, (state, action) => {
        //
      })
      .addCase(fetchTeamLeaderboard.fulfilled, (state, action) => {
        
        console.log("fetchTeamLeaderboard fulfilled")
        state.teams = action.payload
        // state.teams.push(action.payload)
      })
      .addCase(fetchTeamLeaderboard.rejected, (state, action) => {
        console.log("error in extra reducers (fetchTeamLeaderboard)")
      })
  }
})

export const { leaderboardDataFetched } = leaderboardDataSlice.actions;
export default leaderboardDataSlice.reducer;
