import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchTeamData = createAsyncThunk(
  "team/fetchTeamData",
  async ([api, company_id]) => {

    let endpoint = `/team/get_by_company/${company_id}/`
    const response = await api.get(endpoint);
    const data = response.data    

    // right now, data = [{id: 1, team_name: "Team 1", company: 1}, ...]
    // we want teamData = {1:"Team 1", 2:"Team 2", ...} to make it easier to access the team name by id
    let teamData = {};
    data.forEach((team) => {
      teamData[team.id] = team.team_name;
    });

    return teamData;
  }
);

export const fetchTeamRawData = createAsyncThunk(
  "team/fetchTeamRawData",
  async ([company_id, api]) => {

    let endpoint = `/team/get_by_company/${company_id}/`
    const response = await api.get(endpoint);
    const data = response.data
    return data;

  }
);

// team/get_monthly_team_activity/
export const fetchMonthlyTeamActivity = createAsyncThunk(
  "team/fetchMonthlyTeamActivity",
  async(api) => {
    let endpoint = `/team/get_monthly_team_activity/` //new endpoint made by bryce
    const response = await api.get(endpoint);
    const data = response.data
    return data;
  }
)

// Admin function to get all teams
export const fetchAllTeams = createAsyncThunk(
  "team/",
  async (api) => {
    const response = await api.get(`/team/`);
    const data = response.data;
    return data;
  }
);


const teamDataSlice = createSlice({
  name: "team",
  initialState: { teams: {} , teams_raw : {}, team_monthly_activity : {}, admin_teams: []},
  reducers: {
    teamDataFetched(state, action) {
      state = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAllTeams.pending, (state, action) => {
        // do nothing
      })
      .addCase(fetchAllTeams.fulfilled, (state, action) => {
        state.admin_teams = action.payload;
      })
      .addCase(fetchAllTeams.rejected, (state, action) => {
        console.log("fetchAllTeams rejected");
      })
      .addCase(fetchTeamData.pending, (state, action) => {
        // do nothing
      })
      .addCase(fetchTeamData.fulfilled, (state, action) => {
        state.teams = action.payload;
      })
      .addCase(fetchTeamData.rejected, (state, action) => {
        console.log("fetchTeamData rejected");
      })
      .addCase(fetchTeamRawData.pending, (state, action) => {
      })
      .addCase(fetchTeamRawData.fulfilled, (state, action) => {
        state.teams_raw = action.payload;
      })
      .addCase(fetchTeamRawData.rejected, (state, action) => {
        console.log("fetchTeamData rejected");
      })
      .addCase(fetchMonthlyTeamActivity.pending, (state, action) => {
      })
      .addCase(fetchMonthlyTeamActivity.fulfilled, (state, action) => {
        state.team_monthly_activity = action.payload;
      })
      .addCase(fetchMonthlyTeamActivity.rejected, (state, action) => {
        console.log("fetchMonthlyTeamActivity rejected");
      });
  },
});

export const { teamDataFetched } = teamDataSlice.actions;
export default teamDataSlice.reducer;
