import React from 'react'
import Paper from '@mui/material/Paper';
import {
  Chart,
  BarSeries,
  Title,
  ArgumentAxis,
  ValueAxis,
} from '@devexpress/dx-react-chart-material-ui';
import { Animation } from '@devexpress/dx-react-chart';


export default function BarChart({ data }) {

  return (
    <div className="bar_chart">
        <Chart

        // this should work as the format of the prop data is correct
        // for some reason using prop data won't display
        // use dummy data instead for now
        //   data={props.data}

        data={data}
        height="280"
        >
          <ArgumentAxis />
          <ValueAxis max={60} />

          <BarSeries
            valueField="timeActive"
            argumentField="day"
            color="#F7941E"
          />
          <Title text="Weekly Activity" />
          <Animation />
        </Chart>
    </div>
  )
}
