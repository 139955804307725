import Grid from "@mui/system/Unstable_Grid/Grid";
import { Box } from "@mui/system";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import AccountInformation from "../components/AccountInformation/AccountInformation";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { fetchDashboardUsers } from "../components/Reducers/dashboardUserReducer";
import { useDispatch, useSelector } from "react-redux";
import { fetchTeamData, fetchTeamRawData } from "../components/Reducers/teamReducer";
import useAxios from "../utils/useAxios";
import { fetchCurrentDashboardUser } from "../components/Reducers/dashboardUserReducer";
import { useEffect } from "react";
import { fetchBikeDevices, fetchDeskDevices } from "../components/Reducers/deviceReducer";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  borderRadius: "25px",
}));

const SettingsPage = () => {

  // data fetching
  const dispatch = useDispatch();
  const { user, loading } = useContext(AuthContext);
  const api = useAxios();

  // fetch initial data when user is loaded
  useEffect(() => {
    if (!loading && user) {
      dispatch(fetchDashboardUsers([user.company, api]));
      dispatch(fetchTeamData([api, user.company]));
      dispatch(fetchTeamRawData([user.company, api]));
      dispatch(fetchCurrentDashboardUser([user.user_id, api]));
      dispatch(fetchDeskDevices([api, user.company]));
      dispatch(fetchBikeDevices([api, user.company]));
    }
  }, [user]);

  const current_user = useSelector((state) => state.dashboard_user.current_dashboard_user);



  return (
    <div >
      <Box
        sx={{
          flexGrow: 1,
          height: `100%`,
        }}
      >
        <Grid
          container
          rowSpacing={2}
          columnSpacing={2}
          sx={{ mt: 1 }}
          justifyContent="center"
          maxWidth={"xl"}
          marginX="auto"
        >
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Item>
              <AccountInformation 
                current_user={current_user}
              />
            </Item>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default SettingsPage;
