import Routines from "../components/Routines/Routines";
import useAxios from "../utils/useAxios";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AuthContext from "../context/AuthContext";
import { fetchDashboardUserRoutines } from "../components/Reducers/routineReducer";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Select,
  Slider,
  Stack,
  TextField,
  Snackbar,
  Typography,
} from "@mui/material";
import { Grid } from "@mui/material";
import { AddCircleOutlineOutlined } from "@mui/icons-material";
import { fetchDeskDevices } from "../components/Reducers/deviceReducer";
import DeleteIcon from '@mui/icons-material/Delete';
import AddRoutineModal from "../components/AddRoutineModal/AddRoutineModal";

const RoutinesPage = () => {
  // Setup
  const api = useAxios();
  const dispatch = useDispatch();
  const { user } = useContext(AuthContext);

  // State variables
  const routines = useSelector(
    (dashboardStore) => dashboardStore.routine.routines
  );
  const assignedDesks = useSelector((dashboardStore) =>
    dashboardStore.device.desks.filter(
      (desk) => desk.dashboard_user === user.user_id
    )
  );
  const [selectedRoutine, setSelectedRoutine] = useState(routines[0]);
  const [selectedDevice, setSelectedDevice] = useState(assignedDesks[0]);
  const [inputRoutineName, setInputRoutineName] = useState("");
  const [inputTotalTime, setInputTotalTime] = useState(0);
  const [inputActiveHeight, setInputActiveHeight] = useState(0);
  const [inputRestHeight, setInputRestHeight] = useState(0);
  const [inputActiveTime, setInputActiveTime] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(
    "Device Updated Successfully"
  );
  const handleSnackbarOpen = () => setSnackbarOpen(true);
  const handleSnackbarClose = () => setSnackbarOpen(false);
  const action = (
    <>
      <Button color="primary" size="small" onClick={handleSnackbarClose}>
        Close
      </Button>
    </>
  );
  // Handlers
  const refreshRoutines = () => {
    dispatch(fetchDashboardUserRoutines([user.user_id, api]));
  };

  const handleSave = () => {
    api
      .put(`/routine/${selectedRoutine.id}/`, {
        name: inputRoutineName,
        total_time: inputTotalTime,
        active_height: inputActiveHeight,
        rest_height: inputRestHeight,
        active_time: inputActiveTime,
        rest_time: inputTotalTime - inputActiveTime,
      })
      .then((res) => {
        if (res.status === 200) {
          console.log("Routine Updated Successfully");
          setSnackbarMessage("Routine Updated Successfully");
          handleSnackbarOpen();
          refreshRoutines();
        }
      })
      .catch((err) => {
        console.log(err);
        setSnackbarMessage("Routine Update Failed: " + err.message);
        handleSnackbarOpen();
      });
  };

  const deleteRoutine = (routine) => {
    api
      .delete(`/routine/${routine.id}/`)
      .then((res) => {
        if (res.status === 204) {
          console.log("Routine Deleted Successfully");
          setSnackbarMessage("Routine Deleted Successfully");
          handleSnackbarOpen();
          refreshRoutines();
        }
      })
      .catch((err) => {
        console.log(err);
        setSnackbarMessage("Routine Delete Failed: " + err.message);
        handleSnackbarOpen();
      });
  };

  const handleStartRoutine = () => {
    api
    .get(`/routine_index/`)
    .then((res) => {
      if (res.status === 200) {
        console.log("Hit /routine_index/ in the backend");
        setSnackbarMessage("Hit /routine_index/ in the backend");
        handleSnackbarOpen();
        refreshRoutines();
      }
    })
    .catch((err) => {
      console.log(err);
      setSnackbarMessage("Unable to hit /routine_index/ in the backend" + err.message);
      handleSnackbarOpen();
    });
    /*
    api
      .post(`/execute_routine_improved/${selectedRoutine.id}/`, {
        device_name: selectedDevice.device_name,
        device_serial: selectedDevice.serial_number,
      })
      .then((res) => {
        if (res.status === 200) {
          console.log("Scheduled Routine Events");
          setSnackbarMessage("Scheduled Routine Events");
          handleSnackbarOpen();
          refreshRoutines();
        }
      })
      .catch((err) => {
        console.log(err);
        setSnackbarMessage("Unable to Schedule Routine Events" + err.message);
        handleSnackbarOpen();
      });
    */
  };



  // Effect for fetching
  useEffect(() => {
    if (user) {
      dispatch(fetchDashboardUserRoutines([user.user_id, api]));
      dispatch(fetchDeskDevices([api, user.company]));
    }
  }, [user]);

  // Effect for setting selected routine on load
  useEffect(() => {
    if (routines.length > 0) {
      setSelectedRoutine(routines[0]);
    }
  }, [routines]);

  // Effect for updating state when selected routine changes
  useEffect(() => {
    if (selectedRoutine) {
      setInputRoutineName(selectedRoutine.name);
      setInputTotalTime(selectedRoutine.total_time);
      setInputActiveHeight(selectedRoutine.active_height);
      setInputRestHeight(selectedRoutine.rest_height);
      setInputActiveTime(selectedRoutine.active_time);
    }
  }, [selectedRoutine]);

  return (
    <>
      <Snackbar
        // position snackbar absolute to the top of the screen and centered
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        action={action}
      />
      <Grid
        container
        spacing={2}
        sx={{
          height: "100%",
          padding: "2rem",
        }}
      >
        {/* Header */}
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography variant="h4">Routines</Typography>
          <AddRoutineModal />
        </Grid>
        {/* Routine List */}
        {routines?.length === 0 ? (
          <Grid item xs={12}>
            <Typography variant="h6">
              Create a routine to get started.
            </Typography>
          </Grid>
        ) : (
          <Grid item xs={12} md={4}>
            <Stack
              direction="column"
              spacing={2}
              sx={{
                width: "100%",
                height: "100%",
              }}
            >
              {routines?.map((routine) => {
                return (
                  <Paper
                    key={routine.id}
                    sx={{
                      justifyContent: "space-between",
                      display: "flex",
                      alignItems: "center",
                      padding: "10px",
                      "&:hover": {
                        // slightly change background color on hover for dark and light mode
                        backgroundColor: (theme) =>
                          theme.palette.mode === "dark"
                            ? theme.palette.grey[900]
                            : theme.palette.grey[100],
                      },
                      // add border of 1px orange if this is the selected routine, offset by 1px
                      border: (theme) =>
                        selectedRoutine?.id === routine.id
                          ? `1px solid ${theme.palette.primary.main}`
                          : "1px solid transparent",
                      // add border of 1px orange if this is the selected routine, offset by 2px
                      // this creates a subtle shadow effect
                      boxShadow: (theme) =>
                        selectedRoutine?.id === routine.id
                          ? `0 0 0 2px ${theme.palette.primary.main}`
                          : "none",
                    }}
                    onClick={() => setSelectedRoutine(routine)}
                  >
                    {routine.name}
                    {/* Delete Icon */}
                    <IconButton
                      onClick={() => {
                        deleteRoutine(routine);
                      }}
                    >
                      <DeleteIcon 
                        sx={{
                          fontSize: "1.3rem",
                        }}
                      />
                    </IconButton>
                  </Paper>
                );
              })}
            </Stack>
          </Grid>
        )}
        {/* Routine Editor */}
        {routines?.length > 0 && (
          <Grid item xs={12} md={8}>
            <Paper
              sx={{
                height: "100%",
                padding: "2rem",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Stack direction="column" spacing={2}
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  >
                    <TextField
                      label="Routine Name"
                      value={inputRoutineName}
                      onChange={(e) => setInputRoutineName(e.target.value)}
                      variant="standard"
                      sx={{
                        width: "100%",
                      }}
                    />
                    <TextField
                      label="Total Time"
                      value={inputTotalTime}
                      onChange={(e) => setInputTotalTime(e.target.value)}
                      variant="standard"
                      sx={{
                        width: "100%",
                      }}
                    />
                    <TextField
                      label="Active Height"
                      value={inputActiveHeight}
                      onChange={(e) => setInputActiveHeight(e.target.value)}
                      variant="standard"
                      sx={{
                        width: "100%",
                      }}
                    />
                    <TextField
                      label="Rest Height"
                      value={inputRestHeight}
                      onChange={(e) => setInputRestHeight(e.target.value)}
                      variant="standard"
                      sx={{
                        width: "100%",
                      }}
                    />
                    <Button
                      variant="contained"
                      sx={{
                        color: "white",
                        width: "6rem",
                      }}
                      onClick={handleSave}
                    >
                      Save
                    </Button>
                  </Stack>
                </Grid>
                <Grid item xs={12} lg={8}>
                  <Stack
                    direction="column"
                    spacing={2}
                    sx={{
                      justifyContent: "space-between",
                      height: "100%",
                      alignItems: "center",
                    }}
                  >
                    <Paper
                      sx={{
                        width: "100%",
                        padding: "0",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Stack direction="column" spacing={1}>
                        <Box
                          p={1}
                          display="flex"
                          flexDirection={"column"}
                          justifyContent="center"
                          alignItems="center"
                          width="80px"
                        >
                          <Typography variant="caption">Active</Typography>
                          <Typography variant="caption">
                            {inputActiveTime} min
                          </Typography>
                        </Box>
                      </Stack>
                      {/* Slider to distribute the selected Total Time to active/rest time */}
                      <Slider
                        value={inputActiveTime}
                        onChange={(e, value) => setInputActiveTime(value)}
                        min={2}
                        max={Math.min(inputTotalTime, 240)}
                        sx={{
                          width: "100%",
                        }}
                      />
                      <Stack direction="column" spacing={1}>
                        <Box
                          p={2}
                          display="flex"
                          flexDirection={"column"}
                          justifyContent="center"
                          alignItems="center"
                          width="80px"
                        >
                          <Typography variant="caption">Rest</Typography>
                          <Typography variant="caption">
                            {inputTotalTime - inputActiveTime} min
                          </Typography>
                        </Box>
                      </Stack>
                    </Paper>
                    <Box
                      sx={{
                        width: "100%",
                        padding: "1rem",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {/* Device Selector */}
                      <Typography variant="h7">
                        Select a device to start the routine
                      </Typography>
                      <Select
                        native
                        size="small"
                        value={0}
                        onChange={(e) => console.log(e.target.value)}
                        sx={{
                          width: "100%",
                        }}
                      >
                        {assignedDesks?.map((desk) => {
                          return (
                            <option key={desk.id} value={desk.id}>
                              {desk.device_name} - {desk.serial_number}
                            </option>
                          );
                        })}
                      </Select>
                    </Box>
                    <Button
                      variant="contained"
                      sx={{
                        color: "white",
                        width: "8rem",
                      }}
                      onClick={handleStartRoutine}
                    >
                      Start Routine
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default RoutinesPage;
