/* Material UI Imports */
import {Grid, Box} from "@mui/material"

/* Redux imports */
import { useDispatch, useSelector } from "react-redux";
import { fetchCurrentDashboardUser } from "../../components/Reducers/dashboardUserReducer";
import { fetchDashboardUsers } from "../../components/Reducers/dashboardUserReducer";
import { fetchTeamData } from "../../components/Reducers/teamReducer";
import { fetchCompanyData } from "../../components/Reducers/companyReducer";
import { fetchDeskDevices } from "../../components/Reducers/deviceReducer";
import { fetchBikeDevices } from "../../components/Reducers/deviceReducer";

/* React imports */
import { useEffect } from "react";
import useAxios from "../../utils/useAxios";
import TeamMemberInfo from "../../components/TeamMemberProfileComponents/TeamMemberInfo";

import AuthContext from "../../context/AuthContext";
import { useContext } from "react";

// # JACK PROFILE - PLUS

const TeamMemberProfilePage = () => {
  //this parent component should do these things : 

  const api = useAxios()
  const dispatch = useDispatch()
  const { user, loading } = useContext(AuthContext);

  useEffect(() => {
      if (!loading && user) {
        dispatch(fetchCurrentDashboardUser(user.user_id));
        dispatch(fetchDashboardUsers([user.company, api])); //get company team members
        dispatch(fetchBikeDevices([api, user.company]));    //get company devices
        dispatch(fetchDeskDevices([api, user.company]));    //get company devices
        // place more dispatch here:

        // Device info: Desk id, Bike id assigned

        // Team co-workers and icons
        dispatch(fetchTeamData([api, user.company]));
        dispatch(fetchCompanyData([api, user.company]));

        // Activity at a glance, tatal cal burned, power and distance (bike)

        // Range by weekly / ? on a certain day, weekly kcal by desk and bike.

        // Weekly / ? goals & performance
        // Hours active
        // KCal burned

        // edit goals

        // ? days cal

        // 14 days average, daily cal burned

        // Highest cal burned

        // Lowest cal burned

      }
    }, [user]
  );

  return (
    <div >
        {/* <h1>
            HEY THIS IS REGULAR USER PROFILE PAGE !
        </h1> */}
        <TeamMemberInfo/>
    </div>
  );
};

export default TeamMemberProfilePage;
