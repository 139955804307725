import { createContext, useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { useHistory } from "react-router-dom";
import useCookies from "react-cookie/cjs/useCookies";
// https://www.tutorialspoint.com/how-to-set-cookies-in-reactjs#:~:text=Cookies%20are%20the%20data%20stored,party%20dependency%20of%20react%2Dcookie.

// Cookies: Javascript object with all of the user’s cookies.
// setCookie: Function to set the cookies
// removeCookie: Function to remove the cookies.

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  
  //create handles for cookies
  const [cookies, setCookie, removeCookie] = useCookies(['authTokens','access', 'refresh']);

  const [authTokens, setAuthTokens] = useState(() =>
    cookies.authTokens ? cookies.authTokens : null //the token itself
  );
  const [user, setUser] = useState(() =>
    cookies.authTokens ? jwt_decode(cookies.authTokens.access) : null //the decoded user
  );
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  const loginUser = async (email, password) => {
    const response = await fetch(`${process.env.REACT_APP_DASHBOARD_API_URL}/api/token/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email,
        password
      })
    });
    const data = await response.json();

    if (response.status === 200) {
      setAuthTokens(data);
      setUser(jwt_decode(data.access));

      setCookie("authTokens", JSON.stringify(data))//not sure what options exists (max-age, expiry?)

      history.push("/home");
    } else {
      alert("A user with this email and password was not found");
    }
  };

  const registerUser = async (first_name, last_name, email, company, team, user_type) => {
  // const registerUser = async (email, password, password2) => {
    // const response = await fetch("http://localhost:8000/dashboard_user/create/", {
    const response = await fetch(`${process.env.REACT_APP_DASHBOARD_API_URL}/dashboard_user/create/`, {

      
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },

      body: JSON.stringify({
        first_name,
        last_name,
        email,
        company,
        team,
        user_type
      })      
      // body: JSON.stringify({
      //   email,
      //   password,
      //   password2
      // })
    });
    if (response.status === 201) {
      alert("User created successfully");
    } else {
      alert("Something went wrong!");
    }
  };

  const logoutUser = () => {
    setAuthTokens(null);
    setUser(null);
    removeCookie("authTokens")
    // We could go and clear our redux store here, instead I'm going to try and 
    // just redirect to the login page with a refresh and see if that works
    history.push("/");
    window.location.reload();

  };

  const contextData = {
    user,
    setUser,
    authTokens,
    setAuthTokens,
    registerUser,
    loginUser,
    logoutUser
  };

  useEffect(() => {
    if (authTokens) {
      setUser(jwt_decode(authTokens.access));
    }
    setLoading(false);
  }, [authTokens, loading]);

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};