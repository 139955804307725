import * as React from 'react';
import Chip from '@mui/material/Chip';
import { useHistory } from 'react-router-dom';

export default function BetaPill() {
  const history = useHistory();
  return (
    <Chip 
    label="BETA" 
    color ="warning"
    sx = {{
        width : "5em",
        fontWeight : "700",
        backgroundColor: "#424242",
    }}
    onClick={() => {
        history.push("/release_notes");
    }}
    />
  );
}