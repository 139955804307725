import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/system/Unstable_Grid/Grid";
import styles from "./BikeData.module.scss";
import { format } from "date-fns";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import BarChart from "../../BarChart/BarChart";
import { Typography } from "@mui/material";

export default function BikeData(props) {
  const [graphData, setGraphData] = useState([]);
  const [avgActiveTime, setAvgActiveTime] = useState(0);
  const [highestActiveTime, setHighestActiveTime] = useState(0);
  const [totalActiveTime, setTotalActiveTime] = useState(0);

  const userBikeAnalytics = useSelector(
    (state) => state.dashboard_user.bike_analytics
  );

  async function setBikeAnalytics(userBikeAnalytics) {
    // homePage BikeData component
    // Last 7 days
    const today = new Date();
    const sevenDaysAgo = new Date(today);
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

    // get bike analytics with date >= sevenDaysAgo
    const lastSevenDaysAnalytics = userBikeAnalytics.filter(
      (item) => new Date(item.date) >= sevenDaysAgo
    );

    // get the average timeBiked for each item in lastSevenDaysAnalytics
    setAvgActiveTime(
      lastSevenDaysAnalytics.reduce((a, b) => a + b.timeBiked, 0) /
        lastSevenDaysAnalytics.length || 0
    );

    // find the highest timeBiked for each item in lastSevenDaysAnalytics
    setHighestActiveTime(
      lastSevenDaysAnalytics.reduce((a, b) => Math.max(a, b.timeBiked), 0)
    );

    // sum timeBiked for each item in lastSevenDaysAnalytics
    setTotalActiveTime(
      lastSevenDaysAnalytics.reduce((a, b) => a + b.timeBiked, 0)
    );

    // for each item in lastSevenDaysAnalytics, push {day:date, timeActive:timeBiked} to graphData
    // date must be in the format Oct 12
    lastSevenDaysAnalytics.forEach((item) => {
      const date = format(new Date(item.date), "MMM dd");
      const timeBiked = item.timeBiked;
      setGraphData((graphData) => [
        ...graphData,
        { day: date, timeActive: timeBiked },
      ]);
    });
  }

  useEffect(() => {
    if (userBikeAnalytics && userBikeAnalytics.length > 0) {
      setBikeAnalytics(userBikeAnalytics, graphData);
    }
  }, [userBikeAnalytics]);

  return (
    <Grid container alignItems="stretch" spacing={2}>
      {/* EXPERIMENT */}
      {/* Title and date */}
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          alignItems: "center",
          alignContent: "left",
          height: "100%",
        }}
      >
        <Box>
          <Typography variant="h3">Bike Data</Typography>
        </Box>
      </Grid>

      {/* Stats */}
      <Grid item xs={12} xl={3}>
        <Grid container spacing={2}>
          {/* 14 day avg */}
          <Grid
            item
            xs={4}
            xl={12}
            sx={{
              display: "flex",
              alignItems: "center",
              alignContent: "left",
              flexDirection: "column",
            }}
          >
            <Typography variant="h6">Average</Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                alignContent: "left",
                flexDirection: "row",
              }}
            >
              <Typography variant="h3" className={styles.chartValue} mx={1}>
                {/* Round this average to an integer */}
                {Math.round(avgActiveTime) || 0}
              </Typography>
              <Typography variant="caption" className={styles.chartValue}>
                kJ
              </Typography>
            </Box>
            <Typography variant="caption" color={"gray"}>
              Calories Burned
            </Typography>
          </Grid>

          {/* Highest */}
          <Grid
            item
            xs={4}
            xl={12}
            sx={{
              display: "flex",
              alignItems: "center",
              alignContent: "left",
              flexDirection: "column",
            }}
          >
            <Typography variant="h6" mx={1}>
              Highest
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                alignContent: "left",
                flexDirection: "row",
              }}
            >
              <Typography variant="h3" className={styles.chartValue} mx={1}>
                {Math.round(highestActiveTime) || 0}
              </Typography>
              <Typography variant="caption" className={styles.chartValue}>
                kJ
              </Typography>
            </Box>
            <Typography variant="caption" color={"gray"}>
              Calories Burned
            </Typography>
          </Grid>

          {/* Total */}
          <Grid
            item
            xs={4}
            xl={12}
            sx={{
              display: "flex",
              alignItems: "center",
              alignContent: "left",
              flexDirection: "column",
            }}
          >
            <Typography variant="h6">Total</Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                alignContent: "left",
                flexDirection: "row",
              }}
            >

            <Typography variant="h3" className={styles.chartValue} mx={1}>
              {Math.round(totalActiveTime) || 0}
            </Typography>
            <Typography variant="caption" className={styles.chartValue}>
              kJ
            </Typography>
          </Box>
            <Typography variant="caption" color={"gray"}>Calories Burned</Typography>
          </Grid>
        </Grid>
      </Grid>

      {/* Chart */}
      <Grid item xs={12} xl={9} sx={{ textAlign: "center" }}>
        <BarChart data={graphData} />
      </Grid>

      {/* Footer / view data button */}
      <Grid item xs={12} sx={{ textAlign: "right", justifyContent: "center" }}>
        <Button
          size="medium"
          component={Link}
          to="/home/bike"
          sx={{
            color: "white",
            backgroundColor: "orange",
            "&:hover": {
              backgroundColor: "#e2830f",
            },
          }}
        >
          View Data
        </Button>
      </Grid>
    </Grid>
  );
}
