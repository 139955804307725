import React from "react";
import { useContext, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {
  Grid,
  Box,
  Typography,
  Stack,
  Snackbar,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Paper,
} from "@mui/material";
import axios from "axios";
import { store, useSelector } from "react-redux";
import AuthContext from "../../context/AuthContext";
import EditHeightPresets from "../EditHeightPresetsComponent/EditHeightPresets";
import { useEffect } from "react";

/*
WHILE TESTING, GO HERE TO VERIFY MESSAGES ARE GOING THROUGH : 
https://us-east-1.console.aws.amazon.com/iot/home?region=us-east-1#/dashboard
*/

export default function MoveDeskControls() {
  const REACT_APP_API_GATEWAY_URL = String(
    process.env.REACT_APP_API_GATEWAY_URL
  );
  const REACT_APP_API_GATEWAY_KEY = String(
    process.env.REACT_APP_API_GATEWAY_KEY
  );
  const { loading, user } = useContext(AuthContext);
  const desk_devices = useSelector((store) => store.device.desks); //get all the desk devices and then filter (a new endpoint could be built for this, temporary workaround)
  const user_owned_desk_devices = desk_devices.filter(
    (d) => d.dashboard_user == user.user_id
  );

  let [deviceSerial, setDeviceSerial] = useState(""); // using this to test locally - (usdc_depa36egyn)
  let [targetHeightInput, setTargetHeightInput] = useState("");
  let [customHeightInputString, setCustomHeightInputString] = useState("");

  /* SNACK BAR STUFF */
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const handleSnackbarOpen = () => setSnackbarOpen(true);
  const handleSnackbarClose = () => setSnackbarOpen(false);

  //correctness check on the form. if this is not true, custom height button disabled
  const inRange = (value) => value >= 80 && value <= 120;

  //will send a message whenever the target height input is changed
  //this should help reduce some issues with apparent 'delays' in state updates
  useEffect(() => {
    sendHeightChangeMqttMessage()    
  }, [targetHeightInput]);

  const handleDeviceSelectedChange = (e) => {
    setDeviceSerial(e.target.value);
  };

  function handleMoveToActiveHeight() {
    if (deviceSerial === "") {
      alertDeviceNotSet();
      return;
    }
    setTargetHeightInput(120)
    //SIDE EFFECT GETS CALLED sendHeightChangeMqttMessage()
  }

  function handleMoveToCustomHeight(e) {
    if (deviceSerial === "") {
      alertDeviceNotSet();
      return;
    }
    // console.log("event:", e)
    setTargetHeightInput(parseInt(customHeightInputString))
    //SIDE EFFECT GETS CALLED sendHeightChangeMqttMessage()
  }

  function handleMoveToRestHeight() {
    if (deviceSerial === "") {
      alertDeviceNotSet();
      return;
    }
    setTargetHeightInput(80)
    //SIDE EFFECT GETS CALLED sendHeightChangeMqttMessage()
  }

  function sendHeightChangeMqttMessage() {
    if(targetHeightInput === ""){
      alertInputHeightNotSet()
    } else{

      setSnackbarMessage(
        `Will send message to set height to ${targetHeightInput} for selected device`
      );
      handleSnackbarOpen();
  
      let data = {
        topic: "usdc/presets", //should be usdc/preset once it's ready
        payload: {
          device_name: deviceSerial,
          device_serial: deviceSerial,
          target: targetHeightInput,
        },
      };
  
      console.log("sendHeightChangeMqttMessage - data : ", data);
  
      let config = {
        headers: {
          "x-api-key": REACT_APP_API_GATEWAY_KEY,
        },
      };
  
      console.log("sendHeightChangeMqttMessage - config : ", config);
  
      axios
        .post(REACT_APP_API_GATEWAY_URL, data, config)
        .then((response) => {
          console.log("response.data : ", JSON.stringify(response.data));
          console.log("Success publishing the message :) ");
        })
        .catch((error) => {
          console.log("Error publishing the message :( ");
          console.log(error);
        });      

    }//else


  }

  function alertInputHeightNotSet() {
    setSnackbarMessage(`Unfortunately the input height has not set, can't proceed - please set a target height`);
    handleSnackbarOpen();
  }

  function alertDeviceNotSet() {
    setSnackbarMessage(`Device not set, can't proceed - please set a device`);
    handleSnackbarOpen();
  }

  return (
    <div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        //action={action}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      />
      <>
        <Paper
          sx={{
            borderRadius: "25px",
            padding: "1rem",
          }}
        >
          <Grid container p={3} spacing={3}>
            <Grid item xs={12} sm={6} p={2}>
              <Stack spacing={2}>
                <Typography variant="h2">Move your desk</Typography>

                <Box
                  sx={{
                    borderRadius: "5px",
                    backgroundColor: "rgba(255, 166, 41, 0.5)",
                    padding: "0.5rem",
                    maxWidth: "sm",
                  }}
                >
                  <Typography>Currently in testing 🧪!</Typography>
                  <Typography>Requires Firmware Version #5</Typography>
                </Box>
              </Stack>
              <Stack
                spacing={2}
                marginTop={2}
                sx={{ maxWidth: "sm"}}
              >
                <Stack spacing={2}>
                  <FormControl fullWidth>
                    <InputLabel id="move-device-dropdown-label">
                      Device to Move
                    </InputLabel>
                    <Select
                      labelId="move-device-dropdown-label"
                      id="demo-simple-select"
                      value={deviceSerial}
                      label="Device To Move"
                      onChange={handleDeviceSelectedChange}
                      required
                      error={deviceSerial == ""}
                    >
                      {user_owned_desk_devices.map((device) => (
                        <MenuItem
                          key={device.serial_number}
                          value={device.serial_number}
                        >
                          {device.device_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Stack direction="row" spacing={2}>
                    <TextField
                      label="Target Height"
                      variant="outlined"
                      sx={{ flexGrow: 1 }}
                      required
                      onChange={(e) => setCustomHeightInputString(e.target.value)}
                      error={!inRange(parseInt(customHeightInputString))}
                    />
                    <Button
                      onClick={handleMoveToCustomHeight}
                      sx={{ width: 200 ,
                        color:"white"
                      }}
                      disabled = {!inRange(parseInt(customHeightInputString))}
                    >
                      Move to Target Height
                    </Button>
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <TextField
                      variant="outlined"
                      sx={{ flexGrow: 1 }}
                      required
                      disabled
                      placeholder="120"
                    />
                    <Button
                      onClick={handleMoveToActiveHeight}
                      size="large"
                      sx={{ width: 200 ,
                        color:"white"
                    }}
                    >
                      Move to Standing
                    </Button>
                  </Stack>{" "}
                  <Stack direction="row" spacing={2}>
                    <TextField
                      variant="outlined"
                      sx={{ flexGrow: 1 }}
                      required
                      disabled
                      placeholder="80"
                    />
                    <Button
                      onClick={handleMoveToRestHeight}
                      sx={{ width: 200 ,
                        color:"white"
                    }}
                    >
                      Move to Sitting
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6} p={2}>
              <EditHeightPresets />
            </Grid>
          </Grid>
        </Paper>
      </>
    </div>
  );
}
