import React, { useEffect, useState, useContext } from "react";
import { Box, Grid, Typography, Chip } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import "../../styles/colours.scss";
import styles from "./CompanySettings.module.scss";
import AuthContext from "../../context/AuthContext";
import { fetchCompanyData } from "../Reducers/companyReducer";
import { fetchDashboardUsers } from "../Reducers/dashboardUserReducer";
import { fetchTeamRawData } from "../Reducers/teamReducer";
import useAxios from "../../utils/useAxios";

export default function CompanySettings(props) {
  const api = useAxios();
  const { user, loading } = useContext(AuthContext);
  const dispatch = useDispatch();

  const company = useSelector((state) => state.company.companies);
  const all_dashboard_users = useSelector((state) => state.dashboard_user.dashboard_users);
  const teams_in_company = useSelector((state) => state.team.teams_raw); //I need the raw data for this

  const [current_company_information, setCurrentCompanyInformation] = useState({});
  const [company_admins, setCompanyAdmins] = useState([]);

  useEffect(() => {
    // Fetch data when user is loaded
    if (!loading && user) {
      dispatch(fetchCompanyData([api, user.company]));
      dispatch(fetchDashboardUsers([user.company, api]));
      dispatch(fetchTeamRawData([user.company, api]));
    }
  }, [user]);

  useEffect(() => {
    // Update company info when company changes
    if (company) {
      setCurrentCompanyInformation(company);
    }
  }, [company]);

  useEffect(() => {
    // Update admins when dashboard users change
    if (all_dashboard_users) {
      console.log("all_dashboard_users", all_dashboard_users);
      setCompanyAdmins(all_dashboard_users.filter((user) => user.user_type === 2));
    }
  }, [all_dashboard_users]);
  

  return (
    <div>
      <Box>
        <Typography variant="h2" className={styles.header}>
          Company Settings
        </Typography>

        {/* COMPANY GENERAL INFORMATION */}
        <Grid container spacing={4} alignItems="center" justifyContent="center">
          <Grid item xs={10} className={styles.textGrid}>
            <Typography variant="h4">
              {current_company_information.company_name}
            </Typography>
          </Grid>

          <Grid item xs={10} className={styles.textGrid}>
            <Typography variant="h4">Address</Typography>
            <Typography variant="h5">
              {current_company_information.address_1}, <br />
              {current_company_information.address_2} <br />
              <br />
              {current_company_information.city},<br />
              {current_company_information.province},<br />
              {current_company_information.postal_code}
            </Typography>
          </Grid>

          <Grid item xs={10} className={styles.textGrid}>
            <Typography variant="h4">Phone</Typography>
            <Typography variant="h5">
              {current_company_information.phone}
            </Typography>
          </Grid>

          {/* COMPANY DEPARTMENTS - https://mui.com/joy-ui/react-chip/#main-content */}
          <Grid item xs={10} className={styles.textGrid}>
            <Typography variant="h4">Departments</Typography>
            {teams_in_company &&
              teams_in_company.length > 0 &&
              teams_in_company.map((team, index) => (
                <Chip
                  key={index}
                  variant="soft"
                  size="lg"
                  color="warning"
                  label={team.team_name}
                  sx={{ minWidth: 100, marginTop: "10px", marginRight: "10px" }}
                />
              ))}
          </Grid>

          {/* COMPANY ADMINISTRATORS */}
          <Grid item xs={10} className={styles.textGrid}>
            <Typography variant="h4">Administrators</Typography>
            {company_admins &&
              company_admins.map((admin, index) => (
                <Typography variant="h5" key={index}>
                  {admin.first_name} {admin.last_name}
                </Typography>
              ))}
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
