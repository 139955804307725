import React, { useState, useContext } from "react";
import {
  Typography,
  Modal,
  Button,
  TextField,
  Box,
  Stack,
  Select,
  MenuItem,
  Snackbar,
  FormHelperText,
  FormControl,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useAxios from "../../utils/useAxios";
import { useDispatch } from "react-redux";
import { fetchDashboardUsers } from "../Reducers/dashboardUserReducer";
import AuthContext from "../../context/AuthContext";
import { fetchDeskDevices } from "../Reducers/deviceReducer";
export default function EditDeviceModal({ device, employeeList }) {
  const api = useAxios();
  const [modalOpen, setModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(
    "Employee Updated Successfully"
  );
  const [editMode, setEditMode] = useState(false);
  const [inputDeviceName, setInputDeviceName] = useState(device.device_name);
  const [assetTagId, setAssetTagId] = useState(device.asset_tag_id);
  const [assignedUser, setAssignedUser] = useState(device.dashboard_user_id);

  const dispatch = useDispatch();
  const { user } = useContext(AuthContext);

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => {
    setModalOpen(false);
    setEditMode(false);
    // set all values back to default
    setInputDeviceName(device.device_name);
    setAssetTagId(device.asset_tag_id);
    setAssignedUser(device.dashboard_user_id);
  };
  const handleSnackbarOpen = () => setSnackbarOpen(true);
  const handleSnackbarClose = () => setSnackbarOpen(false);

  const action = (
    <React.Fragment>
      <Button color="primary" size="small" onClick={handleSnackbarClose}>
        Close
      </Button>
    </React.Fragment>
  );

  const refreshEmployees = () => {
    dispatch(fetchDashboardUsers([user.company, api]));
  };

  const refreshDevices = () => {
    dispatch(fetchDeskDevices([api, user.company]));
  };

  const handleSave = () => {
    console.log("Assigned User:", assignedUser);
    api
      .put(`/device/${device.id}/`, {
        device_name: inputDeviceName,
        // We have the employee name, we need to get the employee id
        dashboard_user: assignedUser,
        assigned: assignedUser !== null,
        asset_tag_id: assetTagId,//the way this is setup right now it could be any kind of input
        // dashboard_user_id: employeeList.find(
        //   (employee) => employee.name === assignedUser
      })
      .then((res) => {
        if (res.status === 200) {
          console.log("Device Updated Successfully");
          refreshEmployees();
          refreshDevices();
          setSnackbarMessage("Device Updated Successfully");
          handleSnackbarOpen();
          handleModalClose();

        }
      })
      .catch((err) => {
        console.log(err);
        setSnackbarMessage("Device Update Failed: " + err.message);
        handleSnackbarOpen();
        handleModalClose();
      });
  };

  const textFieldStyle = {
    width: "100%",
  };
  return (
    <>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={action}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      />
      <Modal open={modalOpen} onClose={handleModalClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: "5px",
            boxShadow: 24,
            p: 4,
          }}
        >
          <CloseIcon
            sx={{
              float: "right",
              cursor: "pointer",
            }}
            onClick={handleModalClose}
          />
          <Stack
            direction="column"
            justifyContent={"center"}
            alignItems={"center"}
            spacing={3}
            width={"100%"}
          >
            <Typography variant="h4" component="h2">
              Device Details
            </Typography>
            <TextField
              label="Device Name"
              variant="outlined"
              value={inputDeviceName}
              onChange={(e) => setInputDeviceName(e.target.value)}
              disabled={!editMode}
              sx={textFieldStyle}
            />
            <TextField
              label="Asset Tag ID"
              variant="outlined"
              value={assetTagId}
              onChange={(e) => setAssetTagId(e.target.value)}
              disabled={!editMode}
              sx={textFieldStyle}
            />
            <TextField
              label="Device ID"
              variant="outlined"
              value={device.id}
              disabled
              sx={textFieldStyle}
            />
            <TextField
              label="Serial Number"
              variant="outlined"
              value={device.serial_number}
              disabled
              sx={textFieldStyle}
            />
            <TextField
              label="Firmware Version"
              variant="outlined"
              value={device.desk_firmware_version}
              disabled
              sx={textFieldStyle}
            />
            <FormControl sx={{ width: "100%" }}>
              <FormHelperText>{"Assigned User"}</FormHelperText>

              <Select
                labelId="user-select-label"
                id="user-select"
                value={assignedUser}
                label="Assigned User"
                onChange={(e) => setAssignedUser(e.target.value)}
                disabled={!editMode}
                sx={textFieldStyle}
              >
                <MenuItem value={null}>{"Unassigned"}</MenuItem>
                {employeeList.length > 0 &&
                  employeeList.map((employee) => (
                    <MenuItem key={employee.id} value={employee.id}>
                      {employee.first_name + " " + employee.last_name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            {/* Show "Device connected" in green if is_connected is true
              show "Device disconnected" in red otherwise
            */}
            <Typography
              variant="caption"
              component="p"
              sx={{
                color: device.is_connected ? "green" : "red",
                fontWeight: "bold",
              }}
            >
              {device.is_connected ? "Device Connected" : "Device Disconnected"}
            </Typography>
            <Button
              // should say "edit" if editMode is false, "save" if editMode is true
              // when clicked, should toggle editMode and if the "save" button is clicked,
              // should update the device details in the database
              onClick={() => {
                if (editMode) {
                  handleSave();
                }
                setEditMode(!editMode);
                // console.log("deviceName: ", deviceName);
              }}
              sx={{ color: "white" }}
            >
              {editMode ? "Save" : "Edit"}
            </Button>
          </Stack>
        </Box>
      </Modal>
      <Button
        variant="contained"
        onClick={handleModalOpen}
        sx={{ color: "white" }}
      >
        Edit
      </Button>
    </>
  );
}
