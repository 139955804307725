import React from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import "./WelcomeScreen.scss";
import logo from "../../assets/images/ergonomyx_logo.png";
import { Typography , Box} from "@mui/material";
import BetaPill from "../BetaPill/BetaPill";

export default function WelcomeScreen() {
  return (
      <div className='welcome'>
        <div className="welcome__header">
          <img src={logo} alt="" className="welcome__img" />
          <Typography variant='h1' className="welcome__heading"><span className="welcome__letter">E</span>rgonomyx</Typography>
        </div>
        <div className="welcome__headline-div">
          <div className="welcome__headline">
            Company <strong> Wellness</strong> analytics 
            <br /> for your employees health
          </div>
        </div>
        <div className="welcome__button-div">
          <Button component={Link} to="/login" className='welcome__button btn-login' variant="contained" type='submit'>Login</Button>
        </div>

        {/* <Box
          sx={{
            width:"70%",
            marginTop:"10vh",
          }}
        >
          <BetaPill />
        </Box> */}

    </div>
  );
}
