import Grid from "@mui/system/Unstable_Grid/Grid";
import { Box } from "@mui/system";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import React from "react";


const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  // textAlign: "center",
  borderRadius: "25px",
  maxHeight: "100%"
}));

const ReleaseNotesPage = () => {

const releaseNotesData = [
  {
    date: "2021-09-01",
    version: "1.0.0",
    notes: [
      "Initial release",
    ],
  },
  {
    date: "2021-08-01",
    version: "0.9.1",
    notes: [
     "Beta", "test2"
    ],
  },
]
  return (
    <div>
      <Box
        sx={{
          flexGrow: 1,
          height: "100%",
        }}
      >
        <Grid
          container
          rowSpacing={2}
          columnSpacing={2}
          alignItems="stretch"
          sx={{mt: 1, ml: 1, mr: 1 }}
        >
            <Item>
                <h1><strong>Release Notes placeholder page!</strong></h1>
                {
                  releaseNotesData.map((release, index) => {
                   return( <div key={index}>
                      <h2>{release.version}</h2>
                      <h3>{release.date}</h3>
                      <ul>
                        {release.notes.map((note, index) => {
                          return <li>{note}</li>
                        })}
                      </ul>

                    </div>)
                }
                  )}
            </Item>
        </Grid>
      </Box>
    </div>
  );
};

export default ReleaseNotesPage;
