import { Box, Paper, Typography, Grid } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import DeviceStatusCard from "../components/DeviceStatusCard/DeviceStatusCard";
import { fetchDeviceStatusList } from "../components/Reducers/deviceReducer";
import { useDispatch, useSelector } from "react-redux";
import useAxios from "../utils/useAxios";
import LinearLoading from "../components/LinearLoading/LinearLoading";
import AuthContext from "../context/AuthContext";

export default function DeviceListStatusPage() {
  const dispatch = useDispatch();
  const api = useAxios();
  const { user } = useContext(AuthContext);
  console.log(user);

  useEffect(() => {
    dispatch(fetchDeviceStatusList(api));
  }, []);
  const devicesStatus = useSelector((state) => state.device.deviceStatusList);
  const [cardData, setCardData] = useState([]);

  useEffect(() => {
    setCardData(devicesStatus);
  }, [devicesStatus]);

  return (
    <>
      <Box p={2}>
        <Paper
          sx={{
            borderRadius: "25px",
            padding: "1rem",
          }}
        >
          <Grid container p={3} spacing={1}>
            {/* Header */}
            <Grid
              item
              xs={12}
              sx={{
                marginBottom: "1rem",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography variant="h2">Connection Status</Typography>
              {user.user_type == 3 && (
                <Box
                  sx={{
                    borderRadius: "5px",
                    backgroundColor: "rgba(255, 166, 41, 0.5)",
                    padding: "0.5rem",
                  }}
                >
                  Showing devices for all companies
                </Box>
              )}
            </Grid>
            {/* Device Cards */}
            {cardData.length === 0 ? (
              <LinearLoading />
            ) : (
              cardData &&
              cardData.map((deviceStatus) => (
                <Grid item xs={12} md={6} key={deviceStatus.serialNumber}>
                  <DeviceStatusCard deviceStatus={deviceStatus} userType={user.user_type} />
                </Grid>
              ))
            )}
          </Grid>
        </Paper>
      </Box>
    </>
  );
}
