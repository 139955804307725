import { Box, Container } from "@mui/material";
import React from "react";
import ResetPasswordForm from "../components/ResetPasswordForm/ResetPasswordForm";

export default function ResetPasswordPage() {
  return (
    <>
    {/* Center the form in the middle of the page vertically */}
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "white"
      }}
    >
        <ResetPasswordForm />
    </Box>
    </>
  );
}
