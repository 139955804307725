import RegistrationForm from "../components/RegistrationForm";

function Register() {

  return (
    <div>
      <RegistrationForm/>
    </div>
  );
}

export default Register;