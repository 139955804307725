import React, { useState } from "react";
import {
  Paper,
  Typography,
  Box,
  Button,
  Snackbar,
  Stack,
  Modal,
  Grid,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import useAxios from "../../utils/useAxios";
import { useDispatch } from "react-redux";
import { fetchDeskDevices, fetchBikeDevices } from "../Reducers/deviceReducer";
import AuthContext from "../../context/AuthContext";
import { useContext } from "react";

export default function UserDeviceDetail({ currentUserId, device }) {
  // Modal State
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Snackbar State
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleSnackbarOpen = () => setSnackbarOpen(true);
  const handleSnackbarClose = () => setSnackbarOpen(false);
  const action = (
    <React.Fragment>
      <Button color="primary" size="small" onClick={handleSnackbarClose}>
        Close
      </Button>
    </React.Fragment>
  );

    const api = useAxios()
    const dispatch = useDispatch()
    const { user } = useContext(AuthContext)

    const refreshDevices = () => {
      dispatch(fetchDeskDevices([api, user.company]));
      dispatch(fetchBikeDevices([api, user.company]));
    };


  const handleSubmit = () => {
    api.post(`/device/unassign/${device.id}/`).then((res) => {
      if (res.status >= 200 && res.status < 300) {
        console.log("Device Unassigned Successfully")
        handleClose();
        refreshDevices();
      }
    }).catch((err) => {
      console.log(err);
      setSnackbarMessage("Device Update Failed: " + err.message);
      handleSnackbarOpen();
      handleClose();
    });
  };


  return (
    <>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={action}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      />
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            // should be scrollable if content is too long
            overflow: "auto",
            maxHeight: "90%",
            borderRadius: 2,
          }}
        >
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Typography variant="h5">Device Details</Typography>
            {/* Two columns in Paper component showing device detail labels and values */}
            <Paper
              elevation={3}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
                width: "100%",
                padding: "1rem",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography fontWeight="bold">Device Name</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{device.device_name}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography fontWeight="bold">Device ID</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">{device.id}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography fontWeight="bold">Serial Number</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    {device.serial_number}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
            <Box>
              <Button
                variant="contained"
                color="error"
                onClick={handleSubmit}
                sx={{ width: "100%" }}
              >
                Unassign Device
              </Button>
            </Box>

          </Stack>
        </Box>
      </Modal>
      <Paper
        elevation={3}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "row",
          width: "100%",
          padding: "1rem",
          "&:hover": {
            backgroundColor: "background.default",
          },
        }}
        onClick={handleOpen}
      >
        <Typography variant="h6">{device.device_name}</Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Typography variant="body1">
            {device.is_connected ? "Connected" : "Disconnected"}
          </Typography>
          {device.is_connected ? (
            <CheckCircleIcon
              sx={{ color: "success.main", marginLeft: "1rem" }}
            />
          ) : (
            <CancelIcon sx={{ color: "error.main", marginLeft: "1rem" }} />
          )}
        </Box>
      </Paper>
    </>
  );
}
