import {
  Paper,
  Typography,
  Modal,
  Button,
  TextField,
  Box,
  Snackbar,
  Grid,
} from "@mui/material";
import React, { useState } from "react";
import useAxios from "../../utils/useAxios";
export default function AdminCompanies({ company }) {
  const api = useAxios();

  // Modal State
  const [open, setOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setEditMode(false);
    // reset useState values
    setCompanyName(company.company_name);
    setAddress_1(company.address_1);
    setAddress_2(company.address_2);
    setCity(company.city);
    setProvince(company.province);
    setPostal_code(company.postal_code);
    setPhone(company.phone);
    setCountry(company.country);
  };

  // Snackbar State
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(
    "Company Updated Successfully"
  );
  const handleSnackbarOpen = () => setSnackbarOpen(true);
  const handleSnackbarClose = () => setSnackbarOpen(false);
  const action = (
    <React.Fragment>
      <Button color="primary" size="small" onClick={handleSnackbarClose}>
        Close
      </Button>
    </React.Fragment>
  );

  // Form State
  const [companyName, setCompanyName] = useState(company.company_name);
  const [address_1, setAddress_1] = useState(company.address_1);
  const [address_2, setAddress_2] = useState(company.address_2);
  const [city, setCity] = useState(company.city);
  const [province, setProvince] = useState(company.province);
  const [postal_code, setPostal_code] = useState(company.postal_code);
  const [phone, setPhone] = useState(company.phone);
  const [country, setCountry] = useState(company.country);

  const handleSave = () => {
    // create an array of the fields that are changing
    const body = {};
    if (companyName !== company.company_name) {
      body.company_name = companyName;
    }
    if (address_1 !== company.address_1) {
      body.address_1 = address_1;
    }
    if (address_2 !== company.address_2) {
      body.address_2 = address_2;
    }
    if (city !== company.city) {
      body.city = city;
    }
    if (province !== company.province) {
      body.province = province;
    }
    if (postal_code !== company.postal_code) {
      body.postal_code = postal_code;
    }
    if (phone !== company.phone) {
      body.phone = phone;
    }
    if (country !== company.country) {
      body.country = country;
    }
    // if the array is empty, then there are no changes to save
    if (Object.keys(body).length === 0) {
      setSnackbarMessage("No Changes to Save");
      handleSnackbarOpen();
      handleClose();
      return;
    }
    // otherwise, send the changes to the server
    api
      .put(`/company/${company.id}/`, body)
      .then((res) => {
        if (res.status === 200) {
          console.log("Company Updated Successfully");
          setSnackbarMessage("Company Updated Successfully");
          handleSnackbarOpen();
          handleClose();
        }
      })
      .catch((err) => {
        console.log(err);
        setSnackbarMessage("Company Update Failed: " + err.message);
        handleSnackbarOpen();
        handleClose();
      });
  };
  return (
    <>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        action={action}
      />

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", md: 500 },
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            // should be scrollable if content is too long
            overflow: "auto",
            maxHeight: "90%",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4" mb={2}>
                Company Details
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="outlined-basic"
                label="Company Name"
                variant="outlined"
                fullWidth
                mb={2}
                disabled={!editMode}
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="outlined-basic"
                label="Company ID"
                variant="outlined"
                fullWidth
                mb={2}
                disabled
                value={company.id}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="outlined-basic"
                label="Phone"
                variant="outlined"
                fullWidth
                mb={2}
                value={phone}
                disabled={!editMode}
                onChange={(e) => setPhone(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="outlined-basic"
                label="Address 1"
                variant="outlined"
                fullWidth
                mb={2}
                value={address_1}
                disabled={!editMode}
                onChange={(e) => setAddress_1(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="outlined-basic"
                label="Address 2"
                variant="outlined"
                fullWidth
                mb={2}
                value={address_2}
                disabled={!editMode}
                onChange={(e) => setAddress_2(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="outlined-basic"
                label="City"
                variant="outlined"
                fullWidth
                mb={2}
                value={city}
                disabled={!editMode}
                onChange={(e) => setCity(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="outlined-basic"
                label="Province"
                variant="outlined"
                fullWidth
                mb={2}
                value={province}
                disabled={!editMode}
                onChange={(e) => setProvince(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="outlined-basic"
                label="Postal Code"
                variant="outlined"
                fullWidth
                mb={2}
                value={postal_code}
                disabled={!editMode}
                onChange={(e) => setPostal_code(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="outlined-basic"
                label="Country"
                variant="outlined"
                fullWidth
                mb={2}
                value={country}
                disabled={!editMode}
                onChange={(e) => setCountry(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              {/* Green dot with number of active devices */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "left",
                  alignItems: "center",
                  width: "100%",
                }}
                mb={2}
              >
                <Box
                  sx={{
                    height: "10px",
                    width: "10px",
                    borderRadius: "50%",
                    backgroundColor: "green",
                    marginRight: "8px",
                  }}
                ></Box>

                <Typography variant="h7">
                  {company.active_devices} active devices
                </Typography>
              </Box>
            </Grid>

            <Button
              // should say "edit" if editMode is false, "save" if editMode is true
              // when clicked, should toggle editMode and if the "save" button is clicked,
              // should update the device details in the database
              onClick={() => {
                if (editMode) {
                  handleSave();
                }
                setEditMode(!editMode);
                // console.log("deviceName: ", deviceName);
              }}
              sx={{ color: "white" }}
            >
              {editMode ? "Save" : "Edit"}
            </Button>
          </Grid>
        </Box>
      </Modal>
      <Paper
        sx={{
          height: "100px",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "left",
          marginY: 1,
          paddingX: 2,
          "&:hover": {
            // background color should darken in light mode and lighten in dark mode
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[200]
                : theme.palette.grey[800],

            color: "white",
          },
        }}
        onClick={handleOpen}
      >
        <Typography variant="h6">{company.company_name}</Typography>
        <Typography variant="caption">
          {company.active_devices} active devices
        </Typography>
      </Paper>
    </>
  );
}
