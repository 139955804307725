import { Box, Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import useAxios from "../../utils/useAxios";

/* Props would include the dashboard user id */
export default function DeleteEmployeeButton(props) {
    console.log(props)
  const api = useAxios();
  const handleDeleteDashboardUser = (e) => {
    //do a DELETE to delete the user...

    try {
      api.delete(`/dashboard_user/${props.user_id}/`);
    } catch (error) {
      console.log(error);
    }

    // there's a few related things to a user (user totals, assigned devices, rewards, what else ?)
    // so those tables would need to be cleaned too... but can leave that alone for now...

    // we could build a backend endpoint that took care of cleaning anything related to that user...
    // is there something like 'CASCADE' in python ?
  };

  return (
    <div>
      <Box>
        <Button
          onClick={handleDeleteDashboardUser}
          variant="contained"
          color="error"
          disabled={props.disabled}
        >
          Delete Employee
          <DeleteIcon />
        </Button>
      </Box>
    </div>
  );
}
