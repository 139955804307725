import React, { useContext } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PrivateRoute from "./utils/PrivateRoute";
import ThemeContext from "./context/ThemeContext";
import Home from "./pages/homePage";
import TeamMemberRewardsPage from "./pages/TeamMemberPages/teamMemberRewardsPage";
import TeamMemberChallengesPage from "./pages/TeamMemberPages/teamMemberChallengesPage";
import Login from "./pages/loginPage";
import Register from "./pages/registerPage";
import ProtectedPage from "./pages/ProtectedPage";
import Layout from "./components/Layout";
import Forgot from "./pages/forgotPage";
import Employees from "./pages/employeePage";
import EmployeeTeams from "./pages/employeeTeamPage";
import Team from "./pages/teamPage";
import ExportDataPage from "./pages/homeExportDataPage";
import leaderboardPage from "./pages/homeLeaderboardPage";
import DeviceListDeskPage from "./pages/deviceListDeskPage";
import DeviceListPageBike from "./pages/deviceListBikePage";
import Welcome from "./pages/welcomePage";
import HomeDeskPage from "./pages/homeAnalyticsDeskPage";
import HomeBikePage from "./pages/homeAnalyticsBikePage";
import CompanyAdminChallengesPage from "./pages/CompanyAdminPages/companyAdminChallengesPage";
import SettingsPage from "./pages/settingsPage";
import SettingsCompanyPage from "./pages/settingsCompanyPage";
import ResetPasswordPage from "./pages/resetPasswordPage";
import AdminPage from "./pages/adminPage";
import MqttDeskControlsPage from "./pages/MqttDeskControlsPage";
import RoutinesPage from "./pages/RoutinesPage"
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { grey } from "@mui/material/colors";
import { store as dashboardStore } from "./components/dashboardStore"; //this is the actual redux dataStore
import { Provider } from "react-redux";
import AuthContext from "./context/AuthContext";
import { Redirect } from "react-router-dom";
import DataMonitoringPage from "./components/DataMonitoring/DataMonitoringPage"
import TeamMemberProfilePage from "./pages/TeamMemberPages/teamMemberProfilePage_Jack";
import DeviceListStatusPage from "./pages/deviceListStatusPage";
import ReleaseNotesPage from "./pages/ReleaseNotes";

// here we will set the max events listeners to 15. The default is 10, and will cause an overflow
// error if we are waiting for more than 10 async calls to finish.
// https://stackoverflow.com/questions/50709059/maxlistenersexceededwarning-possible-eventemitter-memory-leak-detected-11-mess
require("events").EventEmitter.defaultMaxListeners = 15;

const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === "light"
      ? {
          // palette values for light mode
          primary: {
            main: "#FFA629",
            orange: "#FFA629",
          },
          background: {
            default: grey[400],
            paper: grey[50],
          },

          divider: grey[100],
          text: {
            primary: grey[900],
            secondary: grey[800],
          },
        }
      : {
          // palette values for dark mode
          primary: {
            main: "#FFA629",
          },
          divider: grey[700],
          background: {
            default: grey[700],
            paper: grey[900],
          },
          text: {
            primary: grey[50],
            secondary: grey[100],
          },
        }),
  },
  typography: {
    fontFamily: "Montserrat, sans-serif",
    h1: {
      fontFamily: "Montserrat, sans-serif",
      fontWeight: 600,
      fontSize: "2.3rem",
    },
    h2: {
      fontFamily: "Montserrat, sans-serif",
      fontWeight: 600,
      fontSize: "2rem",
    },
    h3: {
      fontFamily: "Montserrat, sans-serif",
      fontWeight: 600,
      fontSize: "1.7rem",
    },
    h4: {
      fontFamily: "Montserrat, sans-serif",
      fontWeight: 600,
      fontSize: "1.4rem",
    },
    h5: {
      fontFamily: "Montserrat, sans-serif",
      fontWeight: 400,
      fontSize: "1.3rem",
    },
    h6: {
      fontFamily: "Montserrat, sans-serif",
      fontWeight: 400,
      fontSize: "1.2rem",
    },
    body1: {
      fontFamily: "Montserrat, sans-serif",
      fontWeight: 300,
      fontSize: "1rem",
    },
    body2: {
      fontFamily: "Montserrat, sans-serif",
      fontWeight: 300,
      fontSize: "0.8rem",
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true, // no shadow on buttons
        variant: "contained", // all buttons are contained by default
      },
      styleOverrides: {
        root: {
          textTransform: "none", // no auto capitalization
          borderRadius: "20px", // pill style button
          // fontweight is bold
          fontWeight: "bold",
        },
      },
    },
  },
});

function App() {
  const { theme } = useContext(ThemeContext);

  const innerTheme = React.useMemo(
    () => createTheme(getDesignTokens(theme)),
    [theme]
  );

  const { user } = useContext(AuthContext);

  return (
    <Provider store={dashboardStore}>
      <ThemeProvider theme={innerTheme}>
        <CssBaseline />
        <Layout>
          <Switch>
            <PrivateRoute component={ProtectedPage} path="/protected" exact />
            <Route component={Welcome} path="/" exact />
            <Route component={Login} path="/login" />
            <Route component={Register} path="/register" />
            <Route component={Forgot} path="/forgot" />
            <Route component={Employees} path="/employees" exact />
            <Route component={EmployeeTeams} path="/employees/teams" exact />
            <Route component={Home} path="/home" exact />
            <Route component={TeamMemberRewardsPage} path="/rewards" exact />
            <Route component={RoutinesPage} path="/routines" exact />
            <Route
              component={TeamMemberChallengesPage}
              path="/challenges"
              exact
            />
            <Route
              component={CompanyAdminChallengesPage}
              path="/manageChallenges"
              exact
            />
            <Route component={Team} path="/home/teams" exact />
            <Route component={ExportDataPage} path="/home/export" exact />
            <Route component={leaderboardPage} path="/home/leaderboard" exact />
            <Route component={HomeDeskPage} path="/home/desk" exact />
            <Route component={HomeBikePage} path="/home/bike" exact />
            <Route component={DeviceListDeskPage} path="/devices/desk" exact />
            <Route component={DeviceListPageBike} path="/devices/bike" exact />
            <Route component={DeviceListStatusPage} path="/devices/status" exact />
            <Route component={SettingsPage} path="/settings/account" exact />
            <Route component={MqttDeskControlsPage} path="/control_desk" exact />
            <Route component={DataMonitoringPage} path="/data_monitoring" exact />
            <Route component={ReleaseNotesPage} path="/release_notes" exact />
            <Route component={TeamMemberProfilePage} path="/profile" exact />

            {/* If user.user_type == 3 render a route to AdminPage, otherwise omit the route */}
            { user && user.user_type == 3 ? <Route component={AdminPage} path="/admin" exact /> : null }
            <Route
              component={SettingsCompanyPage}
              path="/settings/company"
              exact
            />
            {/* match a route to /reset path where it accept username parameter */}
            <Route
              component={ResetPasswordPage}
              path="/reset/:authcode"
              exact
            />
            {/* Catch all unrouted urls */}

            <Route render={() => <Redirect to={{pathname: "/home"}} />} />
            {/* <Route /> */}
          </Switch>
        </Layout>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
