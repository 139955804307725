import styles from "../styles/home.scss";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { Box } from "@mui/system";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { format } from "date-fns";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ErgoAreaChart from "../components/ErgoAreaChart/ErgoAreaChart";
import DeskDataTable from "../components/DeskDataTable/DeskDataTable";
import { useContext, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AuthContext from "../context/AuthContext";
import { fetchDeskAnalytics } from "../components/Reducers/dashboardUserReducer";
import { fetchHourlyDeskUpsert } from "../components/Reducers/hourlyDeskUpsertReducer";
import useAxios from "../utils/useAxios";
import ThemeContext from "../context/ThemeContext"; //import the theme context

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  borderRadius: "25px",
}));

const HomeDeskPage = () => {

  const {theme, toggleTheme} = useContext(ThemeContext) //this is importing the theme , in order to change the button color depending on current theme

  const api = useAxios();

  const userDeskAnalytics = useSelector(
    (state) => state.dashboard_user.desk_analytics
  );

  const [totalCaloriesBurned, setTotalCaloriesBurned] = useState(0);
  const [mostCaloriesBurned, setMostCaloriesBurned] = useState(0);
  const [averageCaloriesBurned, setAverageCaloriesBurned] = useState(0);
  const [totalTimeStanding, setTotalTimeStanding] = useState(0);
  const [mostTimeStanding, setMostTimeStanding] = useState(0);
  const [averageTimeStanding, setAverageTimeStanding] = useState(0);
  const [totalMovements, setTotalMovements] = useState(0);
  const [mostMovements, setMostMovements] = useState(0);
  const [averageMovements, setAverageMovements] = useState(0);
  const [totalStandingCalories, setTotalStandingCalories] = useState(0);
  const [totalSittingCalories, setTotalSittingCalories] = useState(0);

  async function setValues(data) {
    // Calculate Total Time spent Standing
    const totalTimeStanding = data.reduce((acc, curr) => {
      return acc + curr.timeStanding;
    }, 0);

    setTotalTimeStanding(totalTimeStanding);

    setAverageTimeStanding(totalTimeStanding / 14);

    const mostTimeStanding = data.reduce((acc, curr) => {
      if (acc.timeStanding > curr.timeStanding) {
        return acc;
      } else {
        return curr;
      }
    }, 0);

    setMostTimeStanding(mostTimeStanding);

    // Calculate all Caloric Calculations

    const totalSittingCalories = data.reduce((acc, curr) => {
      return acc + curr.totalSittingCalories;
    }, 0);

    setTotalSittingCalories(totalSittingCalories);

    const totalStandingCalories = data.reduce((acc, curr) => {
      return acc + curr.totalStandingCalories;
    }, 0);

    setTotalStandingCalories(totalStandingCalories);

    const totalCaloriesBurned = data.reduce((acc, curr) => {
      return acc + curr.caloriesBurned;
    }, 0);

    setTotalCaloriesBurned(totalCaloriesBurned);

    setAverageCaloriesBurned(totalCaloriesBurned / 14);

    const mostCaloriesBurned = data.reduce((acc, curr) => {
      if (acc.caloriesBurned > curr.caloriesBurned) {
        return acc;
      } else {
        return curr;
      }
    }, 0);

    setMostCaloriesBurned(mostCaloriesBurned);

    // Calculate Movements
    const totalMovements = data.reduce((acc, curr) => {
      return acc + curr.movements;
    }, 0);

    setTotalMovements(totalMovements);

    setAverageMovements(totalMovements / 14);

    const mostMovements = data.reduce((acc, curr) => {
      if (acc.movements > curr.movements) {
        return acc;
      } else {
        return curr;
      }
    }, 0);

    setMostMovements(mostMovements);
  }

  // DATA FETCHING
  const { user, authTokens } = useContext(AuthContext);
  const dispatch = useDispatch();
  useEffect(() => {
    if (userDeskAnalytics && userDeskAnalytics.length > 0) {
      setValues(userDeskAnalytics);
    } else {
      if (user && authTokens) {
        dispatch(fetchDeskAnalytics(api));
        dispatch(fetchHourlyDeskUpsert([user.company, api]));
      }
    }
  }, [user, userDeskAnalytics]);//removed api , userDeskAnalytics

  // State vars for the dropdown Select components
  const [chartDataKey, setChartDataKey] = useState("caloriesBurned");
  const [dept, setDept] = useState("All");
  const [range, setRange] = useState("Weekly");
  return (
    <div >
      {/* <Appbar menuItems={menuItems} page="Desk Data Info" /> */}
      <Box
        sx={{
          flexGrow: 1,
          height: "100%",
          width: "100%",
        }}
      >
        {/* PARENT GRID */}
        <Grid
          container
          rowSpacing={2}
          columnSpacing={2}
          sx={{ mt: 1, ml: 1, mr: 1 }}
        >
          <Grid item xs={12}>
            <Item>
              <Box
                sx={{
                  flexGrow: 1,
                  textAlign: "left",
                }}
              >
                <Grid container spacing={2}>
                  {/* TITLE AND DATE */}
                  <Grid item xs={12} lg={6}>
                    <Typography variant="h2">
                      <Link to={"/home"}>
                      {theme === "dark" ?
                          <ArrowBackIcon 
                          sx={{ 
                            mr: 2,
                            color: "white",
                            cursor: "pointer",
                            fontSize: "3rem",
                            marginRight: "1rem",
                          }}
                          />
                        : 
                        <ArrowBackIcon 
                        sx={{ 
                          mr: 2,
                          color: "black",
                          cursor: "pointer",
                          fontSize: "3rem",
                          marginRight: "1rem",
                        }}
                        />
                        }
                      </Link>
                      {chartDataKey === "caloriesBurned" && (
                        <span>Calories Burned</span>
                      )}
                      {chartDataKey === "timeStanding" && (
                        <span>Time Standing</span>
                      )}
                      {chartDataKey === "movements" && (
                        <span>Active Movements</span>
                      )}
                    </Typography>
                    <Typography variant="h5" sx={{ ml: 6 }}>
                      <strong>{format(new Date(), "MMM")} </strong>
                      {format(new Date(), "dd")}{" "}
                    </Typography>
                  </Grid>

                  {/* DROPDOWN SELECT COMPONENTS */}
                  <Grid item xs={12} lg={6}>
                    {/* here we want 3 grid items, each with equal width of 4m "" */}
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        {/* made this span the full width 4->12 */}
                        <FormControl fullWidth>
                          <InputLabel
                            id="bikeDataDropdown"
                            sx={{ color: "orange" }}
                          >
                            Data
                          </InputLabel>
                          <Select
                            labelId="deskDataDropdown"
                            id="demo-simple-select"
                            value={chartDataKey}
                            label="Data"
                            onChange={(e) => setChartDataKey(e.target.value)}
                            sx={{
                              borderColor: "orange",
                              outlineColor: "orange",
                            }}
                          >
                            <MenuItem value={"caloriesBurned"} defaultValue>
                              Calories Burned
                            </MenuItem>
                            <MenuItem value={"timeStanding"}>
                              Minutes Standing
                            </MenuItem>
                            <MenuItem value={"movements"}>
                              Active Movements
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      {/* <Grid item xs={12}>
                        <FormControl fullWidth>
                          <InputLabel
                            id="bikeDeptDropdown"
                            sx={{ color: "orange" }}
                          >
                            Dept
                          </InputLabel>
                          <Select
                            disabled
                            labelId="bikeDeptDropdown"
                            id="demo-simple-select"
                            value={dept}
                            label="Data"
                            onChange={(e) => setDept(e.target.value)}
                            sx={{
                              borderColor: "orange",
                              outlineColor: "orange",
                            }}
                          >
                            <MenuItem value={"All"} defaultValue>
                              All
                            </MenuItem>
                            <MenuItem value={"Marketting"}>Marketing</MenuItem>
                            <MenuItem value={"Engineering"}>
                              Engineering
                            </MenuItem>
                            <MenuItem value={"Human Resources"}>
                              Human Resources
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid> */}
                      {/* <Grid item xs={4}>
                        <FormControl fullWidth>
                          <InputLabel
                            id="bikeRangeDropdown"
                            sx={{ color: "orange" }}
                          >
                            Range
                          </InputLabel>
                          <Select
                            disabled
                            labelId="bikeRangeDropdown"
                            id="demo-simple-select"
                            value={range}
                            label="Range"
                            onChange={(e) => setRange(e.target.value)}
                            sx={{
                              borderColor: "orange",
                              outlineColor: "orange",
                            }}
                          >
                            <MenuItem value={"Weekly"} defaultValue>
                              Weekly
                            </MenuItem>
                            <MenuItem value={"Bi-Weekly"}>Bi-Weekly</MenuItem>
                            <MenuItem value={"Monthly"}>Monthly</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>*/}
                    </Grid>

                    {/* END OF DROPDOWN SELECT COMPONENTS */}
                  </Grid>

                  {/* AREA CHART */}
                  <Grid item xs={12} lg={9}>
                    <ErgoAreaChart
                      xDataKey={"date"}
                      yDataKey={chartDataKey}
                      chartData={userDeskAnalytics}
                    />
                  </Grid>

                  {/* STATS GRID */}
                  <Grid
                    item
                    container
                    xs={12}
                    // md={6}
                    lg={3}
                    sx={{ textAlign: "center" }}
                  >
                    {/* Calories Burned */}
                    {chartDataKey === "caloriesBurned" && (
                      <>
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              alignContent: "left",
                              flexDirection: "column",
                              padding:2
                            }}
                          >
                            <Typography variant="h6">14 Day Total</Typography>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                alignContent: "left",
                                flexDirection: "row",
                              }}
                            >
                              <Typography
                                variant="h3"
                                className={styles.chartValue}
                                mx={1}
                                sx={{ color: "orange" }}

                              >
                                {/* Round this average to an integer */}
                                {Math.round(totalCaloriesBurned) || 0}
                              </Typography>
                              <Typography
                                variant="caption"
                                className={styles.chartValue}
                                sx={{ color: "orange" }}

                              >
                                kcal
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              alignContent: "left",
                              flexDirection: "column",
                              padding:2
                            }}
                          >
                            <Typography variant="h6">Best Day</Typography>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                alignContent: "left",
                                flexDirection: "row",
                              }}
                            >
                              <Typography
                                variant="h3"
                                className={styles.chartValue}
                                mx={1}
                                sx={{ color: "orange" }}
                              >
                                {/* Round this average to an integer */}
                                {Math.round(
                                  mostCaloriesBurned.caloriesBurned
                                ) || 0}
                              </Typography>
                              <Typography
                                variant="caption"
                                className={styles.chartValue}
                                sx={{ color: "orange" }}

                              >
                                kcal
                              </Typography>
                            </Box>
                          </Box>
                          {/* --------------------------------------------------------- */}

                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              alignContent: "left",
                              flexDirection: "column",
                              padding: 2
                            }}
                          >
                            <Typography variant="h6">Average</Typography>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                alignContent: "left",
                                flexDirection: "row",
                              }}
                            >
                              <Typography
                                variant="h3"
                                className={styles.chartValue}
                                mx={1}
                                sx={{ color: "orange" }}
                              >
                                {/* Round this average to an integer */}
                                {Math.round(averageCaloriesBurned)}

                              </Typography>
                              <Typography
                                variant="caption"
                                className={styles.chartValue}
                                sx={{ color: "orange" }}

                              >
                                kcal
                              </Typography>
                            </Box>
                          </Box>

                        </Grid>
                      </>
                    )}
                    {chartDataKey === "timeStanding" && (
                      <>
                        <Grid item xs={12}>
                        <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              alignContent: "left",
                              flexDirection: "column",
                              padding:2
                            }}
                          >
                            <Typography variant="h6">14 Day Total</Typography>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                alignContent: "left",
                                flexDirection: "row",
                              }}
                            >
                              <Typography
                                variant="h3"
                                className={styles.chartValue}
                                mx={1}
                                sx={{ color: "orange" }}

                              >
                                {/* Round this average to an integer */}
                                {/* if over 1000 convert to km */}
                                {
                                  totalTimeStanding > 60 ? (totalTimeStanding / 60).toFixed(1) : totalTimeStanding
                                }
                              </Typography>
                              <Typography
                                variant="caption"
                                className={styles.chartValue}
                                sx={{ color: "orange" }}

                              >
                                {
                                  totalTimeStanding > 60 ? "hrs" : "min"
                                }
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              alignContent: "left",
                              flexDirection: "column",
                              padding:2
                            }}
                          >
                            <Typography variant="h6">Best Day</Typography>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                alignContent: "left",
                                flexDirection: "row",
                              }}
                            >
                              <Typography
                                variant="h3"
                                className={styles.chartValue}
                                mx={1}
                                sx={{ color: "orange" }}

                              >
                                {/* Round this average to an integer */}
                                {/* if over 1000 convert to km */}
                                {
                                  mostTimeStanding.timeStanding > 60 ? (mostTimeStanding.timeStanding / 60).toFixed(1) : Math.round(mostTimeStanding.timeStanding)
                                }
                              </Typography>
                              <Typography
                                variant="caption"
                                className={styles.chartValue}
                                sx={{ color: "orange" }}

                              >
                                {
                                  mostTimeStanding.timeStanding > 60 ? "hrs" : "min"
                                }
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              alignContent: "left",
                              flexDirection: "column",
                              padding:2
                            }}
                          >
                            <Typography variant="h6">Average</Typography>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                alignContent: "left",
                                flexDirection: "row",
                              }}
                            >
                              <Typography
                                variant="h3"
                                className={styles.chartValue}
                                mx={1}
                                sx={{ color: "orange" }}

                              >
                                {/* Round this average to an integer */}
                                {/* if over 1000 convert to km */}
                                {
                                  averageTimeStanding > 60 ? (averageTimeStanding / 60).toFixed(1) : Math.round(averageTimeStanding)
                                }
                              </Typography>
                              <Typography
                                variant="caption"
                                className={styles.chartValue}
                                sx={{ color: "orange" }}

                              >
                                {
                                  averageTimeStanding > 60 ? "hrs" : "min"
                                }
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </>
                    )}
                    {/* Movements */}
                    {chartDataKey === "movements" && (
                      <>
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              alignContent: "left",
                              flexDirection: "column",
                              padding:2
                            }}
                          >
                            <Typography variant="h6">14 Day Total</Typography>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                alignContent: "left",
                                flexDirection: "row",
                              }}
                            >
                              <Typography
                                variant="h3"
                                className={styles.chartValue}
                                mx={1}
                                sx={{ color: "orange" }}

                              >
                                {/* Round this average to an integer */}
                                {Math.round(totalMovements) || 0}
                              </Typography>
                              <Typography
                                variant="caption"
                                className={styles.chartValue}
                                sx={{ color: "orange" }}

                              >
                                
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              alignContent: "left",
                              flexDirection: "column",
                              padding:2
                            }}
                          >
                            <Typography variant="h6">Best Day</Typography>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                alignContent: "left",
                                flexDirection: "row",
                              }}
                            >
                              <Typography
                                variant="h3"
                                className={styles.chartValue}
                                mx={1}
                                sx={{ color: "orange" }}
                              >
                                {/* Round this average to an integer */}
                                {Math.round(
                                  mostMovements.movements) || 0}
                              </Typography>
                              <Typography
                                variant="caption"
                                className={styles.chartValue}
                                sx={{ color: "orange" }}

                              >
                              </Typography>
                            </Box>
                          </Box>
                          {/* --------------------------------------------------------- */}

                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              alignContent: "left",
                              flexDirection: "column",
                              padding: 2
                            }}
                          >
                            <Typography variant="h6">Average</Typography>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                alignContent: "left",
                                flexDirection: "row",
                              }}
                            >
                              <Typography
                                variant="h3"
                                className={styles.chartValue}
                                mx={1}
                                sx={{ color: "orange" }}
                              >
                                {/* Round this average to an integer */}
                                {Math.round(averageMovements)}

                              </Typography>
                              <Typography
                                variant="caption"
                                className={styles.chartValue}
                                sx={{ color: "orange" }}

                              >
                              
                              </Typography>
                            </Box>
                          </Box>

                        </Grid>
                      </>
                    )}
                  </Grid>

                  {/* DESK DATA TABLE */}
                  <Grid item xs={12}>
                    <DeskDataTable />
                  </Grid>
                </Grid>
              </Box>
            </Item>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default HomeDeskPage;
