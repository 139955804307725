import Grid from "@mui/system/Unstable_Grid/Grid";
import { Box } from "@mui/system";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import React from "react";
import Rewards from "../../components/Rewards/Rewards"
import Typography from '@mui/material/Typography';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  borderRadius: "25px",
  maxHeight: "100%"
}));

const TeamMemberRewardsPage = () => {


  return (
    <div>
      {/* Gray background box */}
      <Box
        sx={{
          flexGrow: 1,
          height: "100%",
        }}
      >
          <Rewards />
 
      </Box>
    </div>
  );
};

export default TeamMemberRewardsPage;
