
import { useState } from "react";
import { useSelector } from "react-redux";

import Box from "@mui/material/Box";
import { Paper, Stack } from "@mui/material";

export default function DashboardUserDataMonitoring() {

  const [show, setShow] = useState(false)
  const  dashboard_users = useSelector( store => store.dashboard_user.dashboard_users) //fetchDashboardUsers
  const  current_dashboard_user = useSelector( store => store.dashboard_user.current_dashboard_user) //fetchCurrentDashboardUser
  const  all_dashboard_users = useSelector( store => store.dashboard_user.all_dashboard_users) //fetchAllDashboardUsers
  const  bike_analytics = useSelector( store => store.dashboard_user.bike_analytics) //fetchBikeAnalytics
  const  desk_analytics = useSelector( store => store.dashboard_user.desk_analytics) //fetchDeskAnalytics

  function handleClick(){
    setShow(!show)
  }

  return (
    <Paper
      sx={{
        padding: 2,
        mt: 1
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>

        <Stack>
        <div>
          <button onClick={handleClick}>
            Show/Hide Dashboard User Fetch Status
          </button>
        </div>

        { show == true &&
          <div>
            <h1>
              Dashboard User Data Monitoring
            </h1>
            { dashboard_users?.length >0  ? <p> fetchDashboardUsers ✅</p> : <p> fetchDashboardUsers ❌</p> }
            { current_dashboard_user.id  ? <p> fetchCurrentDashboardUser ✅</p> : <p> fetchCurrentDashboardUser ❌</p> }
            { all_dashboard_users?.length >0  ? <p> fetchAllDashboardUsers ✅</p> : <p> fetchAllDashboardUsers ❌</p> }
            { bike_analytics?.length >0  ? <p> fetchBikeAnalytics ✅</p> : <p> fetchBikeAnalytics ❌</p> }
            { desk_analytics?.length >0  ? <p> fetchDeskAnalytics ✅</p> : <p> fetchDeskAnalytics ❌</p> }

          </div>
        }
        </Stack>

      </Box>
    </Paper>
  );
}
