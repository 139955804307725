import React, { useContext, useState } from "react";
import {
  MenuItem,
  Button,
  Modal,
  Box,
  Grid,
  TextField,
  Select,
  FormHelperText,
  FormControl,
  Typography,
  Paper,
  FormLabel
} from "@mui/material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "../../styles/colours.scss";
import styles from "./AddEmployeeModal.module.scss";
import AuthContext from "../../context/AuthContext";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useAxios from "../../utils/useAxios";


// modal sx prop styles (copied from EmployeeDetailsModal.js)
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  borderRadius: "10px",
  p: 4,
};

export default function AddEmployeeModal(props) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selectedTeam, setSelectedTeam] = useState();
  const [selectedUserType, setSelectedUserType] = useState();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const teams = useSelector((dashboardStore) => dashboardStore.team.teams);
  const user_type_map = {
    1: "Employee",
    2: "Company Admin",
    3: "Super Admin",
  };

  const { loading, user } = useContext(AuthContext); //user is the actual current user . registerUser is a function to create a new separate user

  const api = useAxios();

  const createDashboardUser = ( firstName, lastName, email, company, team, user_type) => {
    let timestampString = String(Date.now())//for uniqueness, although possibly not needed
    api
      .post(`/dashboard_user/create/`, {
        first_name: firstName,
        last_name: lastName,
        email: email,
        company: company,
        team: team,
        user_type: user_type,
        username: firstName + timestampString //the backend is 'requiring' this to be sent, otherwise hits back w/ a 400 error 🤔
      })
      .then((res) => {
        if (res.status === 201) {
          console.log("User Added Successfully");
        } else {
          console.log("Error Adding User: " + res.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };


  //UPDATED 2023-01-09 - remove hardcoded company value
  const handleSubmit = (event) => {

    event.preventDefault();

    let companyOfTheAdministrator = user.company
        
    createDashboardUser(firstName, lastName, email, companyOfTheAdministrator, selectedTeam, selectedUserType);

    handleClose();

  };

  return (
    <div>
      <Button 
      sx={{
        color: "white",
        backgroundColor: "orange",
        "&:hover": {
          backgroundColor: "#e2830f",
        },
      }}
      size="large" onClick={handleOpen}>
        Add Employee
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              spacing={3}
              alignItems="center"
              justifyContent="center"
            >
                <Grid item xs={12} 
                    display="flex"
                >
                    <ArrowBackIcon 
                    onClick={handleClose} 
                    sx={{
                        cursor: "pointer",
                        fontSize: "3rem",
                        marginRight: "1rem",

                    }}
                    />
                    <Typography variant="h3">Add Employee</Typography>
                </Grid>
              <Grid item xs={6}>
              <FormLabel>First Name</FormLabel>

                <TextField
                fullWidth
                  required
                  id="outlined-required"
                  label="required"
                  value={firstName}
                  onChange={(event) => setFirstName(event.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
              <FormLabel>Last Name</FormLabel>

                <TextField
                fullWidth
                  required
                  id="outlined-required"
                  label="required"
                  value={lastName}
                  onChange={(event) => setLastName(event.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
              <FormLabel>Email</FormLabel>

                <TextField
                  required
                  id="outlined-required"
                  label="required"
                  fullWidth
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <FormLabel>Team</FormLabel>
                <Select
                  required
                  value={selectedTeam}
                  label="Team"
                  fullWidth
                  onChange={(event) => setSelectedTeam(event.target.value)}
                >
                  {/* map teams into mui MenuItems */}
                  {Object.keys(teams).map((team) => (
                    <MenuItem value={team}>{teams[team]}</MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={12}>
              <FormLabel>User Type</FormLabel>

                <Select
                  required
                  value={selectedUserType}
                  label="User Type"
                  fullWidth
                  onChange={(event) => setSelectedUserType(event.target.value)}
                >
                  {/* map user types into mui MenuItems */}
                  {Object.keys(user_type_map).map((user_type) => (
                    // if user.user_type is not 3 (super admin), don't show super admin as an option
                    user.user_type !== 3 && user_type === "3" ? null : (
                    <MenuItem value={user_type}>
                      {user_type_map[user_type]}
                    </MenuItem>
                    )))}
                </Select>
              </Grid>

              <Grid item xs={12}>
                <Paper 
                    elevation={3}
                >
                </Paper>
              </Grid>

              <Grid item xs={12} 
                display="flex"
                justifyContent="center"
              >
                <Button type="submit" variant="contained">
                  Add Employee
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
