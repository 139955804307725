// import React from "react";
// import {
//   Paper,
//   Box,
//   CardContent,
//   CardMedia,
//   Typography,
//   CardActions,
// } from "@mui/material";
// import getting_started_0 from "../../assets/images/completed_challenge_badges/0_getting_started/0.png";
// import getting_started_1 from "../../assets/images/completed_challenge_badges/0_getting_started/1.png";
// import getting_started_2 from "../../assets/images/completed_challenge_badges/0_getting_started/2.png";
// import getting_started_3 from "../../assets/images/completed_challenge_badges/0_getting_started/3.png";

// import get_connected_0 from "../../assets/images/completed_challenge_badges/1_get_connected/0.png";
// import get_connected_1 from "../../assets/images/completed_challenge_badges/1_get_connected/1.png";
// import get_connected_2 from "../../assets/images/completed_challenge_badges/1_get_connected/2.png";
// import get_connected_3 from "../../assets/images/completed_challenge_badges/1_get_connected/3.png";
// import get_connected_4 from "../../assets/images/completed_challenge_badges/1_get_connected/4.png";
// import get_connected_5 from "../../assets/images/completed_challenge_badges/1_get_connected/5.png";

// import keep_moving_0 from "../../assets/images/completed_challenge_badges/2_keep_moving/0.png";
// import keep_moving_1 from "../../assets/images/completed_challenge_badges/2_keep_moving/1.png";
// import keep_moving_2 from "../../assets/images/completed_challenge_badges/2_keep_moving/2.png";
// import keep_moving_3 from "../../assets/images/completed_challenge_badges/2_keep_moving/3.png";
// import keep_moving_4 from "../../assets/images/completed_challenge_badges/2_keep_moving/4.png";
// import keep_moving_5 from "../../assets/images/completed_challenge_badges/2_keep_moving/5.png";

// import burn_it_off_0 from "../../assets/images/completed_challenge_badges/3_burn_it_off/0.png";
// import burn_it_off_1 from "../../assets/images/completed_challenge_badges/3_burn_it_off/1.png";
// import burn_it_off_2 from "../../assets/images/completed_challenge_badges/3_burn_it_off/2.png";
// import burn_it_off_3 from "../../assets/images/completed_challenge_badges/3_burn_it_off/3.png";
// import burn_it_off_4 from "../../assets/images/completed_challenge_badges/3_burn_it_off/4.png";
// import burn_it_off_5 from "../../assets/images/completed_challenge_badges/3_burn_it_off/5.png";

// import new_heights_0 from "../../assets/images/completed_challenge_badges/4_new_heights/0.png";
// import new_heights_1 from "../../assets/images/completed_challenge_badges/4_new_heights/1.png";
// import new_heights_2 from "../../assets/images/completed_challenge_badges/4_new_heights/2.png";
// import new_heights_3 from "../../assets/images/completed_challenge_badges/4_new_heights/3.png";
// import new_heights_4 from "../../assets/images/completed_challenge_badges/4_new_heights/4.png";
// import new_heights_5 from "../../assets/images/completed_challenge_badges/4_new_heights/5.png";

// import record_setter_0 from "../../assets/images/completed_challenge_badges/5_record_setter/0.png";
// import record_setter_1 from "../../assets/images/completed_challenge_badges/5_record_setter/1.png";
// import record_setter_2 from "../../assets/images/completed_challenge_badges/5_record_setter/2.png";
// import record_setter_3 from "../../assets/images/completed_challenge_badges/5_record_setter/3.png";
// import record_setter_4 from "../../assets/images/completed_challenge_badges/5_record_setter/4.png";
// import record_setter_5 from "../../assets/images/completed_challenge_badges/5_record_setter/5.png";
// import ThemeContext from "../../context/ThemeContext";

// const badge_image_list = [
//   [getting_started_0, getting_started_1, getting_started_2, getting_started_3],
//   [
//     get_connected_0,
//     get_connected_1,
//     get_connected_2,
//     get_connected_3,
//     get_connected_4,
//     get_connected_5,
//   ],
//   [
//     keep_moving_0,
//     keep_moving_1,
//     keep_moving_2,
//     keep_moving_3,
//     keep_moving_4,
//     keep_moving_5,
//   ],
//   [
//     burn_it_off_0,
//     burn_it_off_1,
//     burn_it_off_2,
//     burn_it_off_3,
//     burn_it_off_4,
//     burn_it_off_5,
//   ],
//   [
//     new_heights_0,
//     new_heights_1,
//     new_heights_2,
//     new_heights_3,
//     new_heights_4,
//     new_heights_5,
//   ],
//   [
//     record_setter_0,
//     record_setter_1,
//     record_setter_2,
//     record_setter_3,
//     record_setter_4,
//     record_setter_5,
//   ],
// ];
// export default function RewardCard({ completed_challenge }) {
//   const {theme} = React.useContext(ThemeContext)
//   return (
//     <>
//       <Paper
//         elevation={3}
//         sx={{
//           "&:hover": {
//             backgroundColor: theme === 'dark' ? "#616161" : "#E0E0DF",
//           },
//         }}
//       >
//         <Box
//           sx={{
//             display: "flex",
//             flexDirection: "column",
//             margin: 2,
//             padding: 1,
//           }}
//         >
//           <Box
//             sx={{
//               display: "flex",
//               flexDirection: "row",
//               justifyContent: "space-between",
//               alignItems: "center",
//             }}
//           >
//             <img
//               src={
//                 badge_image_list[completed_challenge.challenge_group][
//                   completed_challenge.task_id
//                 ]
//               }
//               height={30}
//               width={30}
//               alt="badge image"
//             />
//             <Box p={1} />
//             <Box sx={{ display: "flex", flexDirection: "column" }}>
//               <Typography variant="subtitle" fontWeight={"bold"}>
//                 {completed_challenge.task_name}
//               </Typography>
//               <Typography variant="subtitle2">
//                 {completed_challenge.task}
//               </Typography>
//             </Box>
//           </Box>
//         </Box>
//       </Paper>
//     </>
//   );
// }

import * as React from "react";
import Box from "@mui/material/Box";
import { Paper } from "@mui/material";
import Typography from "@mui/material/Typography";

import LinearProgress from "@mui/material/LinearProgress"; /* NEW */
import getting_started_0 from "../../assets/images/completed_challenge_badges/0_getting_started/0.png";
import getting_started_1 from "../../assets/images/completed_challenge_badges/0_getting_started/1.png";
import getting_started_2 from "../../assets/images/completed_challenge_badges/0_getting_started/2.png";
import getting_started_3 from "../../assets/images/completed_challenge_badges/0_getting_started/3.png";

import get_connected_0 from "../../assets/images/completed_challenge_badges/1_get_connected/0.png";
import get_connected_1 from "../../assets/images/completed_challenge_badges/1_get_connected/1.png";
import get_connected_2 from "../../assets/images/completed_challenge_badges/1_get_connected/2.png";
import get_connected_3 from "../../assets/images/completed_challenge_badges/1_get_connected/3.png";
import get_connected_4 from "../../assets/images/completed_challenge_badges/1_get_connected/4.png";
import get_connected_5 from "../../assets/images/completed_challenge_badges/1_get_connected/5.png";

import keep_moving_0 from "../../assets/images/completed_challenge_badges/2_keep_moving/0.png";
import keep_moving_1 from "../../assets/images/completed_challenge_badges/2_keep_moving/1.png";
import keep_moving_2 from "../../assets/images/completed_challenge_badges/2_keep_moving/2.png";
import keep_moving_3 from "../../assets/images/completed_challenge_badges/2_keep_moving/3.png";
import keep_moving_4 from "../../assets/images/completed_challenge_badges/2_keep_moving/4.png";
import keep_moving_5 from "../../assets/images/completed_challenge_badges/2_keep_moving/5.png";

import burn_it_off_0 from "../../assets/images/completed_challenge_badges/3_burn_it_off/0.png";
import burn_it_off_1 from "../../assets/images/completed_challenge_badges/3_burn_it_off/1.png";
import burn_it_off_2 from "../../assets/images/completed_challenge_badges/3_burn_it_off/2.png";
import burn_it_off_3 from "../../assets/images/completed_challenge_badges/3_burn_it_off/3.png";
import burn_it_off_4 from "../../assets/images/completed_challenge_badges/3_burn_it_off/4.png";
import burn_it_off_5 from "../../assets/images/completed_challenge_badges/3_burn_it_off/5.png";

import new_heights_0 from "../../assets/images/completed_challenge_badges/4_new_heights/0.png";
import new_heights_1 from "../../assets/images/completed_challenge_badges/4_new_heights/1.png";
import new_heights_2 from "../../assets/images/completed_challenge_badges/4_new_heights/2.png";
import new_heights_3 from "../../assets/images/completed_challenge_badges/4_new_heights/3.png";
import new_heights_4 from "../../assets/images/completed_challenge_badges/4_new_heights/4.png";
import new_heights_5 from "../../assets/images/completed_challenge_badges/4_new_heights/5.png";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import record_setter_0 from "../../assets/images/completed_challenge_badges/5_record_setter/0.png";
import record_setter_1 from "../../assets/images/completed_challenge_badges/5_record_setter/1.png";
import record_setter_2 from "../../assets/images/completed_challenge_badges/5_record_setter/2.png";
import record_setter_3 from "../../assets/images/completed_challenge_badges/5_record_setter/3.png";
import record_setter_4 from "../../assets/images/completed_challenge_badges/5_record_setter/4.png";
import record_setter_5 from "../../assets/images/completed_challenge_badges/5_record_setter/5.png";
import ThemeContext from "../../context/ThemeContext";

const badge_image_list = [
  [getting_started_0, getting_started_1, getting_started_2, getting_started_3],
  [
    get_connected_0,
    get_connected_1,
    get_connected_2,
    get_connected_3,
    get_connected_4,
    get_connected_5,
  ],
  [
    keep_moving_0,
    keep_moving_1,
    keep_moving_2,
    keep_moving_3,
    keep_moving_4,
    keep_moving_5,
  ],
  [
    burn_it_off_0,
    burn_it_off_1,
    burn_it_off_2,
    burn_it_off_3,
    burn_it_off_4,
    burn_it_off_5,
  ],
  [
    new_heights_0,
    new_heights_1,
    new_heights_2,
    new_heights_3,
    new_heights_4,
    new_heights_5,
  ],
  [
    record_setter_0,
    record_setter_1,
    record_setter_2,
    record_setter_3,
    record_setter_4,
    record_setter_5,
  ],
];

export default function RewardCard({ challenge }) {
  const { theme } = React.useContext(ThemeContext);

  return (
    <Paper
      sx={{
        padding: 2,
        mt: 1,
        "&:hover": {
          backgroundColor: theme === "dark" ? "#616161" : "#E0E0DF",
        },
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {/* image */}
        <img
          src={badge_image_list[challenge.challenge_group][challenge.task_id]}
          alt="badge"
          style={{ width: "40px", height: "40px" }}
        />
        {/* title */}
        <Box sx={{ ml: 2 }}>
          <Typography variant="h5" component="div">
            {challenge.task_name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {challenge.task}
          </Typography>
        </Box>
        <Box sx={{ml: 4}}>
          <CheckCircleIcon />
        </Box>
      </Box>
      {/* NEW ! */}
    </Paper>
  );
}
