import Grid from "@mui/system/Unstable_Grid/Grid";
import { Box, Button, Paper, Snackbar } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import ChallengeGroupCard from "../../components/ChallengeGroupCard/ChallengeGroupCard";
import { useSelector, useDispatch } from "react-redux";
import { fetchChallengeData } from "../../components/Reducers/challengeReducer";
import { fetchCompanySettings, updateCompanyChallengeGroups } from "../../components/Reducers/companyReducer";
import AuthContext from "../../context/AuthContext";
import header0 from "../../assets/images/png_headers/header0.png";
import header1 from "../../assets/images/png_headers/header1.png";
import header2 from "../../assets/images/png_headers/header2.png";
import header3 from "../../assets/images/png_headers/header3.png";
import header4 from "../../assets/images/png_headers/header4.png";
import header5 from "../../assets/images/png_headers/header5.png";
import useAxios from "../../utils/useAxios";
const challengeGroupImgMap = {
  0: header0,
  1: header1,
  2: header2,
  3: header3,
  4: header4,
  5: header5,
};

const CompanyAdminChallengesPage = () => {
  const dispatch = useDispatch();
  const api = useAxios();
  const { user } = useContext(AuthContext);
  const pendingChanges = useSelector((state) => state.company.pendingChanges);
  const [challengeGroups, setChallengeGroups] = useState([]);

  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleSnackbarOpen = () => setSnackbarOpen(true);
  const handleSnackbarClose = () => setSnackbarOpen(false);
  const tempChallenges = useSelector((state) => state.company.tempChallenges);
  const setPendingChanges = (value) => {
    dispatch({ type: "company/setPendingChanges", payload: value });
  };
  const resetTempChallenges = () => {
    dispatch({ type: "company/resetTempChallenges" });
  };

  const handleSave = () => {
    dispatch(updateCompanyChallengeGroups([api, user.company, tempChallenges]));
    setPendingChanges(false);
    setSnackbarMessage("Changes saved!");
    handleSnackbarOpen();
  };
  const discardChanges = () => {
    console.log("Discard Changes");
    resetTempChallenges();
    setPendingChanges(false);
  };

  useEffect(() => {
    dispatch(fetchCompanySettings([api, user.company]));
    dispatch(fetchChallengeData(api));
  }, []);

  const challenges = useSelector((state) => state.challenge.challenges);
  
  // when challenges are loaded, format them into challenge groups and set state
  useEffect(() => {
    challenges &&
      challenges.length > 0 &&
      setChallengeGroups(
        challenges.reduce((acc, challenge) => {
          const { challenge_group, challenge_name } = challenge;
          if (acc[challenge_group]) {
            acc[challenge_group].challenges.push(challenge);
          } else {
            acc[challenge_group] = {
              challenge_group: challenge_group,
              challenges: [challenge],
              challenge_name: challenge_name,
            };
          }
          return acc;
        }, {})
      );
  }, [challenges]);

  // when company settings are loaded, set the toggles on the challenge groups

  return (

    <div>
      <Box
        sx={{
          flexGrow: 1,
          height: "100%",
        }}
      >
        {
          snackbarOpen && (
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={6000}
              onClose={handleSnackbarClose}
              message={snackbarMessage}
            />
          )
        }
        <Grid
          container
          rowSpacing={2}
          columnSpacing={2}
          alignItems="stretch"
          sx={{ mt: 1, ml: 1, mr: 1, margin: 0 }}
        >
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center"
            }}
          >
            {pendingChanges && (
              <Paper
                sx={{
                  p:2,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {/* Save button */}
                <Button
                  variant="contained"
                  onClick={handleSave}
                  sx={{
                    color: "white",
                    backgroundColor: "orange",
                    "&:hover": {
                      backgroundColor: "#e2830f",
                    },
                    marginX:2
                  }}
                >
                  Save Changes
                </Button>
                {/* Discard changes */}
                <Button
                  variant="contained"
                  onClick={discardChanges}
                  sx={{
                    color: "white",
                    backgroundColor: "orange",
                    "&:hover": {
                      backgroundColor: "#e2830f",
                    },
                  }}
                >
                  Discard Changes
                </Button>
              </Paper>
            )}
          </Grid>
          {/* map each challenge group to a ChallengeGroupCard */}
          {Object.values(challengeGroups).map((challengeGroup) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={challengeGroup.challenge_group}
              height="100%"
            >
              <ChallengeGroupCard
                challengeGroup={challengeGroup}
                img={challengeGroupImgMap[challengeGroup.challenge_group]}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
};

export default CompanyAdminChallengesPage;
