import Grid from "@mui/system/Unstable_Grid/Grid";
import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import ChallengeGroup from "../../components/Challenges/ChallengeGroup";
import { useSelector, useDispatch } from "react-redux";
import { useAtom } from "jotai";
import { activeChallengeGroupsAtom, pendingChangesAtom } from "../../appStore";
import {
  fetchChallengeData,
  fetchChallengePercentages,
} from "../../components/Reducers/challengeReducer";
import { fetchCompanySettings } from "../../components/Reducers/companyReducer";
import AuthContext from "../../context/AuthContext";
import header0 from "../../assets/images/png_headers/header0.png";
import header1 from "../../assets/images/png_headers/header1.png";
import header2 from "../../assets/images/png_headers/header2.png";
import header3 from "../../assets/images/png_headers/header3.png";
import header4 from "../../assets/images/png_headers/header4.png";
import header5 from "../../assets/images/png_headers/header5.png";
import useAxios from "../../utils/useAxios";

const TeamMemberChallengesPage = () => {
  const challengeGroupImgMap = {
    0: header0,
    1: header1,
    2: header2,
    3: header3,
    4: header4,
    5: header5,
  };

  const api = useAxios();
  const { user, loading } = useContext(AuthContext);

  const companySettings_Challenges = useSelector(
    (dashboardStore) => dashboardStore.company.companySettings.challenges
  );

  const challenges = useSelector(
    (dashboardStore) => dashboardStore.challenge.challenges
  );

  const [challengeGroups, setChallengeGroups] = useState([]);

    useState([]);

  const taskPercentages = useSelector(
    (state) => state.challenge.challenge_task_percentage_amounts
  );

  const dispatch = useDispatch();

  // This useEffect is for data fetching
  useEffect(() => {
    if (user) {
      if (challenges.length === 0) {
        dispatch(fetchChallengeData(api));
      }
      if (taskPercentages.length === 0) {
        dispatch(fetchChallengePercentages([api, user.user_id])); //need to pass in the AxiosInstance !
      }
    }
  }, [user]);
 
  // Here we use Jotai's useAtom
  // this is like using useState, but it allows us to share state across components
  // Atoms are like global variables, but they are only updated when the component that uses them is updated
  // Atoms are stored in the appStore.js file
  const [activeChallengeGroups, setActiveChallengeGroups] = useAtom(
    activeChallengeGroupsAtom
  );

  useEffect(() => {
    if (!loading && user) {
      dispatch(fetchChallengeData(api));
      // we dont need completed challenged for the admin page
      dispatch(fetchCompanySettings([api, user.company]));
    }
  }, [user]);

  // When we have our challenges, set the challenge groups
  useEffect(() => {
    challenges &&
      challenges.length > 0 &&
      setChallengeGroups(
        challenges.reduce((acc, challenge) => {
          const { challenge_group, challenge_name } = challenge;
          if (acc[challenge_group]) {
            acc[challenge_group].challenges.push(challenge);
          } else {
            acc[challenge_group] = {
              challenge_group: challenge_group,
              challenges: [challenge],
              challenge_name: challenge_name,
            };
          }
          return acc;
        }, {})
      );
  }, [challenges]);

  // Whenever companySettings_Challenges loads, update the activeChallengeGroups
  // This will have to be changed to add support for custom challenges
  // Right now we have 6 challenge groups
  useEffect(() => {
    companySettings_Challenges &&
      companySettings_Challenges.enabled_challenge_groups &&
      companySettings_Challenges.enabled_challenge_groups.length > 0 &&
      setActiveChallengeGroups({
        0: companySettings_Challenges.enabled_challenge_groups.includes(0),
        1: companySettings_Challenges.enabled_challenge_groups.includes(1),
        2: companySettings_Challenges.enabled_challenge_groups.includes(2),
        3: companySettings_Challenges.enabled_challenge_groups.includes(3),
        4: companySettings_Challenges.enabled_challenge_groups.includes(4),
        5: companySettings_Challenges.enabled_challenge_groups.includes(5),
      });
  }, [companySettings_Challenges]);

  return (
    <div>
      <Box
        sx={{
          flexGrow: 1,
          height: "100%",
        }}
      >
        <Grid
          container
          rowSpacing={2}
          columnSpacing={2}
          alignItems="stretch"
          sx={{ mt: 1, ml: 1, mr: 1, margin: 0 }}
        >
          {/* map each challenge group to a ChallengeGroupCard */}
          {Object.values(challengeGroups).map((challengeGroup, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={challengeGroup.challenge_group}
              height="100%"
            >
              <ChallengeGroup
                challengeGroup={challengeGroup}
                img={challengeGroupImgMap[challengeGroup.challenge_group]}
                challenge_task_percentages={taskPercentages[index]}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
};

export default TeamMemberChallengesPage;
