import EmployeeList from "../components/EmployeeList/EmployeeList";
import Appbar from "../components/Appbar/Appbar";
import styles from "../styles/home.scss";
import { Box } from "@mui/system";
import Grid from "@mui/system/Unstable_Grid/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { useEffect, useState, useContext } from "react";
import AuthContext from "../context/AuthContext";
import useAxios from "../utils/useAxios";

import { store as dashboardStore } from "../components/dashboardStore";

import { fetchDashboardUsers } from "../components/Reducers/dashboardUserReducer";
import { fetchBikeDevices } from "../components/Reducers/deviceReducer";
import { fetchDeskDevices } from "../components/Reducers/deviceReducer";
import { fetchTeamData, fetchTeamRawData } from "../components/Reducers/teamReducer";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

// Styling for the paper
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  borderRadius: "25px",
}));

// Menu items for the Appbar
const menuItems = [
  {
    text: "Users",
    path: "/employees",
    paths: ["/employees"],
  },
  {
    text: "Teams",
    path: "/employees/teams",
    paths: ["/employees/teams"],
  },
];

function Employees() {

  const api = useAxios()

  const dispatch = useDispatch();
  // this is dispatched in the Layout component
  // const userInfo = useSelector(dashboardStore => dashboardStore.dashboard_user.current_dashboard_user);
  const {user, loading} = useContext(AuthContext);
  // we only want to fetch the users if the userInfo is loaded
  useEffect(() => {
    if (!loading) {
      if (user) {
      dispatch(fetchDashboardUsers([user.company, api])); // required - fetches all users for a company
    }}
  }, [api, loading, user]);

  useEffect(() => {
    dispatch(fetchTeamData([api, user.company])); // required - fetches all teams for a company
    dispatch(fetchTeamRawData([user.company, api])); // required for EditEmployeeModal
    // I think these are requiered to for the modal to work
    dispatch(fetchBikeDevices([api, user.company]));
    dispatch(fetchDeskDevices([api, user.company]));
  }, [api, user]);

  return (
    <div >
      {/* <Appbar menuItems={menuItems} page="Employee List" /> */}
      <Box
        sx={{
          flexGrow: 1,
          height: "100%",
          width: "100%",
        }}
      >
        <Grid
          container
          rowSpacing={2}
          columnSpacing={2}
          sx={{ mt: 1, ml: 1, mr: 1 }}
        >
          <Grid item xs={12}>
            <Item>
              <EmployeeList />
            </Item>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default Employees;
