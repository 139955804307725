
import { useState } from "react";
import { useSelector } from "react-redux";

import Box from "@mui/material/Box";
import { Paper, Stack } from "@mui/material";

export default function CompanyDataMonitoring() {

  const [show, setShow] = useState(false)
  const companies = useSelector( store => store.company.companies) //fetchCompanyData
  const adminCompanyList = useSelector( store => store.company.adminCompanyList) //fetchAllCompanies
  const companySettings = useSelector( store => store.company.companySettings) //fetchCompanySettings

  function handleClick(){
    setShow(!show)
  }

  return (
    <Paper
      sx={{
        padding: 2,
        mt: 1
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>

        <Stack>
        <div>
          <button onClick={handleClick}>
            Show/Hide Company Fetch Status
          </button>
        </div>

        { show == true &&
          <div>
            <h1>
              Company Data Monitoring
            </h1>
            { companies.id ? <p>fetchCompanyData ✅</p> : <p>fetchCompanyData ❌</p> }
            { adminCompanyList?.length >0  ? <p>fetchAllCompanies ✅</p> : <p>fetchAllCompanies ❌</p>}
            { companySettings['challenges'] && companySettings['home_widgets']   ? <p>fetchCompanySettings ✅</p> :<p>fetchCompanySettings ❌</p>}

          </div>
        }
        </Stack>

      </Box>
    </Paper>
  );
}
