
import { useState } from "react";
import { useSelector } from "react-redux";

import Box from "@mui/material/Box";
import { Paper, Stack } from "@mui/material";

export default function HourlyBikeUpsertDataMonitoring() {

  const [show, setShow] = useState(false)
  const hourly_bike_upserts  = useSelector( store => store.hourly_bike_upsert.hourly_bike_upserts) //fetchHourlyBikeUpsert

  function handleClick(){
    setShow(!show)
  }

  return (
    <Paper
      sx={{
        padding: 2,
        mt: 1
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>

        <Stack>
        <div>
          <button onClick={handleClick}>
            Show/Hide HourlyBikeUpsert Fetch Status
          </button>
        </div>

        { show == true &&
          <div>
            <h1>
              HourlyBikeUpsert Data Monitoring
            </h1>
            { hourly_bike_upserts?.length >0  ? <p> fetchHourlyBikeUpsert  ✅</p> : <p>  fetchHourlyBikeUpsert ❌</p> }

          </div>
        }
        </Stack>

      </Box>
    </Paper>
  );
}
