import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import useAxios from "../../utils/useAxios"; //ATTEMPTING TO USE AXIOS HERE - #1
// import axios from "axios";

//ATTEMPTING TO USE AXIOS HERE 
export const fetchChallengeData = createAsyncThunk(
  "challenge/fetchChallengeData",
  async (api) => {
    let endpoint = `/challenge/`
    const response = await api.get(endpoint);
    const data = response.data    
    return data;
  }
);

//ATTEMPTING TO USE AXIOS HERE 
export const fetchCompletedChallengeData = createAsyncThunk(
    "challenge/fetchCompletedChallengeData",
    async (api) => {
      let endpoint = `/completed_challenge/`
      const response = await api.get(endpoint);
      const data = response.data    
      return data;
    }
  );

  //ATTEMPTING TO USE AXIOS HERE 
  export const fetchChallengePercentages = createAsyncThunk(
    "challenge/fetchChallengePercentages",
    async ( [api, dashboard_user_id] ) => {
      let endpoint = `/completed_challenge/fetch_challenge_percentages/${dashboard_user_id}`
      const response = await api.get(endpoint);
      const data = response.data      
      return data['percentages'];
    }
  );


const challengeDataSlice = createSlice({
  name: 'challenge',
  initialState: {challenges:[], completed_challenges:[], challenge_task_percentage_amounts:[]},
  reducers: {
    //this is not in use
    challengeDataFetched(state, action) {
      state.push({
        id: action.payload.id,
        challenge_name : action.payload.challenge_name,
      })
    },
  },
    extraReducers(builder) {
      builder
        .addCase(fetchChallengeData.pending, (state, action) => {
        })
        .addCase(fetchChallengeData.fulfilled, (state, action) => {
          state.challenges = action.payload;
        })
        .addCase(fetchChallengeData.rejected, (state, action) => {
          console.log("error in extra reducers (fetchChallengeData users)");
        })
        .addCase(fetchCompletedChallengeData.pending, (state, action) => {
        })
        .addCase(fetchCompletedChallengeData.fulfilled, (state, action) => {
            state.completed_challenges = action.payload;
        })
        .addCase(fetchCompletedChallengeData.rejected, (state, action) => {
            console.log("error in extra reducers (fetchCompletedChallengeData users)");
        })
        .addCase(fetchChallengePercentages.pending, (state, action) => {
          // console.log("loading fetchChallengePercentages");
        })
        .addCase(fetchChallengePercentages.fulfilled, (state, action) => {
            // console.log("fetchChallengePercentages fulfilled");
            // console.log("fetchChallengePercentages action payload : ", action.payload);
            state.challenge_task_percentage_amounts = action.payload;
        })
        .addCase(fetchChallengePercentages.rejected, (state, action) => {
            console.log("error in extra reducers (fetchChallengePercentages)");
        })
    },
  
});

export const { challengeDataFetched } = challengeDataSlice.actions;
export default challengeDataSlice.reducer;
