import { useSelector } from "react-redux";
import { Box, Stack, Typography } from "@mui/material";

export default function TeamMemberInfo({ current_user }) {
  const dashboard_user_info = useSelector(
    (store) => store.dashboard_user.current_dashboard_user
  );
  const team_info = useSelector((store) => store.team.teams); // { 'Team1', 'Team2', ... }
  const company_info = useSelector((store) => store.company.companies); // { 'Team1', 'Team2', ... }
  console.log(dashboard_user_info);
  const company_bikes = useSelector((store) => store.device.bikes);
  const company_desks = useSelector((store) => store.device.desks);
  const company_dashboard_users = useSelector(
    (store) => store.dashboard_user.dashboard_users
  );

  return (
    <div>
      {/* Typography */}
      <Box
        sx={{
          backgroundColor: "white",
          width: "100%",
        }}
        maxWidth={500}
        m={3}
        p={2}
        borderRadius={2}
      >
        <h1>
          {dashboard_user_info.first_name} {dashboard_user_info.last_name}
        </h1>
        <Stack direction="column" spacing={0.5} marginY={3}>
          <Typography variant="overline">Info</Typography>
          <Typography>{dashboard_user_info.email}</Typography>
          <Typography>{company_info.phone}</Typography>
          <Typography>{company_info.address_1}</Typography>
        </Stack>
        <Stack direction="column" spacing={0.5}>
          <Typography variant="overline">Devices Assigned</Typography>
          {company_desks.length > 0 &&
            company_desks.map((desk) => {
              if (desk.dashboard_user === dashboard_user_info.id) {
                return <Typography>{desk.device_name}</Typography>;
              }
            })}
          {company_bikes.length > 0 &&
            company_bikes.map((bike) => {
              if (bike.dashboard_user === dashboard_user_info.id) {
                return <Typography>{bike.device_name}</Typography>;
              }
            })}
        </Stack>
        <Stack direction="column" spacing={0.5} marginY={3}>
          <Typography variant="overline">Company</Typography>
          <Typography>{company_info.company_name}</Typography>
        </Stack>
        <Stack direction="column" spacing={0.5} marginY={3}>
          <Typography variant="overline">Team</Typography>
          <Typography>{team_info[dashboard_user_info.team]}</Typography>
          </Stack>
          <Stack direction="column" spacing={0.5} marginY={3}>
          <Typography variant="overline">Team Members</Typography>
          {
            company_dashboard_users.length > 0 &&
            company_dashboard_users.map((user) => {
              if (user.team === dashboard_user_info.team) {
                if (user.user_type > 1) {
                  return <Typography>**{user.first_name}</Typography>;
                }
                return <Typography>{user.first_name}</Typography>;
              }
            }
            )
          }
        </Stack>
        {/* <Typography> {company_info.company_name} </Typography> */}
        {/* <Typography>{ team_info[dashboard_user_info.team] }</Typography> */}
      </Box>

      {/* <p>
        <em>
          {JSON.stringify(company_desks)}
          {JSON.stringify(company_bikes)}
          {JSON.stringify(company_dashboard_users)}
        </em>
      </p>

      <p>
        <em>
          {JSON.stringify(team_info)}
          {JSON.stringify(dashboard_user_info)}
          {JSON.stringify(current_user)}
        </em> */}
      {/* </p>

      <p>
        <em>
          {JSON.stringify(team_info)}
          {JSON.stringify(dashboard_user_info)}
          {JSON.stringify(current_user)}
        </em>
      </p> */}
    </div>
  );
}

/*
{"id":19,
"last_login":null,
"is_superuser":true,
"first_name":"Hector",
"last_name":"Regular",
"is_staff":true,
"is_active":true,
"date_joined":"2023-01-25T22:35:07.371862Z",
"email":"hectorandres.pv@gmail.com",
"user_type":1,
"status":"active",
"privacy":2,
"theme":"light",
"company":1,
"team":1,
"groups":[],
"user_permissions":[],
"marketing_communications_enabled":true}
 */
