import BikeData from "../components/HomeScreenViews/BikeData/BikeData";
import DeskData from "../components/HomeScreenViews/DeskData/DeskData";
import LeaderboardData from "../components/HomeScreenViews/LeaderboardData/LeaderboardData";
import StreakData from "../components/HomeScreenViews/StreakData/StreakData"
import Grid from "@mui/system/Unstable_Grid/Grid";
import { Box } from "@mui/system";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { fetchUserLeaderboard } from "../components/Reducers/leaderboardReducer";
import { fetchTeamLeaderboard } from "../components/Reducers/leaderboardReducer";
import { useDispatch, useSelector } from "react-redux";
import { fetchBikeAnalytics, fetchDeskAnalytics } from "../components/Reducers/dashboardUserReducer";
import { fetchStreakData } from "../components/Reducers/streakReducer"; //new
import AuthContext from "../context/AuthContext";
import React, { useContext, useEffect, useState } from "react";
import CompareTeams from "../components/CompareTeams/CompareTeams";
import useAxios from '../utils/useAxios'
import { fetchMonthlyTeamActivity } from "../components/Reducers/teamReducer";
import { fetchHourlyBikeUpsert } from "../components/Reducers/hourlyBikeUpsertReducer";
import { fetchHourlyDeskUpsert } from "../components/Reducers/hourlyDeskUpsertReducer";
import { fetchCompanySettings } from "../components/Reducers/companyReducer";


const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  borderRadius: "20px",
  maxHeight: "100%",
}));

const Home = () => {

  const api = useAxios()

  const dispatch = useDispatch();
  const { user, loading, authTokens } = useContext(AuthContext);

  const companySettings_HomeWidgets = useSelector( dashboardStore => dashboardStore.company.companySettings.home_widgets )

  console.log("company settings home widgets: ", companySettings_HomeWidgets)

  //BASED ON THE DATA ABOVE, SHOW OR HIDE THE COMPONENTS IN THIS PAGE . 

  //initialize
  let [activeWidgets, setActiveWidgets] = useState({})

  useEffect(() => {
    if (!loading && user && authTokens) {
      dispatch(fetchBikeAnalytics(api));
      dispatch(fetchDeskAnalytics(api));
      dispatch(fetchUserLeaderboard([user.company, api]));
      dispatch(fetchMonthlyTeamActivity(api)) //new endpoint made by Bryce
      dispatch(fetchHourlyBikeUpsert([user.company, api]))
      dispatch(fetchHourlyDeskUpsert([user.company, api]))
      dispatch(fetchStreakData([user.user_id, api]))//not sure if I need to pass the user.id but I decided to ...
      //to select :
      // streaks = useSelector(dashboardStore => dashboardStore.streak.streaks )
      dispatch(fetchCompanySettings([api,user.company]));
    }
  }, [user]);

  //separate efffect that depends on the companySettings_HomeWidgets
  useEffect(() => {
    setActiveWidgets(
      {
        0:companySettings_HomeWidgets.includes(0), // BIKE DATA (BikeData)
        1:companySettings_HomeWidgets.includes(1), // DESK DATA (DeskData)
        2:companySettings_HomeWidgets.includes(2), // EMPLOYEE LEADERBOARD (LeaderboardData)
        3:companySettings_HomeWidgets.includes(3),  // TEAM LEADERBOARD (CompareTeams)
        4:companySettings_HomeWidgets.includes(4)  // TEAM LEADERBOARD (CompareTeams)
      }
    )
  }, [companySettings_HomeWidgets]);

  return (
    <div>
      <Box
        sx={{
          flexGrow: 1,
          height: "100%",
        }}
      >
        {/* Parent Grid */}
        <Grid
          container
          rowSpacing={2}
          columnSpacing={1}
          alignItems="stretch"
          sx={{ mt: 4, ml: 1, mr: 1, margin: 0}}
        >
          {activeWidgets[0] && <Grid item xs={12} sm={12} md={6}>
            <Item>
              <BikeData />
            </Item>
          </Grid>}
          {activeWidgets[1] && <Grid item display="flex" xs={12} sm={12} md={6}>
            <Item>
              <DeskData />
            </Item>
          </Grid>}
          {activeWidgets[2] && <Grid item display="flex" xs={12} sm={12} md={4}>
            <Item>
              <LeaderboardData />
            </Item>
          </Grid>}
          {activeWidgets[3] && <Grid item display="flex" xs={12} sm={12} md={8} >
            <Item>
              <CompareTeams />
            </Item>
          </Grid>}
          {activeWidgets[4] && <Grid item display="flex" xs={12} sm={12} md={4} >
            <Item>
              <StreakData />
            </Item>
          </Grid>}
        </Grid>
      </Box>
    </div>
  );
};

export default Home;
