import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/system/Unstable_Grid/Grid";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { Typography } from "@mui/material";

export default function LeaderboardData(props) {
  // Get team and employee data from the store

  const leaderboard = useSelector(
    (dashboardStore) => dashboardStore.leaderboard.users
  );

  let rows = [];

  for (let i = 0; i < leaderboard.length; i++) {
    rows.push({
      ...leaderboard[i],
      // rank: i + 1,
      name: leaderboard[i].name,
      team_name: leaderboard[i].team,
      monthly_calories: leaderboard[i].total_calories,
    });
  }

  for (let i = 0; i < rows.length + 1; i++) {
    for (let j = i + 1; j < rows.length; j++) {
      if (rows[i].monthly_calories < rows[j].monthly_calories) {
        let temp = rows[i];
        rows[i] = rows[j];
        rows[j] = temp;
      }
    }
  }


  return (
    <Grid container spacing={2}>
      {/* Title and date */}
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          alignItems: "center",
          alignContent: "left",
        }}
      >
        <Box>
          <Typography variant="h3">Leaderboard</Typography>
        </Box>
      </Grid>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Rank</TableCell>
              <TableCell >Name</TableCell>
              <TableCell >Team</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.slice(0, 3).map((employee, index) => (
              <TableRow
                key={index}
              >
                <TableCell component="th">
                  {index === 0 && <EmojiEventsIcon sx={{ color: "gold" }} />}
                  {index === 1 && <EmojiEventsIcon sx={{ color: "silver" }} />}
                  {index === 2 && <EmojiEventsIcon sx={{ color: "#cd7f32" }} />}
                </TableCell>
                <TableCell>{employee.name}</TableCell>
                <TableCell>{employee.team}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Footer / view data button */}
      <Grid item xs={12} sx={{ textAlign: "right", justifyContent: "center", bottom: '0px' }}>
        <Button
          size="medium"
          component={Link}
          to="/home/leaderboard"
          sx={{
            color: "white",
            backgroundColor: "orange",
            "&:hover": {
              backgroundColor: "#e2830f"
            }
          }}
        >
          View Leaderboard
        </Button>
      </Grid>
    </Grid>
  );
}
