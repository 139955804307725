import styles from "../styles/home.scss";
import Grid from "@mui/system/Unstable_Grid/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { format } from "date-fns";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ErgoAreaChart from "../components/ErgoAreaChart/ErgoAreaChart";
import BikeDataTable from "../components/BikeDataTable/BikeDataTable";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useContext, useState } from "react";
import AuthContext from "../context/AuthContext";
import { fetchBikeAnalytics } from "../components/Reducers/dashboardUserReducer";
import { fetchHourlyBikeUpsert } from "../components/Reducers/hourlyBikeUpsertReducer";
import useAxios from "../utils/useAxios";
import ThemeContext from "../context/ThemeContext"; //this may be required since the button is not showing properly when the theme gets toggled

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "center",
  borderRadius: "25px",
}));

const HomeBikePage = () => {
  const { theme, toggleTheme } = useContext(ThemeContext); //this is importing the theme , in order to change the button color depending on current theme

  const api = useAxios();

  const userBikeAnalytics = useSelector(
    (state) => state.dashboard_user.bike_analytics
  );

  const [totalCaloriesBurned, setTotalCaloriesBurned] = useState(0);
  const [mostCaloriesBurned, setMostCaloriesBurned] = useState(0);
  const [averageCaloriesBurned, setAverageCaloriesBurned] = useState(0);
  const [totalDistanceBiked, setTotalDistanceBiked] = useState(0);
  const [mostDistanceBiked, setMostDistanceBiked] = useState(0);
  const [averageDistanceBiked, setAverageDistanceBiked] = useState(0);
  const [totalTimeBiked, setTotalTimeBiked] = useState(0);
  const [mostTimeBiked, setMostTimeBiked] = useState(0);
  const [averageTimeBiked, setAverageTimeBiked] = useState(0);
  const [totalPower, setTotalPower] = useState(0);
  const [mostPower, setMostPower] = useState(0);
  const [averagePower, setAveragePower] = useState(0);

  async function setValues(data) {
    // CALORIES
    const totalCaloriesBurned = data.reduce((acc, curr) => {
      return acc + curr.caloriesBurned;
    }, 0);
    setTotalCaloriesBurned(totalCaloriesBurned);
    // find Total calories Burned
    setAverageCaloriesBurned(totalCaloriesBurned / 14);
    // find the average calories burned per day
    const mostCaloriesBurned = data.reduce((acc, curr) => {
      if (acc.caloriesBurned > curr.caloriesBurned) {
        return acc;
      } else {
        return curr;
      }
    }, 0);
    setMostCaloriesBurned(mostCaloriesBurned);
    // find day most calories burned

    // DISTANCE
    const totalDistanceBiked = data.reduce((acc, curr) => {
      return acc + curr.distanceBiked;
    }, 0);
    setTotalDistanceBiked(totalDistanceBiked);
    // find Distance Biked
    setAverageDistanceBiked(totalDistanceBiked / 14);
    // find Distance Distance Biked
    const mostDistanceBiked = data.reduce((acc, curr) => {
      if (acc.distanceBiked > curr.distanceBiked) {
        return acc;
      } else {
        return curr;
      }
    }, 0);
    setMostDistanceBiked(mostDistanceBiked);
    // find Most Distance Biked

    // TIME
    const totalTimeBiked = data.reduce((acc, curr) => {
      return acc + curr.timeBiked;
    }, 0);
    setTotalTimeBiked(totalTimeBiked);
    // find Total Time Biked
    setAverageTimeBiked(totalTimeBiked / 14);
    // find average Time Biked
    const mostTimeBiked = data.reduce((acc, curr) => {
      if (acc.timeBiked > curr.timeBiked) {
        return acc;
      } else {
        return curr;
      }
    }, 0);
    setMostTimeBiked(mostTimeBiked);
    // find the day with the most Time Biked

    // TIME
    const totalPower = data.reduce((acc, curr) => {
      return acc + curr.power;
    }, 0);
    setTotalPower(totalPower);
    // find Total Time Biked
    setAveragePower(totalPower / 14);
    // find average Time Biked
    const mostPower = data.reduce((acc, curr) => {
      if (acc.power > curr.power) {
        return acc;
      } else {
        return curr;
      }
    }, 0);
    setMostPower(mostPower);
    // find the day with the most Time Biked
  }

  // DATA FETCHING
  const { user, authTokens } = useContext(AuthContext);
  const dispatch = useDispatch();
  useEffect(() => {
    // if userBikeAnalytics exists, set values
    if (userBikeAnalytics && userBikeAnalytics.length > 0) {
      setValues(userBikeAnalytics);
    } else {
      if (user && authTokens) {
        dispatch(fetchBikeAnalytics(api));
        dispatch(fetchHourlyBikeUpsert([user.company, api]));
      }
    }
  }, [user, userBikeAnalytics]); //removed api , userBikeAnalytics

  const [chartDataKey, setChartDataKey] = useState("caloriesBurned");
  const [dept, setDept] = useState("All");
  const [range, setRange] = useState("Weekly");

  return (
    <div>
      {/* <Appbar menuItems={menuItems} page="Bike Data Info"/> */}
      <Box
        sx={{
          flexGrow: 1,
          height: "100%",
          width: "100%",
        }}
      >
        {/* PARENT GRID */}
        <Grid
          container
          rowSpacing={2}
          columnSpacing={2}
          sx={{ mt: 1, ml: 1, mr: 1 }}
        >
          <Grid item xs={12}>
            <Item>
              <Box
                sx={{
                  flexGrow: 1,
                  textAlign: "left",
                }}
              >
                <Grid container spacing={2}>
                  {/* TITLE AND DATE */}
                  <Grid item xs={12} lg={6}>
                    <Typography variant="h2">
                      <Link to={"/home"}>
                        {theme === "dark" ? (
                          <ArrowBackIcon
                            sx={{
                              mr: 2,
                              color: "white",
                              cursor: "pointer",
                              fontSize: "3rem",
                              marginRight: "1rem",
                            }}
                          />
                        ) : (
                          <ArrowBackIcon
                            sx={{
                              mr: 2,
                              color: "black",
                              cursor: "pointer",
                              fontSize: "3rem",
                              marginRight: "1rem",
                            }}
                          />
                        )}
                      </Link>
                      {chartDataKey === "caloriesBurned" && (
                        <span>Calories Burned</span>
                      )}
                      {chartDataKey === "distanceBiked" && (
                        <span>Distance Biked</span>
                      )}
                      {chartDataKey === "timeBiked" && <span>Time Biked</span>}
                      {chartDataKey === "power" && <span>Power Generated</span>}
                    </Typography>
                    <Typography variant="h5" sx={{ ml: 6 }}>
                      <strong>{format(new Date(), "MMM")} </strong>
                      {format(new Date(), "dd")}{" "}
                    </Typography>
                  </Grid>

                  {/* DROPDOWNS */}
                  <Grid item xs={12} lg={6}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        {/* I made this full width 4->12 because otherwise there's too much whitespace */}
                        {/* Data Dropdown Menu */}
                        <FormControl fullWidth>
                          <InputLabel
                            id="bikeDataDropdown"
                            sx={{ color: "orange" }}
                          >
                            Data
                          </InputLabel>
                          <Select
                            labelId="bikeDataDropdown"
                            id="demo-simple-select"
                            value={chartDataKey}
                            label="Data"
                            onChange={(e) => setChartDataKey(e.target.value)}
                            sx={{
                              borderColor: "orange",
                              outlineColor: "orange",
                            }}
                          >
                            <MenuItem value={"caloriesBurned"} defaultValue>
                              <Typography variant="body1">
                                Calories Burned
                              </Typography>
                            </MenuItem>
                            <MenuItem value={"distanceBiked"}>
                              Distance Biked
                            </MenuItem>
                            <MenuItem value={"timeBiked"}>
                              Active Time (min)
                            </MenuItem>
                            <MenuItem value={"power"}>Power Generated</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      {/* Department Dropdown Menu */}
                      {/* <Grid item xs={4}>
                        <FormControl fullWidth>
                          <InputLabel
                            id="bikeDeptDropdown"
                            sx={{ color: "orange" }}
                          >
                            Dept
                          </InputLabel>
                          <Select
                            disabled
                            labelId="bikeDeptDropdown"
                            id="demo-simple-select"
                            value={dept}
                            label="Data"
                            onChange={(e) => setDept(e.target.value)}
                            sx={{
                              borderColor: "orange",
                              outlineColor: "orange",
                            }}
                          >
                            <MenuItem value={"All"} defaultValue>
                              <Typography variant="body1">All</Typography>
                            </MenuItem>
                            <MenuItem value={"Marketting"}>Marketing</MenuItem>
                            <MenuItem value={"Engineering"}>
                              Engineering
                            </MenuItem>
                            <MenuItem value={"Human Resources"}>
                              Human Resources
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid> */}
                      {/* Range Dropdown Menu */}
                      {/* <Grid item xs={4}>
                        <FormControl fullWidth>
                          <InputLabel
                            id="bikeRangeDropdown"
                            sx={{ color: "orange" }}
                          >
                            Range
                          </InputLabel>
                          <Select
                            disabled
                            labelId="bikeRangeDropdown"
                            id="demo-simple-select"
                            value={range}
                            label="Range"
                            onChange={(e) => setRange(e.target.value)}
                            sx={{
                              borderColor: "orange",
                              outlineColor: "orange",
                            }}
                          >
                            <MenuItem value={"Weekly"} defaultValue>
                              <Typography variant="body1">Weekly</Typography>
                            </MenuItem>
                            <MenuItem value={"Bi-Weekly"}>Bi-Weekly</MenuItem>
                            <MenuItem value={"Monthly"}>Monthly</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid> */}
                    </Grid>
                  </Grid>

                  {/* AREA CHART */}
                  <Grid item xs={12} lg={9}>
                    <ErgoAreaChart
                      xDataKey={"date"}
                      yDataKey={chartDataKey}
                      chartData={userBikeAnalytics}
                    />
                  </Grid>

                  {/* STATS GRID */}
                  <Grid
                    item
                    container
                    xs={12}
                    // md={6}
                    lg={3}
                    sx={{ textAlign: "center" }}
                  >
                    {/* Calories Burned */}
                    {chartDataKey === "caloriesBurned" && (
                      <>
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              alignContent: "left",
                              flexDirection: "column",
                              padding:2
                            }}
                          >
                            <Typography variant="h6">14 Day Total</Typography>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                alignContent: "left",
                                flexDirection: "row",
                              }}
                            >
                              <Typography
                                variant="h3"
                                className={styles.chartValue}
                                mx={1}
                                sx={{ color: "orange" }}

                              >
                                {/* Round this average to an integer */}
                                {Math.round(totalCaloriesBurned) || 0}
                              </Typography>
                              <Typography
                                variant="caption"
                                className={styles.chartValue}
                                sx={{ color: "orange" }}

                              >
                                kcal
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              alignContent: "left",
                              flexDirection: "column",
                              padding:2
                            }}
                          >
                            <Typography variant="h6">Best Day</Typography>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                alignContent: "left",
                                flexDirection: "row",
                              }}
                            >
                              <Typography
                                variant="h3"
                                className={styles.chartValue}
                                mx={1}
                                sx={{ color: "orange" }}
                              >
                                {/* Round this average to an integer */}
                                {Math.round(
                                  mostCaloriesBurned.caloriesBurned
                                ) || 0}
                              </Typography>
                              <Typography
                                variant="caption"
                                className={styles.chartValue}
                                sx={{ color: "orange" }}

                              >
                                kcal
                              </Typography>
                            </Box>
                          </Box>
                          {/* --------------------------------------------------------- */}

                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              alignContent: "left",
                              flexDirection: "column",
                              padding: 2
                            }}
                          >
                            <Typography variant="h6">Average</Typography>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                alignContent: "left",
                                flexDirection: "row",
                              }}
                            >
                              <Typography
                                variant="h3"
                                className={styles.chartValue}
                                mx={1}
                                sx={{ color: "orange" }}
                              >
                                {/* Round this average to an integer */}
                                {Math.round(averageCaloriesBurned)}

                              </Typography>
                              <Typography
                                variant="caption"
                                className={styles.chartValue}
                                sx={{ color: "orange" }}

                              >
                                kcal
                              </Typography>
                            </Box>
                          </Box>

                        </Grid>
                      </>
                    )}
                    {chartDataKey === "distanceBiked" && (
                      <>
                        <Grid item xs={12}>
                        <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              alignContent: "left",
                              flexDirection: "column",
                              padding:2
                            }}
                          >
                            <Typography variant="h6">14 Day Total</Typography>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                alignContent: "left",
                                flexDirection: "row",
                              }}
                            >
                              <Typography
                                variant="h3"
                                className={styles.chartValue}
                                mx={1}
                                sx={{ color: "orange" }}

                              >
                                {/* Round this average to an integer */}
                                {/* if over 1000 convert to km */}
                                {
                                  totalDistanceBiked > 1000
                                    ? (totalDistanceBiked / 1000).toFixed(1)
                                    : (Math.round(totalDistanceBiked) || 0) 
                                }
                              </Typography>
                              <Typography
                                variant="caption"
                                className={styles.chartValue}
                                sx={{ color: "orange" }}

                              >
                                {
                                  totalDistanceBiked > 1000
                                    ? "km"
                                    : "meters"
                                }
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              alignContent: "left",
                              flexDirection: "column",
                              padding:2
                            }}
                          >
                            <Typography variant="h6">Best Day</Typography>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                alignContent: "left",
                                flexDirection: "row",
                              }}
                            >
                              <Typography
                                variant="h3"
                                className={styles.chartValue}
                                mx={1}
                                sx={{ color: "orange" }}

                              >
                                {/* Round this average to an integer */}
                                {/* if over 1000 convert to km */}
                                {
                                  mostDistanceBiked.distanceBiked > 1000
                                    ? (mostDistanceBiked.distanceBiked / 1000).toFixed(1)
                                    : (Math.round(mostDistanceBiked.distanceBiked) || 0) 
                                }
                              </Typography>
                              <Typography
                                variant="caption"
                                className={styles.chartValue}
                                sx={{ color: "orange" }}

                              >
                                {
                                  mostDistanceBiked.distanceBiked > 1000
                                    ? "km"
                                    : "meters"
                                }
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              alignContent: "left",
                              flexDirection: "column",
                              padding:2
                            }}
                          >
                            <Typography variant="h6">Average</Typography>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                alignContent: "left",
                                flexDirection: "row",
                              }}
                            >
                              <Typography
                                variant="h3"
                                className={styles.chartValue}
                                mx={1}
                                sx={{ color: "orange" }}

                              >
                                {/* Round this average to an integer */}
                                {/* if over 1000 convert to km */}
                                {
                                  averageDistanceBiked > 1000
                                    ? (averageDistanceBiked / 1000).toFixed(1)
                                    : (Math.round(averageDistanceBiked) || 0) 
                                }
                              </Typography>
                              <Typography
                                variant="caption"
                                className={styles.chartValue}
                                sx={{ color: "orange" }}

                              >
                                {
                                  averageDistanceBiked > 1000
                                    ? "km"
                                    : "meters"
                                }
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </>
                    )}
                    {/* Time Biked */}
                    {chartDataKey === "timeBiked" && (
                      <>
                        <Grid item xs={12}>
                        <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              alignContent: "left",
                              flexDirection: "column",
                              padding:2
                            }}
                          >
                            <Typography variant="h6">14 Day Total</Typography>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                alignContent: "left",
                                flexDirection: "row",
                              }}
                            >
                              <Typography
                                variant="h3"
                                className={styles.chartValue}
                                mx={1}
                                sx={{ color: "orange" }}

                              >
                                {/* Round this average to an integer */}
                                {/* if over 1000 convert to km */}
                                {
                                  totalTimeBiked > 60 ? (totalTimeBiked / 60).toFixed(1) : totalTimeBiked
                                }
                              </Typography>
                              <Typography
                                variant="caption"
                                className={styles.chartValue}
                                sx={{ color: "orange" }}

                              >
                                {
                                  totalTimeBiked > 60 ? "hrs" : "min"
                                }
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              alignContent: "left",
                              flexDirection: "column",
                              padding:2
                            }}
                          >
                            <Typography variant="h6">Best Day</Typography>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                alignContent: "left",
                                flexDirection: "row",
                              }}
                            >
                              <Typography
                                variant="h3"
                                className={styles.chartValue}
                                mx={1}
                                sx={{ color: "orange" }}

                              >
                                {/* Round this average to an integer */}
                                {/* if over 1000 convert to km */}
                                {
                                  mostTimeBiked.timeBiked > 60 ? (mostTimeBiked.timeBiked / 60).toFixed(1) : Math.round(mostTimeBiked.timeBiked)
                                }
                              </Typography>
                              <Typography
                                variant="caption"
                                className={styles.chartValue}
                                sx={{ color: "orange" }}

                              >
                                {
                                  mostTimeBiked.timeBiked > 60 ? "hrs" : "min"
                                }
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              alignContent: "left",
                              flexDirection: "column",
                              padding:2
                            }}
                          >
                            <Typography variant="h6">Average</Typography>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                alignContent: "left",
                                flexDirection: "row",
                              }}
                            >
                              <Typography
                                variant="h3"
                                className={styles.chartValue}
                                mx={1}
                                sx={{ color: "orange" }}

                              >
                                {/* Round this average to an integer */}
                                {/* if over 1000 convert to km */}
                                {
                                  averageTimeBiked > 60 ? (averageTimeBiked / 60).toFixed(1) : Math.round(averageTimeBiked)
                                }
                              </Typography>
                              <Typography
                                variant="caption"
                                className={styles.chartValue}
                                sx={{ color: "orange" }}

                              >
                                {
                                  averageTimeBiked > 60 ? "hrs" : "min"
                                }
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </>
                    )}
                    {/* Power Generated */}
                    {chartDataKey === "power" && (
                      <>
                        <Grid item xs={12}>
                        <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              alignContent: "left",
                              flexDirection: "column",
                              padding:2
                            }}
                          >
                            <Typography variant="h6">14 Day Total</Typography>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                alignContent: "left",
                                flexDirection: "row",
                              }}
                            >
                              <Typography
                                variant="h3"
                                className={styles.chartValue}
                                mx={1}
                                sx={{ color: "orange" }}

                              >
                                {/* Round this average to an integer */}
                                {/* if over 1000 convert to km */}
                                {
                                  Math.round(totalPower)
                                }
                              </Typography>
                              <Typography
                                variant="caption"
                                className={styles.chartValue}
                                sx={{ color: "orange" }}

                              >
                                {
                                  "mWh"
                                }
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              alignContent: "left",
                              flexDirection: "column",
                              padding:2
                            }}
                          >
                            <Typography variant="h6">Best Day</Typography>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                alignContent: "left",
                                flexDirection: "row",
                              }}
                            >
                              <Typography
                                variant="h3"
                                className={styles.chartValue}
                                mx={1}
                                sx={{ color: "orange" }}

                              >
                                {/* Round this average to an integer */}
                                {/* if over 1000 convert to km */}
                                {
                                  Math.round(mostPower.power)
                                }
                              </Typography>
                              <Typography
                                variant="caption"
                                className={styles.chartValue}
                                sx={{ color: "orange" }}

                              >
                                {
                                  "mWh"
                                }
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              alignContent: "left",
                              flexDirection: "column",
                              padding:2
                            }}
                          >
                            <Typography variant="h6">Daily Average</Typography>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                alignContent: "left",
                                flexDirection: "row",
                              }}
                            >
                              <Typography
                                variant="h3"
                                className={styles.chartValue}
                                mx={1}
                                sx={{ color: "orange" }}

                              >
                                {/* Round this average to an integer */}
                                {/* if over 1000 convert to km */}
                                {
                                  Math.round(averagePower)
                                }
                              </Typography>
                              <Typography
                                variant="caption"
                                className={styles.chartValue}
                                sx={{ color: "orange" }}

                              >
                                {
                                  "mWh"
                                }
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </>
                    )}
                  </Grid>
                  {/* <Grid item xs={12} md={6} lg={3} sx={{ textAlign: "center" }}>
                    <SmallDonutChart
                      data={[
                        {
                          name: "Marketing",
                          data: 25,
                          fill: "#F4766E",
                        },
                        {
                          name: "Human Resources",
                          data: 35,
                          fill: "#FF900F",
                        },
                        {
                          name: "Engineering",
                          data: 40,
                          fill: "#FFAF51",
                        },
                      ]}
                      unitsLabel="Total Calories"
                      legendAlign="center"
                      legendVerticalAlign="bottom"
                    />
                  </Grid> */}
                  <Grid item xs={12}>
                    <BikeDataTable />
                  </Grid>
                </Grid>
              </Box>
            </Item>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default HomeBikePage;
