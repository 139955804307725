import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { Button, Container, Grid, Box } from "@mui/material";
import "./ForgotPassword.scss";
import logo from "../../assets/images/ergonomyx_logo.png";
import axios from "axios";

export default function ForgotPassword() {

  const [email, setEmail] = useState("")
  const [status, setStatus] = useState(null);

  const handleClick = (e) => {
    e.preventDefault()
    try {
      console.log("window location : ", window.location.origin)
      axios.post(`${process.env.REACT_APP_DASHBOARD_API_URL}/dashboard_user/forgot_password/`, {
        email: email,
        current_url: window.location.origin //or something like that ?!
      })
      .then((response) => {
        setStatus("success");
        console.log("successfully sent password reset link");
        setEmail("")
      })
      .catch((error) => {
        setStatus("error");
        console.log(error);
      });
    } catch (error) {
      console.log(error)
    }
  }
  

  return (
      <div className="forgot">
        <div className="forgot__header">
          <img src={logo} alt="" className="forgot__img" />
          <h1 className="forgot__heading">
            <span className="forgot__letter">E</span>rgonomyx
          </h1>
        </div>
        <div className="forgot__headline-div">
          <p className="forgot__headline">
            Enter your email address and we'll send instructions
            <br /> to reset your password
          </p>
        </div>
        <form className="forgot__form">
          <div className="forgot__textfield-div">
            <TextField
              className="forgot__textfield"
              label="Email"
              variant="outlined"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx = {{'& label.Mui-focused': {
                color: 'orange',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: 'lightgrey',
                },
                '&:hover fieldset': {
                  borderColor: 'orange',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'orange',
                }
              }}}
              fullWidth
            />
          </div>
          <div className="forgot__button-div">
            <Button
              className="forgot__button btn-forgot"
              variant="contained"
              type="submit"
              onClick={handleClick}
            >
              Send password reset link
            </Button>
            <Button
              component={Link}
              to="/login"
              className="forgot__button btn-back"
              variant="outlined"
              type="submit"
            >
              Back
            </Button>
          </div>
          <Grid item xs={12}>
              <Box
                // background is green if status='success', red if status='error'
                sx={{
                  bgcolor:
                    status === "success"
                      ? "success.main"
                      : status === "error" ||
                        status === "expired" ||
                        status === "no_match" ||
                        status === "invalid_code"
                      ? "error.main"
                      : "background.paper",
                  color: "white",
                  borderRadius: "5px",
                  padding: "7px",
                  marginTop: "15px"
                }}
              >
                {status === "success"
                  ? "If the email address entered matches with a registered user, an email containing directions to reset your password will be sent to your inbox."
                  : null}
                {status === "error"
                  ? "Error - Email Not Sent."
                  : null}
              </Box>
            </Grid>
        </form>
      </div>
  );
}
