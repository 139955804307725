import MoveDeskControls from "../components/MoveDeskControls/MoveDeskControls";
// import ResetDeskControls from "../components/MoveDeskControls/ResetDeskControls";
// import FirmwareDeskControls from "../components/MoveDeskControls/FirmwareDeskControls";
import CombinedFirmwareResetControls from "../components/MoveDeskControls/CombinedFirmwareResetControls";
import { useDispatch, useSelector } from "react-redux";
import { fetchDeskDevices } from "../components/Reducers/deviceReducer";
import useAxios from "../utils/useAxios";
import AuthContext from "../context/AuthContext";
import { useContext } from "react";
import { useEffect } from "react";
import { Box } from "@mui/material";

const MqttDeskControlsPage = () => {

  const api = useAxios()
  const dispatch = useDispatch();
  // const current_user = useSelector( store => store.dashboard_user.current_user)
  const {loading, user} = useContext(AuthContext)

  //probably a good idea to surround this in a useEffect, perhaps Bryce can help me with that
  useEffect(() =>{
    if(user){
      dispatch(fetchDeskDevices([api , user.company])) // this will get all the desk devices to user for the dropdown in the next page
    }
  }, [user])

  return (
    <div>
      <Box p={2}>
        <MoveDeskControls />
      </Box>
      <Box p={2} marginY={3}>
        <CombinedFirmwareResetControls />
        {/* <ResetDeskControls />
        <FirmwareDeskControls /> */}
      </Box>
    </div>

    
  );
};

export default MqttDeskControlsPage;
