import "./EmployeeList.module.scss";
import * as React from "react";
import {
  DataGrid,
  GridToolbarFilterButton,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import styles from "./EmployeeList.module.scss";
import "../../styles/colours.scss";
import { useContext, useState } from "react";
import { Box } from "@mui/material";
import EditEmployeeModal from "../EditEmployeeModal/EditEmployeeModal";
import AddEmployeeModal from "../AddEmployeeModal/AddEmployeeModal";

// import { store as dashboardStore } from "../dashboardStore";
import { useSelector } from "react-redux";
import AuthContext from "../../context/AuthContext";

// Function to render settings button in each DataGrid row
const renderEditEmployeeModal = (cellValues_row, teams) => {
  return (
    <EditEmployeeModal
      employee={cellValues_row}
      teams={teams}
    />
  );
};



// Custom toolbar with the filter option
function CustomToolbar() {

  const { user, loading } = useContext(AuthContext);
  let isSuperAdmin = user.user_type > 2 ? true : false
  // console.log("SuperAdmin? ", String(isSuperAdmin) )

  return (
    <GridToolbarContainer>
      <h1 className={styles.title}>Employee List</h1>
      <GridToolbarFilterButton
          variant="outlined"
          sx={{
            borderRadius: "20px",
            margin: "2rem",
            fontSize: "1.2rem",
            fontWeight: "bold",
            textTransform: "none",
            color: "orange",
            borderColor: "orange",
            "&:hover" : {
              borderColor: "orange",
            }
          }}
      />

    {
      // Add AddEmployeeModal component inside a box and push it to the right using sx
    }
    {/* add a box with the AddEmployeeModal and AddCompanyModal with padding on each component */}
  
      <Box 
      sx={{ 
        flexGrow: 1 

      }} />
      <AddEmployeeModal />

    </GridToolbarContainer>
  );
}

export default function EmployeeList(props) {
  // Columns for the DataGrid
  const teams = useSelector((dashboardStore) => dashboardStore.team.teams);
  const teams_raw = useSelector((dashboardStore) => dashboardStore.team.teams_raw);

const columns = [
  // {
  //   field: "id",
  //   headerName: "UserID",
  //   width: 90,
  //   headerAlign: "center",
  //   align: "center",
  //   flex: 1,
  // },
  {
    field: "name",
    headerName: "Name",
    width: 200,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "team_name",
    headerName: "Team",
    width: 90,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "user_type",
    headerName: "User Type",
    width: 100,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "status",
    headerName: "Status",
    width: 100,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },

  {
    field: "edit",
    headerName: "",
    align: "center",
    className: "styles.settingsBtn",
    width: 200,
    renderCell: (cellValues) => renderEditEmployeeModal(cellValues.row, teams_raw),
    flex: 1,
  },
];

  // Page size state for the DataGrid
  const [pageSize, setPageSize] = useState(25);

  // Get team and employee data from the store
  const employees = useSelector(
    (dashboardStore) => dashboardStore.dashboard_user.dashboard_users
  );

  // Create a map for each user type
  const user_type_map = {
    1: "Employee",
    2: "Admin",
    3: "Super Admin",
  };

  // Create a deep copy of employees, and append a team name and full name to each employee
  // This is passed as the rows prop to the DataGrid
  let rows = [];
  for (let i = 0; i < employees.length; i++) {
    rows.push({
      ...employees[i],
      team_name: teams[employees[i].team],
      name: employees[i].first_name + " " + employees[i].last_name,
      user_type: user_type_map[employees[i].user_type],
    });
  }


  return (
    <div className={styles.dataGridContainer}>
        {rows && (
          <DataGrid
            // components={{Toolbar: GridToolbarFilterButton}}
            components={{ Toolbar: CustomToolbar }}
            pageSize={pageSize}
            onPageSizeChange={(newPage) => setPageSize(newPage)}
            pagination
            rowsPerPageOptions={[5, 10, 15, 20, 25]}
            rows={rows}
            columns={columns}
            rowHeight={60}
            text
            // override the Filter button innter text from "filters"
            localeText={{
              toolbarFilters: "Filter",
            }}
            sx={{
              border: "none",
              ".MuiDataGrid-columnHeaderTitle": {
                fontWeight: "bold",
              },
            }}
          />
        )}
    </div>
  );
}
