import { Box, Grid, Typography, Badge } from "@mui/material";
import WifiIcon from "@mui/icons-material/Wifi";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import AdminCompanies from "../components/AdminCompanies/AdminCompanies";
import AdminDeviceModal from "../components/AdminDeviceModal/AdminDeviceModal";
import AdminAddCompanyModal from "../components/AdminAddCompanyModal/AdminAddCompanyModal";
import useAxios from "../utils/useAxios";
import { fetchAllDevices } from "../components/Reducers/deviceReducer";
import { fetchAllCompanies } from "../components/Reducers/companyReducer";
import { useDispatch, useSelector } from "react-redux";
import BasicTabs from "../components/TabPanel/TabPanel";
import { fetchAllDashboardUsers } from "../components/Reducers/dashboardUserReducer";
import { fetchAllTeams } from "../components/Reducers/teamReducer";

const renderDeviceStatus = (is_connected) => {
  return (
    <Badge
      badgeContent=" "
      color={is_connected ? "success" : "error"}
      variant="dot"
      sx={{
        width: "1rem",
        height: "1rem",
      }}
    >
      <WifiIcon
        sx={{
          width: "1rem",
          height: "1rem",
        }}
      />
    </Badge>
  );
};

const renderDeviceModal = (cellValues_row, companies) => {
  return <AdminDeviceModal device={cellValues_row} companies={companies} />;
};

export default function AdminPage() {
  const api = useAxios();
  const dispatch = useDispatch();
  const devices = useSelector((state) => state.device.adminDeviceList);

  console.log("devices : ", devices);

  const companies = useSelector((state) => state.company.adminCompanyList);
  const dashboardUsers = useSelector((state) => state.dashboard_user.all_dashboard_users);
  const teams = useSelector((state) => state.team.admin_teams);
  const [loading, setLoading] = useState(true);
  const [tablePageSize, setTablePageSize] = useState(10);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "company",
      headerName: "Company",
      width: 90,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "dashboard_user",
      headerName: "User ID",
      width: 90,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "device_name",
      headerName: "Device Name",
      width: 100,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "asset_tag_id",
      headerName: "Device Asset Tag ID",
      width: 100,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "serial_number",
      headerName: "Device Serial Number",
      width: 100,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "is_connected",
      headerName: "Status",
      width: 100,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (cellValues) =>
        renderDeviceStatus(cellValues.row.is_connected),
    },
    {
      field: "settings",
      headerName: "",
      align: "center",
      className: "styles.settingsBtn",
      width: 200,
      flex: 1,
      renderCell: (cellValues) => renderDeviceModal(cellValues.row, companies),
    },
  ];

  useEffect(() => {
    dispatch(fetchAllDevices(api));
    dispatch(fetchAllCompanies(api));
    dispatch(fetchAllDashboardUsers(api)); 
    dispatch(fetchAllTeams(api))
  }, []);

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          height: "100%",
          width: "100%",
          pb: 5,
        }}
      >
        <Grid container spacing={2} m="auto" mt={1}>
          <Grid item xs={12} lg={3}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Typography variant="h3" mb={2}>
                Companies
              </Typography>
              <Box
                sx={{
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "rgba(0, 0, 0, 0.2)",
                  },
                  "&::-webkit-scrollbar": {
                    backgroundColor: "transparent",
                  },
                  overflowY: "auto",
                  maxHeight: "100%",
                  width: "100%",
                }}
              >
                {companies.map((company) => (
                  <AdminCompanies key={company.id} company={company} />
                ))}
              </Box>
              <Box p={1} />
              <AdminAddCompanyModal />
            </Box>
          </Grid>
          <Grid item xs={12} lg={8}>
            <Box
              width="100%"
              minHeight={{ xs: "500px", lg: "100%" }}
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <BasicTabs
                devices={devices}
                tablePageSize={tablePageSize}
                setTablePageSize={setTablePageSize}
                companies={companies}
                dashboardUsers={dashboardUsers}
                teams={teams}
                
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
