//
import { useState, useEffect } from "react";
import {
  DataGrid,
  GridToolbarFilterButton,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import styles from "./DeviceList.module.scss";
import "../../styles/colours.scss";
import { useSelector } from "react-redux";
import DeviceDetailsAlert from "../DeviceDetailsModal/DeviceDetailsAlert";

// import badge and wifi icon for the device status
import Badge from "@mui/material/Badge";
import WifiIcon from "@mui/icons-material/Wifi";
import EditDeviceModal from "../EditDeviceModal/EditDeviceModal";
import CompanyDeviceRequests from "../CompanyDeviceRequests/CompanyDeviceRequests";

const renderDeviceStatus = (is_connected) => {
  // Option 1 - Badge with Wifi Icon
    return (
      <Badge
        badgeContent=" "
        color={is_connected ? "success" : "error"}
        variant="dot"
        sx={{
          width: "1rem",
          height: "1rem",
        }}

      >
        <WifiIcon 
          sx={{
            width: "1rem",
            height: "1rem",
          }}
        />
      </Badge>
    );

    // Option 2 - Colored Wifi Icon
    // return (
    //   <WifiIcon
    //     sx={{
    //       color: is_connected ? "success.main" : "error.main",
    //     }}
    //   />
    // )
  };



const renderSettingsButton = (cellValues_row, employeeData) => {
  return (
    <EditDeviceModal
      device={cellValues_row}
      employeeList={employeeData}
    />
  );
};



function ConstructRows(deviceType, devices, employees, companies) {
  // Construct the rows for the table
  let rows = [];
  console.log("devices: ", devices);

  // if any data is empty, return empty rows
  if (
    companies.length === 0 ||
    employees.length === 0 ||
    devices.length === 0
  ) {
    return rows;
  }

  const employeeMap = new Map();
  employees.forEach((employee) => {
    employeeMap.set(employee.id, employee);
  });

  for (let i = 0, len = devices.length; i < len; i++) {
    let id = devices[i].id;
    let company = companies.company_name;
    let dashboard_user = employeeMap.get(devices[i].dashboard_user);
    try {
      dashboard_user =
        dashboard_user.first_name + " " + dashboard_user.last_name;
    } catch (e) {
      dashboard_user = "-";
    }
    let device_name = devices[i].device_name;
    let device_asset_tag_id = devices[i].asset_tag_id;
    let serial_number = devices[i].serial_number;
    let assigned = devices[i].assigned;
    let is_connected = devices[i].is_connected;

    rows.push({
      id: id,
      device_name: device_name,
      device_asset_tag_id: device_asset_tag_id,
      serial_number: serial_number,
      assigned: assigned,
      company: company,
      dashboard_user: dashboard_user,
      device_arn: devices[i].device_arn,
      desk_firmware_version: devices[i].desk_firmware_version,
      last_date: devices[i].last_date,
      dashboard_user_id: devices[i].dashboard_user,
      is_connected: is_connected,
    });
  }
  return rows;
}

export default function DeviceList({ deviceType }) {
  const [pageSize, setPageSize] = useState(5);

  const bikes = useSelector((dashboardStore) => dashboardStore.device.bikes);
  const desks = useSelector((dashboardStore) => dashboardStore.device.desks);
  const employees = useSelector(
    (dashboardStore) => dashboardStore.dashboard_user.dashboard_users
  );
  const companies = useSelector(
    (dashboardStore) => dashboardStore.company.companies
  );

  const deviceRequests = useSelector(
    (dashboardStore) => dashboardStore.device.deviceRequests
  );

  let devices = deviceType === "bike" ? bikes : desks;
  let rows = ConstructRows(deviceType, devices, employees, companies);
  let columns = [
    {
      field: "id",
      headerName: "ID",
      width: 40,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "dashboard_user",
      headerName: "Assigned User",
      width: 90,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "device_name",
      headerName: "Device Name",
      width: 100,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "device_asset_tag_id",
      headerName: "Device Asset Tag ID",
      width: 120,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "serial_number",
      headerName: "Serial Number",
      width: 100,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "is_connected",
      headerName: "Status",
      width: 100,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (cellValues) => 
        renderDeviceStatus(cellValues.row.is_connected),
    },
    {
      field: "settings",
      headerName: "",
      align: "center",
      className: "styles.settingsBtn",
      width: 200,
      // I ned rendercell to re-run whenever desks is updated
      renderCell: (cellValues) =>
        renderSettingsButton(cellValues.row, employees),
      flex: 1,
    },
  ];

  // Custom toolbar with the filter option
function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <h1 className={styles.title}>Devices List</h1>
      <GridToolbarFilterButton
        variant="outlined"
        sx={{
          borderRadius: "20px",
          margin: "2rem",
          fontSize: "1.2rem",
          fontWeight: "bold",
          textTransform: "none",
          color: "orange",
            borderColor: "orange",
            "&:hover" : {
              borderColor: "orange",
            }
        }}
      />
      <DeviceDetailsAlert />
      <CompanyDeviceRequests deviceRequests={deviceRequests} desks={desks} employees={employees}/>
    </GridToolbarContainer>
  );
}
  
  // useEffect to reconstruct the rows when the device info changes
  useEffect(() => {
    rows = ConstructRows(deviceType, devices, employees, companies);
  }, [devices])

  return (
    <div className={styles.dataGridContainer}>
      {rows && employees && companies && (
        <DataGrid
          components={{ Toolbar: CustomToolbar }}
          pageSize={pageSize}
          onPageSizeChange={(newPage) => setPageSize(newPage)}
          pagination
          rowsPerPageOptions={[5, 10, 15, 20, 25]}
          rows={rows}
          columns={columns}
          rowHeight={60}
          text
          // override the Filter button inner text from "filters"
          localeText={{
            toolbarFilters: "Filter",
          }}
          sx={{
            border: "none",
            ".MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold",
            },
          }}
          
        />
      )}
    </div>
  );
}
