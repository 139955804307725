// import React from 'react';
// import { Grid, Box, Paper, Typography } from '@mui/material';
// import RewardCard from '../RewardCard/RewardCard';

// export default function RewardGroup({ challengeGroup }) {
//   return (
//     <>
//       <Paper
//         elevation={3}
//         sx={{
//           height: '100%',
//           padding: 2,
//           minWidth: 300,
//           transition: '0.3s',
//           '&:hover': {
//             transform: 'scale(1.02)',
//           },
//           display: 'flex',
//           flexDirection: 'column',
//         }}
//       >
//         <Typography variant="h3">{challengeGroup.challenge_name}</Typography>
//         {/* map each completed challenge into a rewardCard */}
//         {/* <Box sx={{ display: "flex" , flexDirection: "row" }}>
//           {challengeGroup.challenges.map((challenge) => (
//             <Box sx={{paddingX:1}}>
//               <RewardCard completed_challenge={challenge} />
//               </Box>
            
//           ))}
//         </Box> */}
//         <Grid container spacing={2}>
//           {challengeGroup.challenges.map((challenge, index) => (
//             <Grid item key={index}>
//               <RewardCard completed_challenge={challenge} />
//             </Grid>
//           ))}
//         </Grid>
//       </Paper>
//     </>
//   );
// }

import { Box } from "@mui/system";
import { Grid, Paper, Typography } from "@mui/material";
import React from "react";
import RewardCard from "../../components/RewardCard/RewardCard";

const RewardGroup = ({challenges, challenge_task_percentages}) => {
  return (
    <Box sx={{ padding: 2, mt: 2 }}>
      <Typography variant="h3" sx={{ textAlign: "center" }}>
        {challenges[0].challenge_name}
      </Typography>
      <Box
        sx={{
          // column with spacing
          display: "flex",
          flexDirection: "column",
        }}
      >
      {challenges &&
        challenges.map((specific_challenge, index) => (
          <RewardCard
            key={index}
            challenge={specific_challenge}
          />
        ))}
      </Box>
    </Box>
  );
};

export default RewardGroup;
