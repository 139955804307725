import { createTheme } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import { fetchCurrentDashboardUser } from "../components/Reducers/dashboardUserReducer";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import PersistentDrawer from "./PersistentDrawer/PersistentDrawer";
import useAxios from '../utils/useAxios'
import { Route, Redirect } from "react-router-dom";
import BetaPill from "./BetaPill/BetaPill";
import ThemeContext from "../context/ThemeContext";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 960,
      md:960,
      lg: 1200,
      xl: 1536,
    },
  },
});

export default function Layout({ children }) {

  const api = useAxios()

  const location = useLocation();
  const dispatch = useDispatch();
  const { user, loading } = useContext(AuthContext);

  useEffect(() => {
    if (!loading) {
      if (user) {
        dispatch(fetchCurrentDashboardUser([user.user_id, api]));
      }
    }
  }, [api,loading, user]);

  return (
    // <div className={classes.root}>
    <div>
      {location.pathname === "/" ||
      location.pathname === "/login" ||
      location.pathname === "/register" ||
      location.pathname === "/forgot" ||
      location.pathname.includes("/reset") ? (
        <div>{children}</div>
      ) : (
        <Box>
          {!user ? <Redirect to="/login" /> : <PersistentDrawer children={children} />}
        </Box>
      )}
      {/* <div className={classes.page}>{children}</div> */}
    </div>
  );
}
