import React, { useState } from "react";
import {
  Typography,
  Modal,
  Button,
  TextField,
  Box,
  Stack,
  Select,
  MenuItem,
  Container,
  Snackbar,
} from "@mui/material";
import useAxios from "../../utils/useAxios";

export default function AdminAddCompanyModal() {
  const api = useAxios();

  // Modal State
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Snackbar State
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleSnackbarOpen = () => setSnackbarOpen(true);
  const handleSnackbarClose = () => setSnackbarOpen(false);
  const action = (
    <React.Fragment>
      <Button color="primary" size="small" onClick={handleSnackbarClose}>
        Close
      </Button>
    </React.Fragment>
  );

  const handleSave = () => {
    api
      .post(`/company/create/`, {
        company_name: companyName,
        company_admin_email: companyAdminEmail,
        address_1: address_1,
        address_2: address_2,
        city: city,
        province: province,
        postal_code: postal_code,
        phone: phone,
        country: country,
      })
      .then((res) => {
        if (res.status === 201) {
          setSnackbarMessage("Company Added Successfully");
        } else {
          setSnackbarMessage("Error Adding Company: " + res.message);
        }
      })
      .catch((err) => {
        console.log(err);
        setSnackbarMessage("Error Adding Company: " + err);
      });
    handleSnackbarOpen();
    handleClose();
  };

  // Form State
  const [companyName, setCompanyName] = useState("");
  const [companyAdminEmail, setCompanyAdminEmail] = useState("");
  const [address_1, setAddress_1] = useState("");
  const [address_2, setAddress_2] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [postal_code, setPostal_code] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("Canada");

  return (
    <>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        action={action}
      />
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            // should be scrollable if content is too long
            overflow: "auto",
            maxHeight: "90%",
          }}
        >
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Typography variant="h4" mb={2}>
              Add New Company
            </Typography>
            <TextField
              id="outlined-basic"
              label="Company Name"
              variant="outlined"
              fullWidth
              mb={2}
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
            <TextField
              id="outlined-basic"
              label="Company Admin Email"
              variant="outlined"
              fullWidth
              mb={2}
              value={companyAdminEmail}
              onChange={(e) => setCompanyAdminEmail(e.target.value)}
            />

            <TextField
              id="outlined-basic"
              label="Address 1"
              variant="outlined"
              fullWidth
              mb={2}
              value={address_1}
              onChange={(e) => setAddress_1(e.target.value)}
            />
            <TextField
              id="outlined-basic"
              label="Address 2"
              variant="outlined"
              fullWidth
              mb={2}
              value={address_2}
              onChange={(e) => setAddress_2(e.target.value)}
            />
            <TextField
              id="outlined-basic"
              label="City"
              variant="outlined"
              fullWidth
              mb={2}
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
            <TextField
              id="outlined-basic"
              label="Postal Code"
              variant="outlined"
              fullWidth
              mb={2}
              value={postal_code}
              onChange={(e) => setPostal_code(e.target.value)}
            />
            <TextField
              id="outlined-basic"
              label="Province"
              variant="outlined"
              fullWidth
              mb={2}
              value={province}
              onChange={(e) => setProvince(e.target.value)}
            />

            <Select
              labelId="country-select-label"
              id="country-select"
              label="Country"
              value={country}
              fullWidth
              mb={2}
            >
              <MenuItem value="United States">United States</MenuItem>
              <MenuItem value="Canada">Canada</MenuItem>
            </Select>

            <TextField
              id="outlined-basic"
              label="Phone Number"
              variant="outlined"
              fullWidth
              mb={2}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <Container
              sx={{ display: "flex", justifyContent: "space-between" }}
              spacing={2}
            >
              <Button
                variant="contained"
                onClick={handleClose}
                sx={{ backgroundColor: "red", color: "white" }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={handleSave}
                sx={{ color: "white" }}
              >
                Save
              </Button>
            </Container>
          </Stack>
        </Box>
      </Modal>
      <Button variant="contained" onClick={handleOpen} sx={{ color: "white" }}>
        Add Company
      </Button>
    </>
  );
}
