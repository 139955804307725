import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AuthContext from "../../context/AuthContext";
import { useContext } from "react";
import { useSelector } from 'react-redux';

//REFACTORED THIS TO USE AXIOS REQUEST LIBRARY
export const fetchDashboardUsers = createAsyncThunk(
  "dashboard_user/fetchDashboardUsers",
  async ([companyId, api]) => {
    let get_employees_by_company_url = `/dashboard_user/get_by_company/${companyId}`;
    const response = await api.get(get_employees_by_company_url);
    const data = response.data
    return data;
  }
);

//REFACTORED THIS TO USE AXIOS REQUEST LIBRARY
export const fetchCurrentDashboardUser = createAsyncThunk(
  "dashboard_user/fetchCurrentDashboardUser",
  async ([dashboard_user_id, api]) => {
    let get_current_dashboard_user = `/dashboard_user/${dashboard_user_id}/`;
    const response = await api.get(get_current_dashboard_user);
    const data = response.data
    return data;
  }
);

export const fetchAllDashboardUsers = createAsyncThunk(
  "dashboard_user/",
  async (api) => {
    let get_all_dashboard_users = `/dashboard_user/`;
    const response = await api.get(get_all_dashboard_users);
    const data = response.data
    return data;
  }
);
export const fetchBikeAnalytics = createAsyncThunk(
  "dashboard_user/fetchBikeAnalytics",
  async (api) => {
    try {
    // end date is today
    let end_date = new Date();
    let end_date_string = end_date.toISOString().split("T")[0];
    // start date is 7 days ago
    let start_date = new Date();
    start_date.setDate(start_date.getDate() - 14);
    let start_date_string = start_date.toISOString().split("T")[0];

    //hardcoded to 14 days
    let get_bike_analytics = `/dashboard_user/get_bike_analytics/${start_date_string}/${end_date_string}/`;
    const response = await api.get(get_bike_analytics);
    const data = response.data

    // sory by date
    data.sort((a, b) => {
      return new Date(a.date) - new Date(b.date);
    });


    return data;
  } catch (error) {
    console.log(error);
  }
  });

  export const fetchDeskAnalytics = createAsyncThunk(
    "dashboard_user/fetchDeskAnalytics",
    async (api) => {
      try{
      // end date is today
      let end_date = new Date();
      let end_date_string = end_date.toISOString().split("T")[0];
      // start date is 7 days ago
      let start_date = new Date();
      start_date.setDate(start_date.getDate() - 14);
      let start_date_string = start_date.toISOString().split("T")[0];

      //hardcoded to 14 days
      let get_desk_analytics = `/dashboard_user/get_desk_analytics/${start_date_string}/${end_date_string}`;
      const response = await api.get(get_desk_analytics);
      const data = response.data
    
      // sory by date
      data.sort((a, b) => {
        return new Date(a.date) - new Date(b.date);
      });
  
      return data;
    } catch (error) {
      console.log(error)
    }
    });
  

const dashboardUserDataSlice = createSlice({
  name: "dashboard_user",
  initialState: { current_dashboard_user : {}, dashboard_users: [], bike_analytics: [], desk_analytics: [], all_dashboard_users: [] },
  reducers: {
    dashboardUserDataFetched(state, action) {
      state = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchDashboardUsers.pending, (state, action) => {
        //
      })
      .addCase(fetchDashboardUsers.fulfilled, (state, action) => {
        state.dashboard_users = action.payload;
      })
      .addCase(fetchDashboardUsers.rejected, (state, action) => {
        console.log("error in extra reducers (fetchDashboard users)");
      })
      .addCase(fetchAllDashboardUsers.pending, (state, action) => {
        //
      })
      .addCase(fetchAllDashboardUsers.fulfilled, (state, action) => {
        state.all_dashboard_users = action.payload;
      })
      .addCase(fetchAllDashboardUsers.rejected, (state, action) => {
        console.log("error in extra reducers (fetchAllDashboardUsers)");
      })
      .addCase(fetchCurrentDashboardUser.pending, (state, action) => {
      })
      .addCase(fetchCurrentDashboardUser.fulfilled, (state, action) => {
        state.current_dashboard_user = action.payload;
      })
      .addCase(fetchCurrentDashboardUser.rejected, (state, action) => {
        console.log("error in extra reducers (fetchCurrentDashboardUser)");
      })
      .addCase(fetchBikeAnalytics.pending, (state, action) => {
        //
      })
      .addCase(fetchBikeAnalytics.fulfilled, (state, action) => {
        state.bike_analytics = action.payload;
      })
      .addCase(fetchBikeAnalytics.rejected, (state, action) => {
        console.log("error in extra reducers (fetchBikeAnalytics)");
      })
      .addCase(fetchDeskAnalytics.pending, (state, action) => {
       //
      })
      .addCase(fetchDeskAnalytics.fulfilled, (state, action) => {
        state.desk_analytics = action.payload;
      })
      .addCase(fetchDeskAnalytics.rejected, (state, action) => {
        console.log("error in extra reducers (fetchDeskAnalytics)");
      });
  },
});

export const { dashboardUserDataFetched } = dashboardUserDataSlice.actions;
export default dashboardUserDataSlice.reducer;
