import { Paper, Box, Stack, Typography } from "@mui/material";
import React from "react";
import Switch from "@mui/material/Switch";
import ThemeContext from "../../context/ThemeContext";
import { useSelector, useDispatch } from "react-redux";

export default function ChallengeGroupCard({ challengeGroup, img }) {
  const { theme } = React.useContext(ThemeContext);
  const tempCompanyChallenges = useSelector(
    (state) => state.company.tempChallenges
  );
  const dispatch = useDispatch();

  return (
    <>
      <Paper
        elevation={3}
        sx={{
          transition: "0.3s",
          "&:hover": {
            transform: "scale(1.02)",
          },
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${img})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "200px",
            borderRadius: "5px 5px 0 0",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              display: "flex",
              alignItems: "center",
              paddingX: 2,
              height: "100%",
              textShadow: "0 0 5px #000",
              color: "white",
            }}
          >
            {challengeGroup.challenge_name}
          </Typography>
        </Box>

        <Box sx={{ padding: 4 }}>
          {/* iterate of the challenges in challengeGroup, and create a Paper with each task_name */}
          {challengeGroup.challenges.map((challenge) => (
            <Paper
              key={challenge.id}
              elevation={3}
              sx={{
                p: 1,
                mt: 1,

                "&:hover": {
                  backgroundColor: theme === "dark" ? "#616161" : "#E0E0DF",
                },
              }}
            >
              <Typography fontWeight={"bold"}>{challenge.task_name}</Typography>
              <Typography>{challenge.task}</Typography>
            </Paper>
          ))}
          <Stack
            direction="row"
            spacing={2}
            sx={{
              mt: 2,
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Typography variant="caption">Disable/Enable</Typography>

            <Switch
              checked={tempCompanyChallenges.includes(
                challengeGroup.challenge_group
              )}
              onChange={(e) => {
                dispatch({
                  type: "company/toggleChallenge",
                  payload: challengeGroup.challenge_group,
                });
                dispatch({ type: "company/setPendingChanges", payload: true });
              }}
              inputProps={{ "aria-label": "controlled" }}
              color="warning"
            />
          </Stack>
        </Box>
      </Paper>
    </>
  );
}
