import styles from '../styles/home.scss';
import Leaderboard from '../components/Leaderboard/Leaderboard';
import { fetchUserLeaderboard} from '../components/Reducers/leaderboardReducer';
import { fetchTeamLeaderboard } from '../components/Reducers/leaderboardReducer';
import { useDispatch } from 'react-redux';
import Grid from '@mui/system/Unstable_Grid/Grid';
import { Box } from '@mui/system';
import Paper from "@mui/material/Paper";
import { styled } from '@mui/material/styles';
import React, { useContext, useEffect } from "react";
import AuthContext from '../context/AuthContext';
import useAxios from '../utils/useAxios'

// Styling for the paper
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  borderRadius: "25px",
}));

function Employees() {

  const api = useAxios()

  const dispatch = useDispatch();
  const { user } = useContext(AuthContext);
  
  useEffect(() => {
    if (user) {
      dispatch(fetchUserLeaderboard([user.company, api]));
      dispatch(fetchTeamLeaderboard([user.company, api]));
    }
  }, [user]);

  return (
    <div >
      {/* <Appbar menuItems={menuItems} page="Company Profile" /> */}
      <Box
        sx={{
          flexGrow: 1,
          height: "100%",
          width: "100%",
        }}
      >
        
        <Grid
          container
          rowSpacing={2}
          columnSpacing={2}
          sx={{ mt: 1, ml: 1, mr: 1 }}
        >
          <Grid item xs={12}>
            <Item>
              <Leaderboard />
            </Item>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default Employees;

