import React, { useState, useContext } from "react";
import { Typography, Button, TextField, Box, Stack } from "@mui/material";
import useAxios from "../../utils/useAxios";
import AuthContext from "../../context/AuthContext";

export default function EditHeightPresets() {
  const api = useAxios();
  const { user } = useContext(AuthContext);

  //just having this during testing, in case the database working with doesn't have these fields
  const sitting_height_preset = user.sitting_height_preset
    ? user.sitting_height_preset
    : 90;
  const standing_height_preset = user.standing_height_preset
    ? user.standing_height_preset
    : 110;

  const inRange = (value) => value >= 80 && value <= 120;

  const [inputStandingHeight, setInputStandingHeight] = useState(
    standing_height_preset
  );
  const [inputSittingHeight, setInputSittingHeight] = useState(
    sitting_height_preset
  );

  const handleSave = () => {
    //The UI should've filtered for us that the values are in the correct range,
    //However additional check required to make sure standing > sitting

    if (parseInt(inputStandingHeight) >= parseInt(inputSittingHeight)) {
      console.log("INPUT VALID ! ✅");
      console.log(
        `Standing : ${inputStandingHeight} larger than or equal to Sitting : ${inputSittingHeight}`
      );

      api
        .put(`/dashboard_user/${user.user_id}/`, {
          sitting_height_preset: inputSittingHeight,
          standing_height_preset: inputStandingHeight,
        })
        .then((res) => {
          if (res.status === 200) {
            alert("Height Presets Updated Successfully");
          }
        })
        .catch((err) => {
          alert(err);
        });
    } else {
      console.log("INPUT NOT VALID ! ❌");
      console.log(
        `Standing : ${inputStandingHeight} NOT larger than or equal to Sitting : ${inputSittingHeight}`
      );
    }
  };

  const textFieldStyle = {
    width: "100%",
  };

  return (
    <>
      <Stack spacing={2}>
        <Typography variant="h2">Height Presets</Typography>
      </Stack>
      <Stack spacing={2} marginTop={2} sx={{ maxWidth: "sm" }}>
        <TextField
          label="Standing Height Preset"
          variant="outlined"
          value={inputStandingHeight}
          onChange={(e) => setInputStandingHeight(e.target.value)}
          sx={textFieldStyle}
          error={!inRange(inputStandingHeight)}
          required
        />

        <TextField
          label="Sitting Height Preset"
          variant="outlined"
          value={inputSittingHeight}
          onChange={(e) => setInputSittingHeight(e.target.value)}
          sx={textFieldStyle}
          error={!inRange(inputSittingHeight)}
          required
        />

        <Button
          disabled={
            !inRange(inputStandingHeight) || !inRange(inputSittingHeight)
          }
          onClick={handleSave}
          sx={{ color: "white", width:200 }}
        >
          Save
        </Button>
      </Stack>
    </>
  );
}
