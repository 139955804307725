import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import AdminDeviceModal from "../AdminDeviceModal/AdminDeviceModal";
import { Badge } from "@mui/material";
import WifiIcon from "@mui/icons-material/Wifi";
import AdminEditEmployeeModal from "../AdminEditEmployeeModal/AdminEditEmployeeModal";
import { Button, Stack } from "@mui/material";
import AdminAddEmployeeModal from "../AdminAddEmployeeModal/AdminAddEmployeeModal";

const renderDeviceModal = (cellValues_row, companies) => {
  return <AdminDeviceModal device={cellValues_row} companies={companies} />;
};

const renderEditEmployeeModal = (cellValues_row, companies) => {
  return (
    <AdminEditEmployeeModal employee={cellValues_row} companies={companies} />
  );
};

const renderDeviceStatus = (is_connected) => {
  return (
    <Badge
      badgeContent=" "
      color={is_connected ? "success" : "error"}
      variant="dot"
      sx={{
        width: "1rem",
        height: "1rem",
      }}
    >
      <WifiIcon
        sx={{
          width: "1rem",
          height: "1rem",
        }}
      />
    </Badge>
  );
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({
  devices,
  tablePageSize,
  setTablePageSize,
  companies,
  dashboardUsers,
  teams
}) {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const deviceColumns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "company",
      headerName: "Company",
      width: 90,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "dashboard_user",
      headerName: "User ID",
      width: 90,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "device_name",
      headerName: "Device Name",
      width: 100,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "asset_tag_id",
      headerName: "Device Asset Tag ID",
      width: 100,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "serial_number",
      headerName: "Device Serial Number",
      width: 100,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "is_connected",
      headerName: "Status",
      width: 100,
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (cellValues) =>
        renderDeviceStatus(cellValues.row.is_connected),
    },
    {
      field: "settings",
      headerName: "",
      align: "center",
      className: "styles.settingsBtn",
      width: 200,
      flex: 1,
      renderCell: (cellValues) => renderDeviceModal(cellValues.row, companies),
    },
  ];

  const dashboardUserColumns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "company_name",
      headerName: "Company",
      width: 100,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "first_name",
      headerName: "First Name",
      width: 90,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "last_name",
      headerName: "Last Name",
      width: 90,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      width: 100,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },

    {
      field: "user_type",
      headerName: "User Type",
      width: 100,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "settings",
      headerName: "",
      align: "center",
      className: "styles.settingsBtn",
      width: 200,
      flex: 1,
      renderCell: (cellValues) =>
        renderEditEmployeeModal(cellValues.row, companies),
    },
  ];
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Devices" {...a11yProps(0)} />
          <Tab label="Users" {...a11yProps(1)} />
        </Tabs>
      </Box>
      {/* DEVICES */}
      <TabPanel value={value} index={0}>
        <Box
          width="100%"
          // min height 500px, max height 100%
          minHeight={{ xs: "500px", lg: "600px" }}
          // height="500px"
          sx={{
            // center items and text
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="h3" marginX="auto" mb={2}>
            Devices
          </Typography>
          <DataGrid
            rows={devices}
            columns={deviceColumns}
            pageSize={tablePageSize}
            rowsPerPageOptions={[10, 20, 50]}
            onPageSizeChange={(newPageSize) => setTablePageSize(newPageSize)}
            disableSelectionOnClick
            height="100%"
            sx={{
              // set background color the same as paper
              backgroundColor: (theme) => theme.palette.background.paper,
            }}
          />
        </Box>
      </TabPanel>
      {/* USERS */}
      <TabPanel value={value} index={1}>
        <Box
          width="100%"
          minHeight={{ xs: "500px", lg: "600px" }}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Stack direction="row" spacing={2} mb={2}>
            <Typography variant="h3" marginX="auto" mb={2}>
              Users
            </Typography>
            <AdminAddEmployeeModal companies={companies} teams={teams} />
          </Stack>

          <DataGrid
            rows={dashboardUsers}
            columns={dashboardUserColumns}
            pageSize={tablePageSize}
            rowsPerPageOptions={[10, 20, 50]}
            onPageSizeChange={(newPageSize) => setTablePageSize(newPageSize)}
            disableSelectionOnClick
            height="100%"
            sx={{
              backgroundColor: (theme) => theme.palette.background.paper,
            }}
          />
        </Box>
      </TabPanel>
    </Box>
  );
}
