import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Switch,
  Box,
  Grid,
  TextField,
  Typography,
  FormLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Snackbar,
  Input,
  InputLabel,
} from "@mui/material";

import { useSelector, useDispatch } from "react-redux";
import "../../styles/colours.scss";
import styles from "./AccountInformation.module.scss";
import { fetchCurrentDashboardUser } from "../Reducers/dashboardUserReducer";
import { fetchTeamData } from "../Reducers/teamReducer";
import AuthContext from "../../context/AuthContext";
import ThemeContext from "../../context/ThemeContext";
import useAxios from "../../utils/useAxios";
import { fetchCompanySettings } from "../Reducers/companyReducer";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Stack } from "@mui/material";
import { IconButton } from "@mui/material";
import { InputAdornment } from "@mui/material";
import RequestDeviceModal from "../RequestDeviceModal/RequestDeviceModal";
import UserDeviceDetail from "../UserDeviceDetail/UserDeviceDetail";

export default function AccountInformation({ current_user }) {
  const api = useAxios();
  const { theme, toggleTheme } = useContext(ThemeContext);
  const [iconToUseInToggle, setIconToUseInToggle] = useState(<></>); //really weird...

  const teams = useSelector((state) => state.team.teams); //I could use teams_raw here as well, and that could work better.
  const homeWidgets = useSelector(
    (dashboardStore) => dashboardStore.company.companySettings.home_widgets
  );

  // const deviceList = useSelector(
  //   (dashboardStore) => dashboardStore.device.desk
  // );
  // console.log("deviceList", deviceList);
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [team, setTeam] = useState();
  const [teamName, setTeamName] = useState("");
  const [newPassword, setNewPassword] = useState(""); //new, to hold what the user enters their new password should be now
  const [currentPassword, setCurrentPassword] = useState(""); //new, to hold what the user enters their new password should be now
  const [confirmPassword, setConfirmPassword] = useState("");
  const [publicAccount, setPublicAccount] = useState(
    current_user.privacy === 1 ? true : false
  );
  const [marketingCommunicationsEnabled, setMarketingCommunicationsEnabled] = useState(
    current_user.marketing_communications_enabled //this is just a boolean (true / false)
  );
  const [bikeDataStatus, setBikeDataStatus] = useState(false);
  const [deskDataStatus, setDeskDataStatus] = useState(false);
  const [leaderboardStatus, setLeaderboardStatus] = useState(false);
  const [compareTeamsStatus, setCompareTeamsStatus] = useState(false);
  const [streakStatus, setStreakStatus] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowCurrentPassword = () => {
    setShowCurrentPassword(!showCurrentPassword);
  };

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // Snackbar State
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleSnackbarOpen = () => setSnackbarOpen(true);
  const handleSnackbarClose = () => setSnackbarOpen(false);
  const action = (
    <React.Fragment>
      <Button color="primary" size="small" onClick={handleSnackbarClose}>
        Close
      </Button>
    </React.Fragment>
  );
  const refreshCurrentUser = () => {
    dispatch(fetchCurrentDashboardUser([current_user.id, api]));
  };
  const handlePrivacyChange = (event) => {
    // update privacy to whatever is the opposite of what it is now
    api
      .put(`/dashboard_user/${current_user.id}/`, {
        privacy: publicAccount ? 2 : 1,
      })
      .then((response) => {
        if (response.status < 400) {
          refreshCurrentUser();
        }
      })
      .finally(() => {
        setPublicAccount(current_user.privacy === 1);
      });
  };

  const handleMarketingCommunicationsEnabledToggleChange = (event) => {
    // update marketing_communications_enabled to whatever is the opposite of what it is now
    api
      .put(`/dashboard_user/${current_user.id}/`, {
        marketing_communications_enabled: !marketingCommunicationsEnabled, //flip the boolean value
      })
      .then((response) => {
        if (response.status < 400) {
          refreshCurrentUser();
        }
      })
      .finally(() => {
        setMarketingCommunicationsEnabled(current_user.marketing_communications_enabled);
      });
  };


  // Fetch enabled home widgets

  async function setValues(data) {
    setFirstName(data.first_name);
    setLastName(data.last_name);
    setEmail(data.email);
    setTeam(data.team);
    setTeamName(teams[data.team]);
    setPublicAccount(data.privacy === 1 ? true : false);
    setMarketingCommunicationsEnabled(data.marketing_communications_enabled); //copying this from the data
    setBikeDataStatus(homeWidgets.includes(0));
    setDeskDataStatus(homeWidgets.includes(1));
    setLeaderboardStatus(homeWidgets.includes(2));
    setCompareTeamsStatus(homeWidgets.includes(3));
    setStreakStatus(homeWidgets.includes(4));
  }

  const { user, loading } = useContext(AuthContext);
  const dispatch = useDispatch();
  useEffect(() => {
    if (user) {
      dispatch(fetchCurrentDashboardUser(user.user_id));
      dispatch(fetchCompanySettings([api, user.company]));
      dispatch(fetchTeamData([api, user.company]));
    }
  }, [user]);

  useEffect(() => {
    setValues(current_user, homeWidgets);
    // setPrivacy(current_user.privacy === 2 ? true : false);
  }, [current_user]);

  useEffect(() => {
    let icon_to_use_in_toggle =
      theme == "dark" ? <LightModeIcon /> : <DarkModeIcon />;
    setIconToUseInToggle(icon_to_use_in_toggle);
  }, [theme]);

  const label = { inputProps: { "aria-label": "Color switch demo" } };

  //New handler to change the password once logged in and on the settings page
  const handlePasswordUpdate = async (event) => {
    event.preventDefault();
    try {
      // NOTE : Made a PUT method endpoint in the backend
      const response = await api.put(
        `/dashboard_user/update_password_from_settings_page/`,
        {
          user_id: current_user.id, //I wasn't able to get it to work by sending this in the request params, so I put it in like this
          current_password: currentPassword,
          new_password: newPassword, //this could potentially show issues, when does this get hashed ?
        }
      );
      if (response.status === 200) {
        setSnackbarMessage("Successfully Updated Password");
        handleSnackbarOpen();
      } else {
        setSnackbarMessage("Error Updating Password");
        handleSnackbarOpen();
      }
    } catch (error) {
      setSnackbarMessage("Error Updating Password");
      handleSnackbarOpen();
      console.log(error);
    }
  };

  const handleWidgetUpdate = async (event) => {
    event.preventDefault();

    let updatedHomeWidgets = [];

    if (bikeDataStatus) {
      updatedHomeWidgets.push(0);
    }
    if (deskDataStatus) {
      updatedHomeWidgets.push(1);
    }
    if (leaderboardStatus) {
      updatedHomeWidgets.push(2);
    }
    if (compareTeamsStatus) {
      updatedHomeWidgets.push(3);
    }
    if (streakStatus) {
      updatedHomeWidgets.push(4);
    }

    if (updatedHomeWidgets.length === 0) {
      updatedHomeWidgets = {};
    }

    try {
      const response = await api.post(
        `/company_setting/company/${current_user.company}/`,
        {
          enabled_home_widgets: updatedHomeWidgets,
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const deskList = useSelector((state) => state.device.desks);
  console.log(deskList)
      
  const bikeList = useSelector((state) => state.device.bikes);

  return (
    <Box maxWidth={"sm"} padding={2} marginX="auto">
      <Typography variant="h2" className={styles.header}>
        Account Information
      </Typography>
      <Grid container spacing={4} alignItems="center" justifyContent="center">
        <Grid item xs={6} className={styles.textGrid}>
          <FormLabel>First Name</FormLabel>
          <TextField fullWidth value={firstName} disabled />
        </Grid>
        <Grid item xs={6} className={styles.textGrid}>
          <FormLabel>Last Name</FormLabel>

          <TextField fullWidth value={lastName} disabled />
        </Grid>

        <Grid item xs={12} className={styles.textGrid}>
          <FormLabel>Email</FormLabel>

          <TextField fullWidth value={email} disabled />
        </Grid>

        <Grid item xs={12} className={styles.textGrid}>
          <FormLabel>Team</FormLabel>
          <TextField fullWidth value={teamName} disabled />
        </Grid>

        {/* Assigned Devices and Device Requests */}
        <Grid item xs={12}>
          <Box
            backgroundColor="background.paper"
            padding={2}
            borderRadius={2}
            width="100%"
          >
            <Stack
              direction="column"
              spacing={4}
              alignContent="center"
              maxWidth={"25rem"}
              marginX="auto"
              padding={2}
              borderRadius={2}
            >
              <Typography variant="h5">Your Devices</Typography>

              {/* Map out devices where dashboard_user_id matches current_user.id */}
              {deskList.map((device) => {
                if (device.dashboard_user === current_user.id) {
                  return (
                    <UserDeviceDetail
                      device={device}
                      currentUserId={current_user.id}
                    />
                  );
                } else {
                  return null;
                }
              })}

              {/* Request Device Button */}
              {/* only pass in available devices */}
              <RequestDeviceModal
                deviceList={deskList.filter(
                  (device) => device.dashboard_user === null
                )}
              />
            </Stack>
          </Box>
        </Grid>

        {/* Public Account and Dark Mode */}
        <Grid item xs={12}>
          <Stack
            direction="column"
            spacing={4}
            alignContent="center"
            maxWidth={"15rem"}
            marginX="auto"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flexDirection="row"
            >
              <Typography fontWeight={"bold"}>
                Make My Profile Public
              </Typography>
              <Switch
                {...label}
                checked={publicAccount}
                color="warning"
                onChange={(e) => handlePrivacyChange(e)}
              />
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flexDirection="row"
            >
              <Typography fontWeight={"bold"}>Enable Marketing Communications</Typography>
              <Switch
                {...label}
                checked={marketingCommunicationsEnabled}
                color="warning"
                onChange={(e) => handleMarketingCommunicationsEnabledToggleChange(e)}
              />
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flexDirection="row"
            >
              <Typography fontWeight={"bold"}>Dark Mode</Typography>
              <Button onClick={toggleTheme}>
                {iconToUseInToggle && iconToUseInToggle}
              </Button>
            </Box>
          </Stack>
        </Grid>
      </Grid>
      {/* Spacer */}
      <Box sx={{ height: "50px" }}></Box>

      {/* Reset password */}
      <Box maxWidth={"sm"} marginX="auto" marginTop={5}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h5">Change Password</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack spacing={4} alignContent="center">
              <InputLabel htmlFor="standard-adornment-password">
                Current password
              </InputLabel>
              <Input
                id="standard-adornment-password"
                type={showCurrentPassword ? "text" : "password"}
                value={currentPassword}
                onChange={(e) => {
                  setCurrentPassword(e.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowCurrentPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showCurrentPassword ? (
                        <Visibility fontSize="small" />
                      ) : (
                        <VisibilityOff fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />

              <InputLabel htmlFor="standard-adornment-password">
                New password
              </InputLabel>
              <Input
                id="standard-adornment-password"
                type={showNewPassword ? "text" : "password"}
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowNewPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showNewPassword ? (
                        <Visibility fontSize="small" />
                      ) : (
                        <VisibilityOff fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />

              <InputLabel htmlFor="standard-adornment-password">
                Confirm new password
              </InputLabel>
              <Input
                id="standard-adornment-password"
                type={showConfirmPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showConfirmPassword ? (
                        <Visibility fontSize="small" />
                      ) : (
                        <VisibilityOff fontSize="small" />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "1rem",
                }}
              >
                <Button
                  onClick={handlePasswordUpdate}
                  sx={{
                    color: "white",
                  }}
                >
                  Change Password
                </Button>
              </Box>
            </Stack>

            {/* ADDED THIS IN CASE IT'S NEEDED (A.k.a user -really- needs to change their password, but forgot their current passswords) */}
            {/* NOTE/UPDATE : ISSUE, THE EXPECTED 'FORGOT' PAGE DOES NOT SHOW UP PROPERLY (UNCOMMENT AND TEST) */}

            {/*<Link to="/forgot">
              <Typography
                sx={{
                  marginTop: `1.75rem`,
                  color: `#F79A2B`,
                  fontWeight: `bold`,
                  fontSize: `1.25rem`,
                  textDecoration: `underline #F79A2B`,
                }}
              >
                Forgot Password
              </Typography>
            </Link> */}
          </AccordionDetails>
        </Accordion>
      </Box>

      {/* Company Widgets */}
      {current_user.user_type >= 2 && (
        <Box maxWidth={"sm"} marginX="auto" marginTop={5}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography variant="h5">Company Widgets</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack
                spacing={4}
                alignContent="center"
                maxWidth={"15rem"}
                marginX="auto"
              >
                <Box
                  sx={{
                    display: "flex",
                    direction: "row",
                    // space between the two
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="subtitle1">Bike Data</Typography>

                  <Switch
                    {...label}
                    checked={bikeDataStatus}
                    color="warning"
                    onChange={(e) => setBikeDataStatus(!bikeDataStatus)}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    direction: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="subtitle1">Desk Data</Typography>

                  <Switch
                    {...label}
                    checked={deskDataStatus}
                    color="warning"
                    onChange={(e) => setDeskDataStatus(!deskDataStatus)}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    direction: "row",
                    // space between th
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="subtitle1">Leaderboard</Typography>

                  <Switch
                    {...label}
                    checked={leaderboardStatus}
                    color="warning"
                    onChange={(e) => setLeaderboardStatus(!leaderboardStatus)}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    direction: "row",
                    // space between th
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="subtitle1">Compare Teams</Typography>

                  <Switch
                    {...label}
                    checked={compareTeamsStatus}
                    color="warning"
                    onChange={(e) => setCompareTeamsStatus(!compareTeamsStatus)}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    direction: "row",
                    // space between th
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="subtitle1">Streaks</Typography>

                  <Switch
                    {...label}
                    checked={streakStatus}
                    color="warning"
                    onChange={(e) => setStreakStatus(!streakStatus)}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "1rem",
                  }}
                >
                  <Button
                    size="small"
                    sx={{
                      color: "white",
                    }}
                    onClick={handleWidgetUpdate}
                  >
                    Save Widgets
                  </Button>
                </Box>
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Box>
      )}

      <Box sx={{ height: "50px" }}></Box>

      {/* handle password update */}
      <Box
        sx={{
          maxWidth: "sm",
          marginX: "auto",
        }}
      >
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={4000}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          action={action}
        />
      </Box>
    </Box>
  );
}
