import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchDeskDevices = createAsyncThunk(
  "device/fetchDeskDevices",
  async ([api, company_id]) => {
    let endpoint = `/device/get_desks_by_company/${company_id}/`;
    const response = await api.get(endpoint);
    const data = response.data;
    return data;
  }
);

export const fetchDeviceRequests = createAsyncThunk(
  "device_request/fetchDeviceRequests",
  async ([api, company_id]) => {
    let endpoint = `/device_request/fetch/${company_id}/`;
    const response = await api.get(endpoint);
    const data = response.data;
    return data;
  }
);
export const fetchBikeDevices = createAsyncThunk(
  "device/fetchBikeDevices",
  async ([api, company_id]) => {
    let endpoint = `/device/get_bikes_by_company/${company_id}/`;
    const response = await api.get(endpoint);
    const data = response.data;
    return data;
  }
);

export const fetchAllDevices = createAsyncThunk(
  // This requires superadmin permissions
  "device/fetchAllDevices",
  async (api) => {
    let endpoint = `/device/admin/`;
    const response = await api.get(endpoint);
    const data = response.data;
    return data;
  }
);

export const fetchDeviceStatusList = createAsyncThunk(
  "device/fetchDeviceStatusList",
  async (api) => {
    let endpoint = `/device/connection_status/`;
    const response = await api.get(endpoint);
    const data = response.data;
    return data;
  }
);


const deviceDataSlice = createSlice({
  name: "device",
  initialState: {
    desks: [],
    bikes: [],
    adminDeviceList: [],
    deviceRequests: [],
    device_list_alert_info: { alert_message: "", alert_severity: "" },
    deviceStatusList: [],
  },
  reducers: {
    //this reducer is actually not being used for anything anymore.
    deviceDataFetched(state, action) {
      //encapsulated this in a for loop so that it adds each item in the payload to the state
      state = action.payload;
    },
    updateDeviceListAlertInfo(state, action) {
      state.device_list_alert_info.alert_message = action.payload.alert_message;
      state.device_list_alert_info.alert_severity =
        action.payload.alert_severity;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchDeviceStatusList.pending, (state, action) => {
        //
      })
      .addCase(fetchDeviceStatusList.fulfilled, (state, action) => {
        state.deviceStatusList = action.payload;
      })
      .addCase(fetchDeviceStatusList.rejected, (state, action) => {
        console.log("error in extra reducers (fetchDeviceStatusList)");
      })
      .addCase(fetchDeviceRequests.pending, (state, action) => {
        //
      })
      .addCase(fetchDeviceRequests.fulfilled, (state, action) => {
        state.deviceRequests = action.payload;
      })
      .addCase(fetchDeviceRequests.rejected, (state, action) => {
        console.log("error in extra reducers (fetchDeviceRequests)");
      })
      .addCase(fetchBikeDevices.pending, (state, action) => {
        //
      })
      .addCase(fetchBikeDevices.fulfilled, (state, action) => {
        state.bikes = action.payload;
      })
      .addCase(fetchBikeDevices.rejected, (state, action) => {
        console.log("error in extra reducers (fetchBikeDevices)");
      })
      .addCase(fetchDeskDevices.pending, (state, action) => {
        //
      })
      .addCase(fetchDeskDevices.fulfilled, (state, action) => {
        state.desks = action.payload;
      })
      .addCase(fetchDeskDevices.rejected, (state, action) => {
        console.log("error in extra reducers (fetchDeskDevices)");
      })
      .addCase(fetchAllDevices.pending, (state, action) => {
        //
      })
      .addCase(fetchAllDevices.fulfilled, (state, action) => {
        state.adminDeviceList = action.payload;
      })
      .addCase(fetchAllDevices.rejected, (state, action) => {
        console.log("error in extra reducers (fetchAllDevices)");
      });
  }});
export const { deviceDataFetched, updateDeviceListAlertInfo } =
  deviceDataSlice.actions;
export default deviceDataSlice.reducer;
