import "./TeamList.module.scss";
import { useEffect } from "react";
import {
  DataGrid,
  GridToolbarFilterButton,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import styles from "./TeamList.module.scss";
import "../../styles/colours.scss";
import { useState } from "react";
import {
  Box,
  ThemeProvider,
  createTheme,
  CssBaseline,
  Button,
} from "@mui/material";
import AddTeamModal from "../AddTeamModal/AddTeamModal";
import { useSelector } from "react-redux";
import EditTeamModal from "../EditTeamModal/EditTeamModal";

const renderEditTeamModal = (cellValues_row, employees) => {
  return <EditTeamModal team={cellValues_row} employees={employees} />;
};

// Custom toolbar with the filter option
function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <h1 className={styles.title}>Team List</h1>
      <GridToolbarFilterButton
        variant="outlined"
        sx={{
          borderRadius: "20px",
          margin: "2rem",
          fontSize: "1.2rem",
          fontWeight: "bold",
          textTransform: "none",
          color: "orange",
          borderColor: "orange",
          "&:hover": {
            borderColor: "orange",
          },
        }}
      />

      {
        // Add AddTeamModal component inside a box and push it to the right using sx
      }
      {/* add a box with the AddTeamModal with padding on each component */}

      <Box
        sx={{
          flexGrow: 1,
        }}
      />
      <AddTeamModal />
    </GridToolbarContainer>
  );
}

// DataGrid theme override to orange
const theme = createTheme({
  palette: {
    primary: {
      // not sure how to get scss to work here
      main: "#F7941E",
    },
  },
});

export default function TeamList(props) {
  const [pageSize, setPageSize] = useState(5);

  const teams_raw = useSelector(
    (dashboardStore) => dashboardStore.team.teams_raw
  );

  const employees = useSelector(
    (dashboardStore) => dashboardStore.dashboard_user.dashboard_users
  );

  const [rows, setRows] = useState([]);
  // rows for the dataGrid is teams_raw except each team has a members field
  // num_members = number of employees with a team_id = team.id as an integer
  useEffect(() => {
    const tempRows = teams_raw.map((team) => {
      const num_members = employees.filter(
        (employee) => employee.team === team.id
      ).length;
      return {
        ...team,
        num_members: num_members,
      };
    });
    setRows(tempRows);
    console.log("employees", employees);
  }, [teams_raw, employees]);

  // Columns for the DataGrid
  const columns = [
    {
      field: "id",
      headerName: "TeamID",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "team_name",
      headerName: "Name",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "num_members",
      headerName: "Members",
      headerAlign: "center",
      align: "center",
      flex: 1,
    },
    {
      field: "settings",
      headerName: "",
      align: "center",
      className: "styles.settingsBtn",
      width: 200,
      renderCell: (cellValues) =>
        renderEditTeamModal(cellValues.row, employees),
      flex: 1,
    },
  ];

  return (
    <div className={styles.dataGridContainer}>
      <DataGrid
        // components={{Toolbar: GridToolbarFilterButton}}
        components={{ Toolbar: CustomToolbar }}
        pageSize={pageSize}
        onPageSizeChange={(newPage) => setPageSize(newPage)}
        pagination
        rowsPerPageOptions={[5, 10, 15, 20, 25]}
        rows={rows}
        columns={columns}
        rowHeight={60}
        text
        // override the Filter button innter text from "filters"
        localeText={{
          toolbarFilters: "Filter",
        }}
        sx={{
          border: "none",
          ".MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold",
          },
        }}
      />
    </div>
  );
}
