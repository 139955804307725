import { Alert } from "@mui/material";
import {store as dashboardStore, useSelector} from 'react-redux'

export default function DeviceDetailsAlert(props) {

  const device_list_alert_info = useSelector( dashboardStore => dashboardStore.device.device_list_alert_info)

  return (
    <>
    {device_list_alert_info.alert_message != "" && device_list_alert_info.alert_severity != "" && 
        <Alert severity={device_list_alert_info.alert_severity}>
            {device_list_alert_info.alert_message}
        </Alert>
    }
    </>
  );
}