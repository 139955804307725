import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/system/Unstable_Grid/Grid";
import styles from "./DeskData.module.scss";
import { format } from "date-fns";
import BarChart from "../../BarChart/BarChart";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";


export default function DeskData(props) {
  const [totalMovements, setTotalMovements] = useState();
  const [timeStanding, setTimeStanding] = useState();
  const [averageTimeStanding, setAverageTimeStanding] = useState();
  const [graphData, setGraphData] = useState([]);

  const userDeskAnalytics = useSelector(
    (state) => state.dashboard_user.desk_analytics
  );

  console.log("user desk analytics : ", userDeskAnalytics)

  async function setDeskAnalytics(userDeskAnalytics) {
    // This sets values for the homePage DeskData component
    // This includes the graph and stats for the last 7 days

    // Last 7 days
    const today = new Date();
    const sevenDaysAgo = new Date(today);
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

    console.log("today : ", today)
    console.log("seven days ago : ", sevenDaysAgo)

    // get desk analytics with date >= sevenDaysAgo
    // userDeskAnalytics is an array of objects
    const lastSevenDaysAnalytics = userDeskAnalytics.filter(
      (item) => new Date(item.date) >= sevenDaysAgo
    );

    console.log("lastSevenDaysAnalytics: ", lastSevenDaysAnalytics)

    // sum totalMovements for each item in lastSevenDaysAnalytics
    const totalMovements = lastSevenDaysAnalytics.reduce(
      (a, b) => a + b.movements,
      0
    );
    setTotalMovements(totalMovements);

    // sum timeStanding for each item in lastSevenDaysAnalytics
    const timeStanding = lastSevenDaysAnalytics.reduce(
      (a, b) => a + b.timeStanding,
      0
    );
    setTimeStanding(timeStanding);

    // calculate average timeStanding for each item in lastSevenDaysAnalytics
    const averageTimeStanding =
      timeStanding / lastSevenDaysAnalytics.length || 0;
    setAverageTimeStanding(averageTimeStanding);

    let result = []

    // create graphData by filtering (has a mismatch issue)
    lastSevenDaysAnalytics.forEach( (item ) => {

      let recreatedDate = new Date([item.date, "00:00"])
      let formattedDate = format(recreatedDate, "MMM dd")

      console.log("recreatedDate", recreatedDate)
      console.log("formattedDate: ", formattedDate)

      result.push({ day: formattedDate, timeActive: item.timeStanding })
    });

    console.log("result", result)

    setGraphData(result)

  }

  useEffect(() => {
    if (userDeskAnalytics && userDeskAnalytics.length > 0) {
      setDeskAnalytics(userDeskAnalytics);
    }
  }, [userDeskAnalytics]);

  return (
    <Grid container alignItems="stretch" spacing={2}>{/* EXPERIMENT */}
      {/* Title and date */}
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          alignItems: "center",
          alignContent: "left",
          height: "100%",
        }}
      >
        <Box>
          <Typography variant="h3">Desk Data</Typography>
        </Box>
      </Grid>

      {/* Stats */}
      <Grid item xs={12} xl={3}>
        <Grid container spacing={2}>
          {/* Movements */}
          <Grid
            item
            xs={4}
            xl={12}
            sx={{
              display: "flex",
              alignItems: "center",
              alignContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography variant="h6">Movements</Typography>
            <Box
              sx={{
                display:"flex",
                alignItems:"center",
                alignContent:"left",
                flexDirection: "row"
              }}
              >
            <Typography variant="h3" className={styles.chartValue} mx={1}>
              {Math.round(totalMovements) || 0}
            </Typography>
            </Box>
          </Grid>

          {/* # Devices */}
          <Grid
            item
            xs={4}
            xl={12}
            sx={{
              display: "flex",
              alignItems: "center",
              alignContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography variant="h6">Average</Typography>
            <Box
              sx={{
                display:"flex",
                alignItems:"center",
                alignContent:"left",
                flexDirection: "row"
              }}
              >
            <Typography variant="h3" className={styles.chartValue} mx={1}>
              {Math.round(averageTimeStanding)|| 0}
            </Typography>
            <Typography variant="caption" className={styles.chartValue}>
              min
            </Typography>
            </Box>
            <Typography variant="caption" color={"gray"}>Standing</Typography>
          </Grid>

          {/* Time Standing */}
          <Grid
            item
            xs={4}
            xl={12}
            sx={{
              display: "flex",
              alignItems: "center",
              alignContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography variant="h6">Activity</Typography>
            <Box
              sx={{
                display:"flex",
                alignItems:"center",
                alignContent:"left",
                flexDirection: "row"
              }}
              >
            <Typography variant="h3" className={styles.chartValue} mx={1}>
              {Math.round(timeStanding) || 0}
            </Typography>
            <Typography variant="caption" className={styles.chartValue}>
              min
            </Typography>
            </Box>
            <Typography variant="caption" color={"gray"}>Standing</Typography>
          </Grid>
        </Grid>
      </Grid>

      {/* Chart */}
      <Grid item xs={12} xl={9} sx={{ textAlign: "center" }}>
        {graphData.length > 0 && <BarChart data={graphData} />}
      </Grid>

      {/* Footer/ view data button */}
      <Grid item xs={12} sx={{ textAlign: "right", justifyContent: "center" }}>
        <Button
          size="medium"
          component={Link}
          to="/home/desk"
          sx={{
            color: "white",
            backgroundColor: "orange",
            "&:hover": {
              backgroundColor: "#e2830f",
            },
          }}
        >
          View Data
        </Button>
      </Grid>
      
    </Grid>
  );
}
