// import { Typography } from "@material-ui/core";
import { Typography, Button, Grid, Stack, TextField } from "@mui/material";
import { Container } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/system";
import logo from "../../assets/images/ergonomyx_logo.png";
import { useHistory } from "react-router-dom";
import axios from "axios";
export default function ResetPasswordForm() {
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [status, setStatus] = useState(null);

  let params = useParams();
  let auth_code = params.authcode;
  let history = useHistory(); //used for redirect

  //tough one to refactor. not sure if this is going to be enough...
  //the old version had redirects and stuff baked into it
  const handleSubmit = (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setStatus("no_match");
    } else {
      axios.get(`${process.env.REACT_APP_DASHBOARD_API_URL}/authorization_code/get_user_id/${auth_code}/`)
        .then((response) => {
          axios.post(`${process.env.REACT_APP_DASHBOARD_API_URL}/dashboard_user/reset_password_from_user_id/`, {
            auth_code: auth_code,
            user_id:  response.data.dashboard_user_id,
            newPassword: newPassword,
          })
            .then((response) => {
              setStatus("success");
              console.log("successfully reset password from user id");

              //possibly perform the redirection stuff here ... look into old code to find this
              setTimeout(() => {
                history.push("/login");
              }, 3000);

            }
            )
            .catch((error) => {
              setStatus("error");
              console.log(error);
            }
            );
        })
        .catch((error) => {
          setStatus("error");
          console.log(error);
        }
        );
    }
  };

  return (
    <>
      <Container
        maxWidth="xs"
        // expands to fill parent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <form onSubmit={handleSubmit}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
            display="flex"
          >
            <img src={logo} alt="Ergo Logo" />
            <Grid item xs={12}>
              <Typography variant="h4" align="center">
                Reset Password
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="New Password"
                variant="outlined"
                required
                autoComplete="off"
                type="password"
                onChange={(e) => setNewPassword(e.target.value)}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Confirm Password"
                variant="outlined"
                required
                autoComplete="off"
                type="password"
                onChange={(e) => setConfirmPassword(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Button variant="contained" type="submit">
                Submit
              </Button>
            </Grid>
            {/* status message box */}
            <Grid item xs={12}>
              <Box
                // background is green if status='success', red if status='error'
                sx={{
                  bgcolor:
                    status === "success"
                      ? "success.main"
                      : status === "error" ||
                        status === "expired" ||
                        status === "no_match" ||
                        status === "invalid_code"
                      ? "error.main"
                      : "background.white",
                  color: "white",
                  borderRadius: "5px",
                  padding: "7px",
                }}
              >
                {status === "success"
                  ? "Password Successfully Changed. Rerouting you to login in 3 seconds."
                  : null}
                { (status === "success") && 
                  <p>
                    If not redirected, please 
                    <span>
                      <Typography>
                        <a href={"/login"}>
                          click here!
                        </a>
                      </Typography>
                    </span>
                  </p>
                }
                {status === "error"
                  ? "Error - Unable to Change Password."
                  : null}
                {status === "no_match" ? "Passwords do not match." : null}
                {status === "invalid_code"
                  ? "The Authorization Code is invalid."
                  : null}
                {status === "expired"
                  ? "The Authorization Code is expired. Please Contact your Administrator to be sent a new one."
                  : null}
              </Box>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
}
