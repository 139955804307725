import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchCompanyData = createAsyncThunk(
  "company/fetchCompanyData",
  async ([api, company_id]) => {
    let endpoint = `/company/${company_id}`
    const response = await api.get(endpoint);
    const data = response.data    
    return data;
  }
);

export const fetchAllCompanies = createAsyncThunk(
  "company/fetchAllCompanies",
  async (api) => {
    let endpoint = `/company/admin/`
    const response = await api.get(endpoint);
    const data = response.data
    return data;
  }
);

export const fetchCompanySettings = createAsyncThunk(
  "company/fetchCompanySettings",
  async ([api, company_id]) => {

    let endpoint = `/company_setting/company/${company_id}/`
    const response = await api.get(endpoint);
    const data = response.data    
    
    // if data.detail says "Not found" then we return an an array where all challenges are enabled.
    // This will have to be changed in the API - bryce
    // instead of api returning "not found" on no entry, it should return this array
    if (data.detail === "Not found.") {
      return {"enabled_challenge_groups":[0,1,2,3,4,5],"enabled_home_widgets":[0,1,2,3]};
    }

    return data;
  }
);

export const updateCompanyChallengeGroups = createAsyncThunk(
  "company/updateCompanyChallengeGroups",
  async ([api, company_id, challenge_groups]) => {
    let endpoint = `/company_setting/company/${company_id}/`
    const response = await api.post(endpoint, {
      enabled_challenge_groups: challenge_groups,
    }
    );
    const data = response.data
    return data;
  }
);

const companyDataSlice = createSlice({
  name: 'company',
  initialState: {adminCompanyList:[], companies:{}, companySettings:{"challenges":[], "home_widgets":[]}, tempChallenges:[], pendingChanges:false},
  reducers: {
    companyDataFetched(state, action) {
      state.push({
        id: action.payload.id,
        company_name : action.payload.company_name,
      })
    },
    setTempChallenges(state, action) {
      state.tempChallenges = action.payload
    },
    setPendingChanges(state, action) {
      state.pendingChanges = action.payload;
    },
    resetTempChallenges(state, action) {
      state.tempChallenges = state.companySettings['challenges']
    },
    toggleChallenge(state, action) {
      // if the challenge is in the tempChallenges array, remove it
      if (state.tempChallenges.includes(action.payload)) {
        state.tempChallenges = state.tempChallenges.filter((challenge) => challenge !== action.payload)
      } else {
        // if the challenge is not in the tempChallenges array, add it
        state.tempChallenges.push(action.payload)
      }
    },
  },
    extraReducers(builder) {
      builder
        .addCase(updateCompanyChallengeGroups.pending, (state, action) => {
          //
        })
        .addCase(updateCompanyChallengeGroups.fulfilled, (state, action) => {
          state.companySettings['challenges'] = action.payload.enabled_challenge_groups;
          state.tempChallenges = action.payload.enabled_challenge_groups
          state.pendingChanges = false
        })
        .addCase(fetchCompanyData.pending, (state, action) => {
          //
        })
        .addCase(fetchCompanyData.fulfilled, (state, action) => {
          state.companies = action.payload;
        })
        .addCase(fetchCompanyData.rejected, (state, action) => {
          console.log("error in extra reducers (fetchCompanyData users)");
        })
        .addCase(fetchCompanySettings.pending, (state, action) => {
          //
        })
        .addCase(fetchCompanySettings.fulfilled, (state, action) => {
          state.companySettings['challenges'] = action.payload.enabled_challenge_groups;
          state.companySettings['home_widgets']= action.payload.enabled_home_widgets;
          state.tempChallenges = action.payload.enabled_challenge_groups
        })
        .addCase(fetchCompanySettings.rejected, (state, action) => {
          console.log("error in extra reducers (fetchCompanySettings users)");
        })
        .addCase(fetchAllCompanies.pending, (state, action) => {
          //
        })
        .addCase(fetchAllCompanies.fulfilled, (state, action) => {
          state.adminCompanyList = action.payload;
        })
        .addCase(fetchAllCompanies.rejected, (state, action) => {
          console.log("error in extra reducers (fetchAllCompanies users)");
        });
    },
  
});

export const { companyDataFetched } = companyDataSlice.actions;
export default companyDataSlice.reducer;