import Appbar from "../components/Appbar/Appbar";
import styles from "../styles/home.scss";
import ExportData from "../components/HomeScreenViews/ExportData/ExportData";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { Box } from "@mui/system";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

//removed fetch & replaced w/ fetchfunctions for exportReducer
import {fetchDeskExportWithOptions, fetchBikeExportWithOptions} from '../components/Reducers/exportReducer'

import { useSelector, useDispatch } from "react-redux";
import useAxios from "../utils/useAxios";
import { useEffect } from "react";
import AuthContext from "../context/AuthContext";
import { useContext } from "react";
import ExportDataWithBackend from "../components/HomeScreenViews/ExportData/ExportDataWithBackend.js";
// import ExporDataNew from "../components/HomeScreenViews/ExportData/ExportDataNew";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  borderRadius: "25px",
  color: theme.palette.text.secondary,
}));

const ExportDataPage = () => {
  const api = useAxios();
  const { loading, user } = useContext(AuthContext);
  const dispatch = useDispatch();
  useEffect(() => {
    if (user) {
      dispatch(fetchDeskExportWithOptions([ user.company, api]));
      dispatch(fetchBikeExportWithOptions([ user.company, api]));
    }
  }, [user]);

  return (
    <div>
      {/* <Appbar menuItems={menuItems} page="Company Profile" /> */}
      <Box
        sx={{
          flexGrow: 1,
          height: "100%",
          width: "100%",
        }}
      >
        <Grid
          container
          rowSpacing={2}
          columnSpacing={1}
          sx={{ mt: 0.3, ml: 1, mr: 1 }}
        >
          {/* <Grid item xs={12}>
            <Item>
              <ExportData />
            </Item>
          </Grid> */}
          <Grid item xs={12}>
            <Item>
              <ExportDataWithBackend />
              {/* <ExporDataNew/> */}
            </Item>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default ExportDataPage;
