import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/system/Unstable_Grid/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import { useState, useRef } from "react";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import { FormControl, RadioGroup, Select, Typography } from "@mui/material";

export default function ExportDataWithBackend(props) {
  const [selectedDevice, setSelectedDevice] = useState("bike");
  const csvLink = useRef();

  const deskData = useSelector(
    (state) => state.csv_export.desk_export
  );
  const bikeData = useSelector(
    (state) => state.csv_export.bike_export
  );
  const [exportData, setExportData] = useState([]);

  // CheckBox Values
  const deskTime = useRef(false);
  const deskTimeTotal = useRef(false);
  const deskMovements = useRef(false);
  const bikeCals = useRef(true);
  const bikeActive = useRef(true);
  const bikeDistance = useRef(true);
  const bikePower = useRef(true);

  //   Bike checkbox

  // Reset Values
  function resetFilters() {
    console.log("RESET");
    deskTime.current = false;
    deskTimeTotal.current = false;
    deskMovements.current = false;
    bikeCals.current = false;
    bikeActive.current = false;
    bikeDistance.current = false;
    bikePower.current = false;
    setExportData("");
  }
  function setDeskExportData() {
    bikeCals.current = false;
    bikeActive.current = false;
    bikeDistance.current = false;
    bikePower.current = false;
  
    setExportData(
      deskData.map((item) => {
        const exportItem = {
          id: item.id,
          user_id: item.user_id,
          device: item.device,
          timestamp: item.timestamp,
          user_firstname : item.user_firstname,
          user_lastname : item.user_lastname,
          device_name :item.device_name,
          device_serial_number :item.device_serial_number,
          device_asset_tag_id : item.device_asset_tag_id
        };
  
        if (deskTime.current) {
          exportItem.time = item.time;
        }
        if (deskTimeTotal.current) {
          exportItem.total_time = item.time_total;
        }
        if (deskMovements.current) {
          exportItem.movements = item.movements;
        }
  
        return exportItem;
      })
    );
  }
  

  function setBikeExportData() {
    deskTime.current = false;
    deskTimeTotal.current = false;
    deskMovements.current = false;

    setExportData(
      bikeData.map((item) => {
        const exportItem = {
          id: item.id,
          user_id: item.dashboard_user,
          device: item.device,
          timestamp: item.date,
          user_firstname : item.user_firstname,
          user_lastname : item.user_lastname,
          device_name :item.device_name,
          device_serial_number :item.device_serial_number,
          device_asset_tag_id : item.device_asset_tag_id
        };

        if (bikePower.current) {
          exportItem.power = item.power;
        }
        if (bikeActive.current) {
          exportItem.time_biking = item.time_bike;
        }
        if (bikeDistance.current) {
          exportItem.distance = item.distance;
        }
        if (bikeCals.current) {
          exportItem.calories = item.calories;
        }

        return exportItem;
      })
    );
  }

  async function handleCSVExport() {
    csvLink.current.link.click();
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        textAlign: "left",
      }}
    >
      <Grid
        container
        rowSpacing={1}
        columnSpacing={2}
        sx={{ mt: 2, ml: 1, mr: 1 }}
      >
        {/* Header */}
        <Grid item xs={12}>
          <Typography variant="h5" component="h2" gutterBottom>
            Export data to CSV
          </Typography>
        </Grid>
        {/* Form */}
        <Grid item xs={12} p={3}>
          <FormControl
            component="fieldset"
            sx={{
              width: "100%",
              //   maxWidth = md
              maxWidth: "500px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* Data type selector (bike/desk)  */}
            <FormGroup
              sx={{
                marginBottom: "1rem",
              }}
            >
              <FormLabel component="legend">Data type</FormLabel>
              <Select
                native
                value={selectedDevice}
                onChange={(e) => {
                  setSelectedDevice(e.target.value);
                  if (e.target.value === "desk") {
                    setDeskExportData();
                  } else {
                    setBikeExportData();
                  }
                }}
              >
                <option value="bike">Bike</option>
                <option value="desk">Desk</option>
              </Select>
            </FormGroup>
            {/* Additional fields */}
            {selectedDevice === "desk" ? (
              <RadioGroup>
                <FormControlLabel
                  value="bike"
                  control={
                    <Checkbox
                      checked={deskTime.current}
                      onChange={() => {
                        const temp = deskTime.current;
                        deskTime.current = !temp;
                        setDeskExportData();
                      }}
                    />
                  }
                  label="Standing Time Per Hour"
                />
                <FormControlLabel
                  value="desk"
                  control={
                    <Checkbox
                      checked={deskTimeTotal.current}
                      onChange={() => {
                        const temp = deskTimeTotal.current;
                        deskTimeTotal.current = !temp;
                        setDeskExportData();
                      }}
                    />
                  }
                  label="Total Time"
                />
                <FormControlLabel
                  value="desk"
                  control={
                    <Checkbox
                      checked={deskMovements.current}
                      onChange={() => {
                        const temp = deskMovements.current;
                        deskMovements.current = !temp;
                        setDeskExportData();
                      }}
                    />
                  }
                  label="Active Movements"
                />
              </RadioGroup>
            ) : (
              <RadioGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={bikeCals.current}
                      onChange={() => {
                        const temp = bikeCals.current;
                        bikeCals.current = !temp;
                        setBikeExportData();
                      }}
                    />
                  }
                  label="Calories Burned"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={bikeActive.current}
                      onChange={() => {
                        const temp = bikeActive.current;
                        bikeActive.current = !temp;
                        setBikeExportData();
                      }}
                    />
                  }
                  label="Active Time"
                />
                <FormControlLabel
                  value="desk"
                  control={
                    <Checkbox
                      checked={bikeDistance.current}
                      onChange={() => {
                        const temp = bikeDistance.current;
                        bikeDistance.current = !temp;
                        setBikeExportData();
                      }}
                    />
                  }
                  label="Distance Biked"
                />
                <FormControlLabel
                  value="desk"
                  control={
                    <Checkbox
                      checked={bikePower.current}
                      onChange={() => {
                        const temp = bikePower.current;
                        bikePower.current = !temp;
                        setBikeExportData();
                      }}
                    />
                  }
                  label="Power Generated"
                />
              </RadioGroup>
            )}

            {/* Export button */}
            <Button
              variant="contained"
              sx={{
                width: "100px",
                marginTop: "1rem",
              }}
              onClick={handleCSVExport}
              disabled={
                selectedDevice === "desk" ? (
                  deskData.length === 0
                ) : (
                  bikeData.length === 0
                ) 
              }
            >
              Export
            </Button>
            <CSVLink
              data={exportData}
              filename="export_data.csv"
              className="hidden"
              ref={csvLink}
              target="_blank"
            />
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
}
