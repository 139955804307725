import React, { useEffect, useState } from 'react'
import {
    DataGrid,
    GridToolbarFilterButton,
    GridToolbarContainer,
  } from "@mui/x-data-grid";
import styles from './BikeDataTable.module.scss';
import { CssBaseline, Typography } from "@mui/material";
import { useSelector } from 'react-redux'
import { borderColor } from '@mui/system';

const dayInMonthComparator = (v1, v2) => {
  // date in in the format MM/DD/YYYY
    const date1 = new Date(v1);
    const date2 = new Date(v2);
    const day1 = date1.getDate();
    const day2 = date2.getDate();
    const month1 = date1.getMonth();
    const month2 = date2.getMonth();
    const year1 = date1.getFullYear();
    const year2 = date2.getFullYear();

    if (year1 < year2) {
      return -1;
    }
    if (year1 > year2) {
      return 1;
    }
    if (month1 < month2) {
      return -1;
    }
    if (month1 > month2) {
      return 1;
    }
    if (day1 < day2) {
      return -1;
    }
    if (day1 > day2) {
      return 1;
    }
    return 0;
}


const columns = [
    {
      field: "date",
      headerName: "Date",
      width: 100,
      headerAlign: "center",
      align: "center",
      flex: 1,
      valueGetter: (params) => params.row.date,
      type: "date",
      sortComparator: dayInMonthComparator
    },
    {
      field: "calories",
      headerName: "Calories",
      width: 100,
      headerAlign: "center",
      align: "center",
      flex: 1
    },
    {
      field: "time_bike",
      headerName: "Time Biking",
      width: 100,
      headerAlign: "center",
      align: "center",
      flex: 1
    },
  
    {
      field: "distance",
      headerName: "Distance (km)",
      width: 100,
      headerAlign: "center",
      align: "center",
      flex: 1
    },
    {
        field: "power",
        headerName: "Power (mWh)",
        width: 100,
        headerAlign: "center",
        align: "center",
        flex: 1
      },
  ];

function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <Typography variant='h2'>Bike Data Table</Typography>
        <GridToolbarFilterButton
          variant="outlined"
          sx={{
            borderRadius: "20px",
            margin: "2rem",
            fontSize: "1.2rem",
            fontWeight: "bold",
            textTransform: "none",
            color: "orange",
            borderColor: "orange",
            "&:hover" : {
              borderColor: "orange",
            }
          }}
        />
      </GridToolbarContainer>
    );
  }
export default function BikeDataTable() {
  const [pageSize, setPageSize] = useState(25);

  const hourly_bike_upserts = useSelector(dashboardStore => dashboardStore.hourly_bike_upsert.hourly_bike_upserts)
  const [rows, setRows] = useState([])
  // Whenever hourly_bike_upserts changes, this useEffect will run
  useEffect(() => {
    if (hourly_bike_upserts.length > 0) {
      // for each hourly bike upsert, format the date, and distance
      let formatted_rows = hourly_bike_upserts.map((upsert) => {
        let date = new Date(upsert.date)
        // date = MM/DD/YYYY
        date = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
        let distance = upsert.distance / 1000
        return {
          id: upsert.id,
          date: date,
          calories: upsert.calories,
          time_bike: upsert.time_bike,
          distance: distance.toFixed(1),
          power: upsert.power
        }
      })
      console.log("formatted_rows", formatted_rows)
      setRows(formatted_rows)
    }
  }, [hourly_bike_upserts])
  
  return (
    <>
    <div className={styles.dataGridContainer}>
        <CssBaseline />
        {rows.length > 0 && <DataGrid
          components={{ Toolbar: CustomToolbar }}
          pageSize={pageSize}
          onPageSizeChange={(newPage) => setPageSize(newPage)}
          pagination
          rowsPerPageOptions={[5, 10, 15, 20, 25]}
          checkboxSelection
          columns={columns}
          rows={rows}
          rowHeight={60}
          text
          // override the Filter button innter text from "filters"
          localeText={{
            toolbarFilters: "Filter",
          }}
          initialState={{
            ...rows.initialState,
            sorting: {
              ...rows.initialState?.storing,
              sortModel: [
                {
                  field: "date",
                  sort: "desc",
                }
              ],
            },
            }}
          sx={{
            "border": "none",
            ".MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold",
              fontSize: "1.2rem"
            },
          }}
        />}
      </div>
    </>
  )
}