import React, { useState, useEffect, useContext } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  House,
  Settings,
  Badge,
  SmartToy,
  StarsRounded,
  Landscape,
  SwapVerticalCircle
} from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { BadgeRoot, Button, Hidden } from "@mui/material";
import styles from "./PersistentDrawer.module.scss";
import logo from "../../assets/images/ergonomyx_logo.png";
import { Link } from "react-router-dom";
import { Container, Avatar } from "@mui/material";
import { useSelector } from "react-redux";
import AuthContext from "../../context/AuthContext";
import BetaPill from "../../components/BetaPill/BetaPill"

// let menuItemsToUse = null;

let menuItemsCompanyAdmin = [
  {
    text: "Home",
    icon: (
      <House
        sx={{ color: "white", fontSize: "large", margin: 0, padding: 0 }}
      />
    ),
    path: "/home",
    subPages: [
      {
        text: "Analytics",
        path: "/home",
        paths: ["/home", "/home/desk", "/home/bike", "/home/leaderboard"],
      },
      {
        text: "Export",
        path: "/home/export",
        paths: ["/home/export"],
      },
    ],
    paths: [
      "/home",
      "/home/export",
      "/home/leaderboard",
      "/home/bike",
      "/home/desk",
    ],
  },
  {
    text: "Employees",
    icon: (
      <Badge
        sx={{ color: "white", fontSize: "large", margin: 0, padding: 0 }}
      />
    ),
    path: "/employees",
    paths: ["/employees", "/employees/teams"],
    subPages: [
      {
        text: "Employees",
        path: "/employees",
        paths: ["/employees"],
      },
      {
        text: "Teams",
        path: "/employees/teams",
        paths: ["/employees/teams"],
      },
    ],
  },
  {
    text: "Challenges",
    icon: (
      <Landscape
        sx={{ color: "white", fontSize: "large", margin: 0, padding: 0 }}
      />
    ),
    path: "/manageChallenges",
    paths: ["/manageChallenges"],
    subPages: [
      {
        text: "Challenges",
        path: "/manageChallenges",
        paths: ["/manageChallenges"],
      },
    ],
  },
  {
    text: "Device List",
    icon: (
      <SmartToy
        sx={{ color: "white", fontSize: "large", margin: 0, padding: 0 }}
      />
    ),
    path: "/devices/desk",
    paths: ["/devices/desk", "/devices/bike", "/devices/status"],
    subPages: [
      {
        text: "Desk",
        path: "/devices/desk",
        paths: ["/devices/desk"],
      },
      {
        text: "Bike",
        path: "/devices/bike",
        paths: ["/devices/bike"],
      },
      {
        text: "Status",
        path: "/devices/status",
        paths: ["/devices/status"],
      }
    ],
  },
  {
    text: "Settings",
    icon: (
      <Settings
        sx={{ color: "white", fontSize: "large", margin: 0, padding: 0 }}
      />
    ),
    path: "/settings/account",
    paths: ["/settings/account", "/settings/company"],
    subPages: [
      {
        text: "Account",
        path: "/settings/account",
        paths: ["/settings/account"],
      },
      {
        text: "Company",
        path: "/settings/company",
        paths: ["/settings/company"],
      },
    ],
  },
  {
    text: "Control Your Desk",
    icon: (
      <SwapVerticalCircle
        sx={{ color: "white", fontSize: "large", margin: 0, padding: 0 }}
      />
    ),
    path: "/control_desk",
    paths: ["/control_desk"],
    subPages: [
      {
        text: "Move Desk",
        path: "/control_desk",
        paths: ["/control_desk"],
      }
    ]
  },
  {
    text: "Routines",
    icon: (
      <Landscape
        sx={{ color: "white", fontSize: "large", margin: 0, padding: 0 }}
      />
    ),
    path: "/routines",
    paths: ["/routines"],
    subPages: [
      {
        text: "Routines",
        path: "/routines",
        paths: ["/routines"],
      },
    ],
  },
];
// menuItemsSuperAdmin is the same as menuItemsCompanyAdmin, we just add a new item to the end of the array
let menuItemsSuperAdmin = [
  ...menuItemsCompanyAdmin,
  {
    text: "Admin",
    icon: (
      <Settings
        sx={{ color: "white", fontSize: "large", margin: 0, padding: 0 }}
      />
    ),
    path: "/admin",
    paths: ["/admin"],
    subPages: [
      {
        text: "Admin",
        path: "/admin",
        paths: ["/admin"],
      },
    ],
  },
];


let menuItemsTeamMember = [
  {
    text: "Home",
    icon: (
      <House
        sx={{ color: "white", fontSize: "large", margin: 0, padding: 0 }}
      />
    ),
    path: "/home",
    subPages: [
      {
        text: "Home",
        path: "/home",
        paths: ["/home", "/home/desk", "/home/bike"],
      },
      {
        text: "Leaderboard",
        path: "/home/leaderboard",
        paths: ["/home/leaderboard"],
      },
    ],
    paths: ["/home", "/home/leaderboard", "/home/bike", "/home/desk"],
  },
  {
    text: "Rewards",
    icon: (
      <StarsRounded
        sx={{ color: "white", fontSize: "large", margin: 0, padding: 0 }}
      />
    ),
    path: "/rewards",
    paths: ["/rewards"],
    subPages: [
      {
        text: "Rewards",
        path: "/rewards",
        paths: ["/rewards"],
      },
    ],
  },
  {
    text: "Challenges",
    icon: (
      <Landscape
        sx={{ color: "white", fontSize: "large", margin: 0, padding: 0 }}
      />
    ),
    path: "/challenges",
    paths: ["/challenges"],
    subPages: [
      {
        text: "Challenges",
        path: "/challenges",
        paths: ["/challenges"],
      },
    ],
  },
  {
    text: "Settings",
    icon: (
      <Settings
        sx={{ color: "white", fontSize: "large", margin: 0, padding: 0 }}
      />
    ),
    path: "/settings/account",
    paths: ["/settings/account", "/settings/company"],
    subPages: [
      {
        text: "Account",
        path: "/settings/account",
        paths: ["/settings/account"],
      },
      {
        text: "Company",
        path: "/settings/company",
        paths: ["/settings/company"],
      },
    ],
  },
  {
    text: "Control Your Desk",
    icon: (
      <SwapVerticalCircle
        sx={{ color: "white", fontSize: "large", margin: 0, padding: 0 }}
      />
    ),
    path: "/control_desk",
    paths: ["/control_desk"],
    subPages: [
      {
        text: "Move Desk",
        path: "/control_desk",
        paths: ["/control_desk"],
      }
    ]
  },
  {
    text: "Routines",
    icon: (
      <Landscape
        sx={{ color: "white", fontSize: "large", margin: 0, padding: 0 }}
      />
    ),
    path: "/routines",
    paths: ["/routines"],
    subPages: [
      {
        text: "Routines",
        path: "/routines",
        paths: ["/routines"],
      },
    ],
  },
  {
    text: "My Profile",
    icon: (
      <Settings
        sx={{ color: "white", fontSize: "large", margin: 0, padding: 0 }}
      />
    ),
    path: "/profile",
    paths: ["/profile"],
    subPages: [
      {
        text: "Home",
        path: "/profile",
        paths: ["/profile"],
      }
    ]
  },
];

let drawerWidth = 240;

if (window.innerWidth <= 768) {
  drawerWidth = window.innerWidth;
}

const headerHeight = 100;

// Main content is pushed to the right when the drawer is open
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1, // sets background color for content
    height: "100%",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  height: headerHeight,
  backgroundColor: theme.palette.background.paper,
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    height: headerHeight, // app bar height
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
  height: headerHeight, // app bar height
}));

export default function PersistentDrawer({ children }) {
  const user = useContext(AuthContext);

  const handleLogout = (e) => {
    user.logoutUser();
  };
  const theme = useTheme();

  const history = useHistory();
  const location = useLocation();
  const [currPage, setCurrPage] = React.useState(location.pathname);
  const [menuItemsToUse, setMenuItemsToUse] = React.useState([])//initialize empty, change based on authcontext (user.user_type)

  // This is no longer necessary if the AuthContext is being used ... (useSelector to get the current user)
  const current_user = useSelector(
    (dashboardStore) => dashboardStore.dashboard_user.current_dashboard_user
  );

  // const username = current_user.username;

  // open is true by default unless the screen is too small
  const [open, setOpen] = React.useState(
    window.innerWidth > 768 ? true : false
  );

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  //refactored this as a useEffect instead, depending on the user in the context
  useEffect(() => {
    if (user.user.user_type === 3) { //1 is team member //2 is company admin
      setMenuItemsToUse(menuItemsSuperAdmin)
    } else if (user.user.user_type === 2) {
      setMenuItemsToUse(menuItemsCompanyAdmin)
    } else {
      setMenuItemsToUse(menuItemsTeamMember)
    }
  }, [user]);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  return (
    <Box sx={{ display: "flex", flexDirection: "row", flex: 1 }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Box
          sx={{
            flexGrow: 4,
            flexDirection: "row",
            display: "flex",
            width: "100%",
          }}
        >
          <Toolbar
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <MenuIcon />
            </IconButton>

            {/* Appbar Title for current page */}
            {/* <Typography
              variant="h6"
              component="div"
              sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                width: "auto",
              }}
            >
              {
                menuItemsToUse.find((menuItem) =>
                  menuItem.paths.includes(location.pathname)
                ).text
              }
            </Typography> */}

            {/* Create a box to equally space all the menu items across the appbar */}
            <Box
              sx={{
                flexGrow: 1,
                flexDirection: "row",
                display: "flex",
                width: "100%",
              }}
            >
              {/* we only want to find on menuItemsToUse if it's not null */}

              {menuItemsToUse &&
                menuItemsToUse.find((menuItem) =>
                  menuItem.paths.includes(location.pathname)
                )?.subPages &&
                menuItemsToUse
                  .find((menuItem) =>
                    menuItem.paths.includes(location.pathname)
                  )
                  .subPages.map((subPage) => (
                    <Button
                      key={subPage.text}
                      onClick={() => history.push(subPage.path)}
                      sx={{
                        padding: `15px 30px`,
                        display: `block`,
                        backgroundColor: `transparent`,
                        "&:hover" : {
                            backgroundColor: `transparent`,
                        },
                        boxShadow: `none`,
                        textTransform: `none`,
                        borderRadius: `0`,
                        width: `100%`, // this will change the size of the buttons on appbar
                      }}
                      className={
                        subPage.paths.includes(location.pathname)
                          ? styles.appBarBtnActive
                          : styles.appBarBtn
                      }
                    >
                      <Typography
                        variant="h6"
                        {...(subPage.paths.includes(location.pathname) ? {
                          color: "primary.main",
                          fontWeight: "bold"
                        } : {
                          color: "text.primary"
                        }
        
                      

                      )}
                      >
                        {subPage.text}
                      </Typography>
                    </Button>
                  ))}
            </Box>
          </Toolbar>
        </Box>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            color: "white",
            backgroundColor: "black",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          {/* WE WANT TO CENTER THIS  */}
          {/* Create a box that centers it's content */}
          <Container>
            {/* Close Drawer Button */}
            <IconButton
              onClick={handleDrawerClose}
              // fix this so it's not affected by the username
              sx={{ position: "absolute", left: "5px", top: headerHeight / 5 }}
            >
              {isMobile && <CloseIcon sx={{ color: "white" }} />}
              <Box sx={{ padding: 3 }} />
            </IconButton>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                marginTop: 2,
              }}
            >
              <Link to="/home">
                <img src={logo} alt="" className={styles.ergoImage} />
              </Link>
              <Typography variant="h6" noWrap component="div">
                {user.user.first_name}
              </Typography>
            </Box>
          </Container>
          {/* Close Drawer Button */}
          {/* <IconButton
            onClick={handleDrawerClose}
            // fix this so it's not affected by the username
            sx={{ position: "absolute", right: "-30px", top: headerHeight / 3 }}
          >
            {window.innerWidth <= 600 ? (
              <CloseIcon sx={{ color: "white", display: "" }} />
            ) : (
              null
            )}
            <Box sx={{padding: 3}}/>
          </IconButton> */}
        </DrawerHeader>

        <List>
          {menuItemsToUse.map((item, index) => (
            <ListItem
              button
              key={item.text}
              onClick={() => {
                history.push(item.path);
                setCurrPage(item.path);
                if(isMobile){handleDrawerClose()};//adding this to see if it closes the drawer upon clicking list item on mobile
              }}
              // if is it the currently selected page, set color to orange
              sx={{
                backgroundColor: currPage === item.path ? "orange" : "black",
                "&:hover": {
                  backgroundColor: currPage === item.path ? "orange" : "black",
                },
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
          {/* Logout button */}
          <ListItem
            button
            key={"Logout"}
            onClick={() => {
              handleLogout();
            }}
          >
            <ListItemIcon>
              <LogoutIcon
                sx={{
                  color: "white",
                  fontSize: "large",
                  margin: 0,
                  padding: 0,
                }}
              />
            </ListItemIcon>
            <ListItemText primary={"Logout"} />
          </ListItem>
        </List>
        
        <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          // height: "100%",
          marginTop: 2,
        }}
        >
          <BetaPill/>
        </Box>

      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        {children}{/* [children, <BetaPill/>] */}
      </Main>
    </Box>
  );
}
