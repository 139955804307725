import Appbar from '../components/Appbar/Appbar'
import ErgoAreaChart from '../components/ErgoAreaChart/ErgoAreaChart';
const Team = () => {
  return (
    <div>
      {/* <Appbar/> */}
      <ErgoAreaChart/>
      <h1>You are on team page!</h1>
      <h1>You are on team page!</h1>
    </div>
  );
};

export default Team;