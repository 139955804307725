import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

//to use this, you'll have to do 
// dispatch(fetchStreakData(dashboard_user.id, api))
export const fetchStreakData = createAsyncThunk(
  "streak/fetchStreakData",
  async ([dashboard_user_id, api]) => {
    let get_streaks_by_dashboard_user = `/streak/get_by_dashboard_user/${dashboard_user_id}`;
    const response = await api.get(get_streaks_by_dashboard_user);
    const data = response.data
    return data;
  }
);


const streakDataSlice = createSlice({
  name: 'streak',
  initialState: {streaks:{}},
  reducers: {
    streakDataFetched(state, action) {
      state.push({
        id: action.payload.id,
        streak_name : action.payload.streak_name,
      })
    },
  },
    extraReducers(builder) {
      builder
        .addCase(fetchStreakData.pending, (state, action) => {
          //
        })
        .addCase(fetchStreakData.fulfilled, (state, action) => {
          state.streaks = action.payload;
        })
        .addCase(fetchStreakData.rejected, (state, action) => {
          console.log("error in extra reducers (fetchStreakData)");
        });
    },
  
});

export const { streakDataFetched } = streakDataSlice.actions;
export default streakDataSlice.reducer;