
import { useState } from "react";
import { useSelector } from "react-redux";

import Box from "@mui/material/Box";
import { Paper, Stack } from "@mui/material";

export default function DeviceDataMonitoring() {

  const [show, setShow] = useState(false)
  const desks  = useSelector( store => store.device.desks) //fetchDeskDevices
  const deviceRequests = useSelector( store => store.device.deviceRequests) // fetchDeviceRequests
  const bikes = useSelector( store => store.device.bikes) //fetchBikeDevices
  const adminDeviceList = useSelector( store => store.device.adminDeviceList) //fetchAllDevices

  function handleClick(){
    setShow(!show)
  }

  return (
    <Paper
      sx={{
        padding: 2,
        mt: 1
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>

        <Stack>
        <div>
          <button onClick={handleClick}>
            Show/Hide Dashboard User Fetch Status
          </button>
        </div>

        { show == true &&
          <div>
            <h1>
              Device Data Monitoring
            </h1>
            { desks?.length >0  ? <p> fetchDeskDevices  ✅</p> : <p>  fetchDeskDevices ❌</p> }
            { deviceRequests ? <p> fetchDeviceRequests  ✅</p> : <p>  fetchDeviceRequests ❌</p> }
            { bikes?.length >0  ? <p> fetchBikeDevices  ✅</p> : <p>  fetchBikeDevices ❌</p> }
            { adminDeviceList?.length >0  ? <p> fetchAllDevices  ✅</p> : <p>  fetchAllDevices ❌</p> }

          </div>
        }
        </Stack>

      </Box>
    </Paper>
  );
}
