import TeamList from "../components/TeamList/TeamList";
import Appbar from "../components/Appbar/Appbar";
import styles from "../styles/home.scss";
import { Box } from "@mui/system";
import Grid from "@mui/system/Unstable_Grid/Grid";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import AddTeamModal from "../components/AddTeamModal/AddTeamModal";

import { useDispatch } from "react-redux";
import { fetchTeamRawData } from "../components/Reducers/teamReducer";
import { useEffect, useContext } from "react";
import AuthContext from "../context/AuthContext";
import useAxios from "../utils/useAxios";
import { fetchDashboardUsers } from "../components/Reducers/dashboardUserReducer";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  borderRadius: "25px",
}));

function EmployeeTeams() {
  const api = useAxios()
  const dispatch = useDispatch();

  const {user, loading} = useContext(AuthContext); // NEW ! Required to pass the correct Company ID to fetchTeamRawData

  useEffect(() => {
    dispatch(fetchTeamRawData([user.company, api])); // Previously hardcoded to 1
    dispatch(fetchDashboardUsers([user.company, api])); 
  }, [api, user]);
  return (
    <div >
      {/* <Appbar menuItems={menuItems} page="Employee List" /> */}

      <Box
        sx={{
          flexGrow: 1,
          height: "100%",
          width: "100%",
        }}
      >
        <Grid
          container
          rowSpacing={2}
          columnSpacing={2}
          sx={{ mt: 1, ml: 1, mr: 1 }}
        >
          <Grid item xs={12}>
            <Item>
              <TeamList />
            </Item>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default EmployeeTeams;
