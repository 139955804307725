import React from "react";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import AuthContext from "../../context/AuthContext";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import "./LoginForm.scss";
import logo from "../../assets/images/ergonomyx_logo.png";
import Grid from "@mui/system/Unstable_Grid/Grid";
import Typography from "@mui/material/Typography";
import BetaPill from "../BetaPill/BetaPill";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";

export default function LoginForm() {
  const [userEmail, setUserEmail] = useState();
  const [password, setPassword] = useState();

  const { loginUser } = useContext(AuthContext);
  const handleSubmit = (e) => {
    e.preventDefault();
    // const username = e.target.username.value;
    // const password = e.target.password.value
    userEmail.length > 0 && loginUser(userEmail, password);
  };

  const parentTheme = useTheme();
  const textFieldProps = {
    input: {
      color: "black",
    },
    label: {
      color: "black",
    },
    "& label.Mui-focused": {
      color: "orange",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "lightgrey",
      },
      "&:hover fieldset": {
        borderColor: "orange",
      },
      "&.Mui-focused fieldset": {
        borderColor: "orange",
      },
    },
  };

  return (
    <Grid
      container
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "100vh",
        backgroundColor: "white",
      }}
    >
      <ThemeProvider
        theme={(outerTheme) =>
          createTheme({
            ...outerTheme,
            palette: {
              ...outerTheme.palette,
              mode: "light",
            },
          })
        }
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img src={logo} alt="" style={{ height: "3.5rem" }} />
          <Typography
            variant="h1"
            sx={{
              fontSize: "3rem",
              margin: 0,
              paddingTop: "0.5rem",
              color: "black",
            }}
          >
            <span style={{ color: "#F79A2B" }}>E</span>rgonomyx
          </Typography>
        </Box>
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "1.5rem",
          }}
          onSubmit={handleSubmit}
        >
          <Box
            sx={{
              margin: "1rem 0",
              width: "25rem",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid
              item="true"
              xs={10}
              sm={10}
              md={12}
              sx={{ margin: "auto", borderColor: "orange" }}
            >
              <TextField
                label="Email"
                variant="outlined"
                required
                onChange={(e) => setUserEmail(e.target.value)}
                sx={textFieldProps}
                fullWidth
              />
            </Grid>
          </Box>
          <Box
            sx={{
              margin: "1rem 0",
              width: "25rem",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid item="true" xs={10} sm={10} md={12} sx={{ margin: "auto" }}>
              <TextField
                label="Password"
                variant="outlined"
                required
                autoComplete="off"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                sx={textFieldProps}
                fullWidth
              />
            </Grid>
          </Box>
          <Box sx={{ marginTop: "2rem", display: "flex" }}>
            <Button
              className="login__button btn-login"
              variant="contained"
              type="submit"
            >
              Login
            </Button>
            <Button
              className="login__button btn-back"
              variant="outlined"
              component={Link}
              to="/"
            >
              Back
            </Button>
          </Box>
          <Link to="/forgot">
            <Typography
              sx={{
                marginTop: `1.75rem`,
                color: `#F79A2B`,
                fontWeight: `bold`,
                fontSize: `1.25rem`,
                textDecoration: `underline #F79A2B`,
              }}
            >
              Forgot Password
            </Typography>
          </Link>
        </form>

        {/* <Box
        sx={{
          width:"70%",
          marginTop:"10vh",
        }}
      >
        <BetaPill/>
      </Box> */}
      </ThemeProvider>
    </Grid>
  );
}
