
import { useState } from "react";
import { useSelector } from "react-redux";

import Box from "@mui/material/Box";
import { Paper, Stack } from "@mui/material";

export default function LeaderboardDataMonitoring() {

  const [show, setShow] = useState(false)
  const users = useSelector( store => store.leaderboard.users) //fetchUserLeaderboard
  const teams = useSelector( store => store.leaderboard.teams) //fetchTeamLeaderboard

  function handleClick(){
    setShow(!show)
  }

  return (
    <Paper
      sx={{
        padding: 2,
        mt: 1
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>

        <Stack>
        <div>
          <button onClick={handleClick}>
            Show/Hide Leaderboard Fetch Status
          </button>
        </div>

        { show == true &&
          <div>
            <h1>
              Leaderboard Data Monitoring
            </h1>
            { users?.length >0  ? <p>  fetchUserLeaderboard ✅</p> : <p>  fetchUserLeaderboard  ❌</p> }
            { teams?.length >0  ? <p>  fetchTeamLeaderboard ✅</p> : <p>  fetchTeamLeaderboard  ❌</p> }

          </div>
        }
        </Stack>

      </Box>
    </Paper>
  );
}
