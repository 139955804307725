import {
  Modal,
  Snackbar,
  Box,
  Stack,
  Button,
  Typography,
  Paper,
  Badge,
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import React, { useState } from "react";
import useAxios from "../../utils/useAxios";
import AuthContext from "../../context/AuthContext";
import { useContext } from "react";
import { TableContainer } from "@mui/material";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import {
  fetchBikeDevices,
  fetchDeskDevices,
  fetchDeviceRequests,
} from "../Reducers/deviceReducer";
import { useDispatch } from "react-redux";
export default function CompanyDeviceRequests({
  deviceRequests,
  desks,
  employees,
}) {
  // Create tables data
  let rows = [];
  for (let i = 0, len = deviceRequests.length; i < len; i++) {
    let id = deviceRequests[i].id;
    let device = desks.find((desk) => desk.id === deviceRequests[i].device);
    let dashboard_user = employees.find(
      (employee) => employee.id === deviceRequests[i].dashboard_user
    );

    rows.push({
      id: id,
      device: device,
      dashboard_user: dashboard_user,
    });
  }

  // Modal State
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Current user
  const { user } = useContext(AuthContext);

  // Snackbar State
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleSnackbarOpen = () => setSnackbarOpen(true);
  const handleSnackbarClose = () => setSnackbarOpen(false);
  const action = (
    <React.Fragment>
      <Button color="primary" size="small" onClick={handleSnackbarClose}>
        Close
      </Button>
    </React.Fragment>
  );

  const api = useAxios();
  const dispatch = useDispatch();
  const refreshData = () => {
    dispatch(fetchDeskDevices([api, user.company]));
    dispatch(fetchBikeDevices([api, user.company]));
    dispatch(fetchDeviceRequests([api, user.company]));
  };
  const handleApproval = (deviceRequestId) => {
    api
      .post(`/device_request/approve/${deviceRequestId}/`)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          console.log("Request Approved Successfully");
          setSnackbarMessage("Request Approved");
          refreshData();
          handleSnackbarOpen();
        }
      })
      .catch((err) => {
        console.log(err);
        setSnackbarMessage("Request could not be approved: " + err.message);
        refreshData();
        handleSnackbarOpen();
      });
  };

  const handleReject = (deviceRequestId) => {
    api
      .post(`/device_request/deny/${deviceRequestId}/`)
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          console.log("Request Rejected Successfully");
          setSnackbarMessage("Request Rejected Successfully");
          refreshData();
          handleSnackbarOpen();
        }
      })
      .catch((err) => {
        console.log(err);
        setSnackbarMessage("Request could not be approved: " + err.message);
        refreshData();
        handleSnackbarOpen();
      });
  };

  return (
    <>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={action}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      />
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "100%", md: "70%", lg: "80%" },
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            // should be scrollable if content is too long
            overflow: "auto",
            maxHeight: "90%",
            borderRadius: 2,
          }}
        >
          <Close
            sx={{
              float: "right",
              cursor: "pointer",
            }}
            onClick={handleClose}
          />
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Typography variant="h4">Device Requests</Typography>
            <Paper
              sx={{
                width: "100%",
                height: "100%",
                overflow: "auto",
              }}
            >
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Device Serial</TableCell>
                      <TableCell>Device Name</TableCell>
                      <TableCell>User</TableCell>
                      <TableCell>Approve</TableCell>
                      <TableCell>Reject</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((deviceRequest) => (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={deviceRequest.id}
                      >
                        <TableCell>
                          {deviceRequest.device.serial_number}
                        </TableCell>
                        <TableCell>
                          {deviceRequest.device.device_name}
                        </TableCell>
                        <TableCell>
                          {deviceRequest.dashboard_user.first_name}{" "}
                          {deviceRequest.last_name}
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => handleApproval(deviceRequest.id)}
                          >
                            Approve
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            color="error"
                            size="small"
                            onClick={() => handleReject(deviceRequest.id)}
                          >
                            Reject
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Stack>
        </Box>
      </Modal>
      <Box>
        <Badge badgeContent={deviceRequests.length} color="error">
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpen}
            sx={{ color: "white" }}
          >
            Device Requests
          </Button>
        </Badge>
      </Box>
    </>
  );
}
