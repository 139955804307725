import { Grid, Box, Typography, Select, FormControl, MenuItem, InputLabel } from '@mui/material';
import React, { useState, useEffect } from 'react';
import ErgoLineChart from '../ErgoLineChart/ErgoLineChart';
import { useSelector } from 'react-redux';
import {store as dashboardStore} from 'react-redux';

export default function CompareTeams() {

  const data = useSelector(dashboardStore => dashboardStore.team.team_monthly_activity)
  //  [
  //       { date: "2020-01-01", team1: 100, team2: 200, team3: 300 },
  //       { date: "2020-01-02", team1: 100, team2: 200, team3: 300 },
  //       ...
  //   ]
  // Each integer is aggregate time biking + desk standing time
  // The unit we will give is "active minutes"
  const [chartDataRange, setChartDataRange] = useState(7);

  const handleChange = (event) => {
    setChartDataRange(event.target.value);
    setChartData(data.slice(0, event.target.value).reverse());
  };

  // default chartData is only the most recent 7 days
  const [chartData, setChartData] = useState([]);

  // we need to re-render the chart when the data changes
  useEffect(() => {
    if (data && data.length > 0) {
      setChartData(data.slice(0, chartDataRange).reverse());
      console.log(data)
    }
  }, [data]);

  return (
    <Grid container spacing={2} p={1}>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: 'flex',
          alignItems: 'center',
          alignContent: 'left',
        }}
      >
        <Typography variant="h3">Compare Teams</Typography>
      </Grid>
      <Grid item xs={12} md={6} pr={2} mt={1}>
        <FormControl fullWidth>
          <InputLabel id="select-label">Range</InputLabel>
          <Select
          size="small"
            labelId="select-label"
            id="select"
            value={chartDataRange}
            label="Select Range"
            onChange={handleChange}
          >
            <MenuItem value={7}>Last 7 Days</MenuItem>
            <MenuItem value={14}>Last 14 Days</MenuItem>
            <MenuItem value={30}>Last 30 Days</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            width: '100%',
            height: '280px',
          }}
        >
          {chartData.length > 0 && <ErgoLineChart data={chartData} legend={true} />}
        </Box>
      </Grid>
    </Grid>
  );
}
