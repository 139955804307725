import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AuthContext from "../../context/AuthContext";
import { useContext } from "react";

export const fetchHourlyDeskUpsert = createAsyncThunk(
  "hourly_desk_upsert/fetchHourlyDeskUpsert",
  async ([company_id, api]) => { // removed passing authtokens
    let endpoint = `/hourly_desk_upsert/get_by_company/${company_id}/`
    const response = await api.get(endpoint);
    const data = response.data
    return data;
  }
);

const hourlyDeskUpsertDataSlice = createSlice({
  name: "hourly_desk_upsert",
  initialState: { hourly_desk_upserts: [] },
  reducers: {
    hourlyDeskUpsertDataFetched(state, action) {
      state.hourly_desk_upserts = action.payload; //passing the entire response
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchHourlyDeskUpsert.pending, (state, action) => {
        //
      })
      .addCase(fetchHourlyDeskUpsert.fulfilled, (state, action) => {
        state.hourly_desk_upserts = action.payload;
      })
      .addCase(fetchHourlyDeskUpsert.rejected, (state, action) => {
        console.log("error in extra reducers (fetchHourlyDeskUpsert)");
      });
  },
});

export const { hourlyDeskUpsertDataFetched } =
  hourlyDeskUpsertDataSlice.actions;
export default hourlyDeskUpsertDataSlice.reducer;
