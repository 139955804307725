
import axios from "axios";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import useCookies from "react-cookie/cjs/useCookies";
import { useLocation } from "react-router-dom";

// const baseURL = "http://localhost:8000";
const baseURL = process.env.REACT_APP_DASHBOARD_API_URL;

const useAxios = () => {
  
  /* FOLLOWING THIS REFERENCE : https://bobbyhadz.com/blog/react-get-current-url#:~:text=Use%20the%20window%20object%20to,access%20the%20path%2C%20use%20window. */

  // 👇️ WITHOUT React router
  // console.log('current URL 👉️', window.location.href);
  // console.log('current Pathname 👉️', window.location.pathname);

  // 👇️ with React router #THIS DOESNT HAVE THE INFORMATION NEEDED ***
  // const location = useLocation();
  // console.log('hash', location.hash);
  // console.log('pathname', location.pathname);
  // console.log('search', location.search);

  const currentUrlOrigin = String(window.location.origin)
  // console.log("*** useAxios --- currentUrl : ", currentUrlOrigin)

  const { authTokens, setUser, setAuthTokens } = useContext(AuthContext);

    //create handles for cookies
  const [cookies, setCookie, removeCookie] = useCookies(['authTokens', 'refresh' , 'access']);

  const axiosInstance = axios.create({
    baseURL,
    headers: { 
      Authorization: `Bearer ${cookies.authTokens?.access}`, 
      // We cannot manually set the origin header, it is automatically set by the browser
      // Origin : currentUrlOrigin,
    }
  });

  // console.log("axiosInstance headers : ", JSON.stringify(axiosInstance.headers))

  axiosInstance.interceptors.request.use(async req => {
    const user = jwt_decode(cookies.authTokens.access);
    const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

    if (!isExpired) return req;

    const response = await axios.post(`${baseURL}/api/token/refresh/`, {
      refresh: cookies.authTokens.refresh
    });

    setCookie("authTokens", JSON.stringify(response.data))//not sure what options exists (max-age, expiry?)

    setAuthTokens(response.data);
    setUser(jwt_decode(response.data.access));

    req.headers.Authorization = `Bearer ${response.data.access}`;
    return req;
  });

  return axiosInstance;
};

export default useAxios;