import React, { useEffect } from "react";
import {
  DataGrid,
  GridToolbarFilterButton,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { useState } from "react";
import styles from "./DeskDataTable.module.scss";
import { createTheme, CssBaseline, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const dayInMonthComparator = (v1, v2) => {
  // date in in the format MM/DD/YYYY
    const date1 = new Date(v1);
    const date2 = new Date(v2);
    const day1 = date1.getDate();
    const day2 = date2.getDate();
    const month1 = date1.getMonth();
    const month2 = date2.getMonth();
    const year1 = date1.getFullYear();
    const year2 = date2.getFullYear();

    if (year1 < year2) {
      return -1;
    }
    if (year1 > year2) {
      return 1;
    }
    if (month1 < month2) {
      return -1;
    }
    if (month1 > month2) {
      return 1;
    }
    if (day1 < day2) {
      return -1;
    }
    if (day1 > day2) {
      return 1;
    }
    return 0;
}

const columns = [
  {
    field: "date",
    headerName: "Date",
    width: 100,
    headerAlign: "center",
    align: "center",
    flex: 1,
    valueGetter: (params) => params.row.date,
    type: "date",
    sortComparator: dayInMonthComparator
  },
  {
    field: "movements",
    headerName: "Movements",
    width: 100,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "time_sitting",
    headerName: "Time Sitting",
    width: 100,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "time_standing",
    headerName: "Time Standing",
    width: 100,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
];

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <Typography variant="h2">Desk Data Table</Typography>
      <GridToolbarFilterButton
          variant="outlined"
          sx={{
            borderRadius: "20px",
            margin: "2rem",
            fontSize: "1.2rem",
            fontWeight: "bold",
            textTransform: "none",
            color: "orange",
            borderColor: "orange",
            "&:hover" : {
              borderColor: "orange",
            }
          }}
      />
    </GridToolbarContainer>
  );
}
export default function DeskDataTable() {
  const [pageSize, setPageSize] = useState(25);

  const hourly_desk_upserts = useSelector(
    (dashboardStore) => dashboardStore.hourly_desk_upsert.hourly_desk_upserts
  );

  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (hourly_desk_upserts.length > 0) {
      // format date as DD/MM/YYYY and time for each row
      const formattedRows = hourly_desk_upserts.map((row) => {
        const date = new Date(row.date);
        const formattedDate = `${date.getMonth() + 1}/${
          date.getDate()
        }/${date.getFullYear()}`;
        // Time sitting = time_total - time
        const timeSitting = row.time_total - row.time;
        const timeStanding = row.time;
        return {
          id: row.id,
          date: formattedDate,
          movements: row.movements,
          time_sitting: timeSitting,
          time_standing: timeStanding,
        };
      });
      setRows(formattedRows);
    }
  }, [hourly_desk_upserts]);

  return (
    <>
      <div className={styles.dataGridContainer}>
        <CssBaseline />
        {hourly_desk_upserts && rows.length > 0 && (
          <DataGrid
            components={{ Toolbar: CustomToolbar }}
            pageSize={pageSize}
            onPageSizeChange={(newPage) => setPageSize(newPage)}
            pagination
            rowsPerPageOptions={[5, 10, 15, 20, 25]}
            checkboxSelection
            columns={columns}
            rows={rows}
            rowHeight={60}
            text
            // override the Filter button innter text from "filters"
            localeText={{
              toolbarFilters: "Filter",
            }}
            initialState={{
              ...rows.initialState,
              sorting: {
                ...rows.initialState?.storing,
                sortModel: [
                  {
                    field: "date",
                    sort: "desc",
                  }
                ],
              },
              }}
            sx={{
              border: "none",
              ".MuiDataGrid-columnHeaderTitle": {
                fontWeight: "bold",
                fontSize: "1.2rem",
              },
            }}
          />
        )}
      </div>
    </>
  );
}
