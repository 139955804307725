import React, { useContext, useState } from "react";
import {
  MenuItem,
  Button,
  Modal,
  Box,
  Grid,
  TextField,
  Select,
  FormHelperText,
  FormControl,
  Typography,
  Paper,
  FormLabel,
} from "@mui/material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "../../styles/colours.scss";
import styles from "./AddTeamModal.module.scss";
import AuthContext from "../../context/AuthContext";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useAxios from "../../utils/useAxios"
import { useDispatch } from "react-redux";
import { fetchTeamData, fetchTeamRawData } from "../Reducers/teamReducer";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  borderRadius: "10px",
  p: 4,
};

export default function AddTeamModal(props) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { user } = useContext(AuthContext);
  const dispatch = useDispatch();
  const [teamName, setTeamName] = useState("");
  const api = useAxios()

  const refreshTeams = () => {
    dispatch(fetchTeamData([api, user.company])); 
    dispatch(fetchTeamRawData([user.company, api])); 
  }

  function addTeam(team_name, company_id){
    let endpoint = `/team/`
    const response = api.post(endpoint, {
      "team_name":team_name, 
      "company" : String(company_id)
    })
    const data = response.data    
    console.log("POSTED NEW TEAM DATA - Result :", data)
    return data;

  }

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("submitting");
    //hardcoded
    const company = user.company;
    addTeam(teamName, company);
    console.log("Team Added");
    refreshTeams();
    handleClose();
  };

  return (
    <div>
      <Button
        sx={{
          color: "white",
          backgroundColor: "orange",
          "&:hover": {
            backgroundColor: "#e2830f",
          },
        }}
        size="large"
        onClick={handleOpen}
      >
        Add Team
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <form onSubmit={handleSubmit}>
            <Grid
              container
              spacing={3}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={12} display="flex">
                <ArrowBackIcon
                  onClick={handleClose}
                  sx={{
                    cursor: "pointer",
                    fontSize: "3rem",
                    marginRight: "1rem",
                  }}
                />
                <Typography variant="h3">Add Team</Typography>
              </Grid>

              <Grid item xs={12}>
                <FormLabel>Team Name</FormLabel>

                <TextField
                  required
                  id="outlined-required"
                  label="required"
                  fullWidth
                  value={teamName}
                  onChange={(event) => setTeamName(event.target.value)}
                />
              </Grid>

              {/* SHOULD THERE BE A DROPDOWN FOR THE COMPANY TO CREATE THE TEAM FOR ? */}
              {/* RIGHT NOW IT'S HARDCODED THAT THE COMPANY IS = 1 */}

              <Grid item xs={12} display="flex" justifyContent="center">
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    color: "white",
                    backgroundColor: "orange",
                    "&:hover": {
                      backgroundColor: "#e2830f",
                    },
                  }}
                >
                  Add Team
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
