import styles from "../styles/home.scss";

import Grid from "@mui/system/Unstable_Grid/Grid";
import { Box } from "@mui/system";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import CompanySettings from "../components/CompanySettings/CompanySettings";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  borderRadius: "25px",
}));


const SettingsCompanyPage = () => {
  return (
    <div >
      <Box
        sx={{
          flexGrow: 1,
          height :"100%",
        }}
      >
        <Grid
          container
          rowSpacing={2}
          columnSpacing={2}
          sx={{ mt: 1, ml: 1, mr: 1 }}
          justifyContent="center"
        >
          <Grid item xs={12} md={8}>
            <Item>
              <CompanySettings />
            </Item>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default SettingsCompanyPage;