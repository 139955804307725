import DeviceList from "../components/DeviceList/DeviceList";
import styles from "../styles/home.scss";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { Box } from "@mui/system";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import {
  fetchDeskDevices,
  updateDeviceListAlertInfo,
} from "../components/Reducers/deviceReducer";
import { useDispatch } from "react-redux";
import useAxios from "../utils/useAxios";
import AuthContext from "../context/AuthContext";
import { useContext, useEffect } from "react";
import { fetchCompanyData } from "../components/Reducers/companyReducer";
import { fetchDashboardUsers } from "../components/Reducers/dashboardUserReducer";
import { fetchDeviceRequests } from "../components/Reducers/deviceReducer";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  borderRadius: "25px",
}));

const DeviceListDeskPage = () => {
  const api = useAxios();

  const dispatch = useDispatch();

  const { user, loading } = useContext(AuthContext);
  dispatch(
    updateDeviceListAlertInfo({ alert_message: "", alert_severity: "" })
  );

  useEffect(() => {
    if (!loading) {
      if (user) {
        dispatch(fetchCompanyData([api, user.company]));
        dispatch(fetchDeskDevices([api, user.company]));
        dispatch(fetchDashboardUsers([user.company, api]));
        if (user.user_type >= 2) {
          dispatch(fetchDeviceRequests([api, user.company]));
        }
      }
    }
  }, [api, user]);

  return (
    <div>
      <Box
        sx={{
          flexGrow: 1,
          height: "100%",
          width: "100%",
        }}
      >
        <Grid
          container
          rowSpacing={2}
          columnSpacing={2}
          sx={{ mt: 1, ml: 1, mr: 1 }}
        >
          <Grid item xs={12}>
            <Item>
              <DeviceList deviceType={"desk"} />
            </Item>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default DeviceListDeskPage;
