import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
export const fetchHourlyBikeUpsert = createAsyncThunk(
  'hourly_bike_upsert/fetchHourlyBikeUpsert',
  async ([company_id, api]) => { //removed passing authtokens
    let endpoint = `/hourly_bike_upsert/get_by_company/${company_id}/`
    const response = await api.get(endpoint);
    const data = response.data
    return data;

  }
);

const hourlyBikeUpsertDataSlice = createSlice({
  name: 'hourly_bike_upsert',
  initialState: {"hourly_bike_upserts":[]},
  reducers: {
    hourlyBikeUpsertDataFetched(state, action) {
        state.hourly_bike_upserts = action.payload //passing the entire response
    },
  },
  extraReducers(builder) {
    builder
    .addCase(fetchHourlyBikeUpsert.pending, (state, action) => {
      //
    })
    .addCase(fetchHourlyBikeUpsert.fulfilled, (state, action) => {
      state.hourly_bike_upserts = action.payload
    })
    .addCase(fetchHourlyBikeUpsert.rejected, (state, action) => {
      console.log('error in extra reducers (fetchHourlyBikeUpsert)')
    });
  }
})

export const { hourlyBikeUpsertDataFetched } = hourlyBikeUpsertDataSlice.actions
export default hourlyBikeUpsertDataSlice.reducer