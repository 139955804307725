import WelcomeScreen from "../components/WelcomeScreen/WelcomeScreen";

const Welcome = () => {
  return (
    <div>
      <WelcomeScreen/>
    </div>
  );
};

export default Welcome;
