import {
  Modal,
  Snackbar,
  Box,
  Stack,
  Button,
  Typography,
  Select,
  MenuItem,
  Paper,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import useAxios from "../../utils/useAxios";
import AuthContext from "../../context/AuthContext";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { fetchDeskDevices, fetchBikeDevices } from "../Reducers/deviceReducer";
import { fetchDashboardUserRoutines } from "../Reducers/routineReducer";
import { IconButton } from "@mui/material";
import { AddCircleOutlineOutlined } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
export default function AddRoutineModal() {
  // Modal State
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setRoutineName("");
    setOpen(false);
  };

  const { user } = useContext(AuthContext);
  const [routineName, setRoutineName] = useState("My Routine");
  // Snackbar State
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleSnackbarOpen = () => setSnackbarOpen(true);
  const handleSnackbarClose = () => setSnackbarOpen(false);
  const action = (
    <React.Fragment>
      <Button color="primary" size="small" onClick={handleSnackbarClose}>
        Close
      </Button>
    </React.Fragment>
  );

  const api = useAxios();
  const dispatch = useDispatch();

  const refreshRoutines = () => {
    dispatch(fetchDashboardUserRoutines([user.user_id, api]));
  };

  const handleSubmit = () => {
    api
      .post(`/routine/create/`, {
        dashboard_user: user.user_id,
        name: routineName,
        total_time: 30,
        active_time: 15,
        rest_time: 15,
        active_height: 112,
        rest_height: 80
      })
      .then((res) => {
        if (res.status >= 200 && res.status < 300) {
          setSnackbarMessage("Routine Created Successfully");
          handleSnackbarOpen();
          refreshRoutines();
          handleClose();
        }
      })
      .catch((err) => {
        console.log(err);
        setSnackbarMessage("Request Failed: " + err.response.data.message);
        handleSnackbarOpen();
        handleClose();
      });
  };

  return (
    <>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={action}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      />
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", sm: "400px" },
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            overflow: "auto",
            maxHeight: "90%",
            borderRadius: 2,
          }}
        >
                      <CloseIcon
            sx={{
              float: "right",
              cursor: "pointer",
            }}
            onClick={handleClose}
          />
          
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            ><Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Typography variant="h4">Create new routine</Typography>
            <Typography variant="body1">Enter a name for your routine</Typography>
            <TextField
                required
                label="Routine Name"
                variant="outlined"
                size="small"
                value={routineName}
                onChange={(e) => setRoutineName(e.target.value)}

                />
            <Button variant="contained" type="submit">
                Create Routine
            </Button>          </Stack>

            </form>

        </Box>
      </Modal>

      <IconButton sx={{ marginLeft: "20px" }} onClick={handleOpen}>
        <AddCircleOutlineOutlined />
      </IconButton>
    </>
  );
}
