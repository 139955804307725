import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Grid from "@mui/system/Unstable_Grid/Grid";
import hot_streak_icon from "../../../assets/images/streaks/streak_wheel_hot.png";
import cold_streak_icon from "../../../assets/images/streaks/streak_wheel_cold.png";

import { useSelector } from "react-redux";

import { Typography } from "@mui/material";

export default function StreakData(props) {
  // Get team and employee data from the store

  const user_streaks = useSelector(
    (dashboardStore) => dashboardStore.streak.streaks
  );
  const dispatch = useDispatch();

  const [bikeStreakHot, setBikeStreakHot] = useState("");
  const [bikeStreakCold, setBikeStreakCold] = useState("");

  useEffect(() => {
    for (var i = 0; i < user_streaks.length; i++) {
      console.log("STREAK: ", user_streaks[i]);
      // Get the bike streak, and the desk streak
      if (user_streaks[i].streak_type === "time_bike") {
        setBikeStreakHot(user_streaks[i].streak_days);
        setBikeStreakCold(user_streaks[i].non_streak_days);
      }
    }
  }, [user_streaks]);

  return (
    <Grid container spacing={2}>
      {/* Title and date */}
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          alignItems: "center",
          alignContent: "left",
        }}
      >
        <Box>
          <Typography variant="h3">Your Streaks</Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            alignContent: "left",
          }}
        >
          <img src={hot_streak_icon} alt="" style={{ height: "3.5rem" }} />
          <Typography variant="subtitle1" sx={{ marginLeft: 5 }}>
            {bikeStreakHot} Days <span style={{color: 'grey', fontSize: `14px`}}>Current Streak</span>
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            alignContent: "left",
          }}
        >
          <img src={cold_streak_icon} alt="" style={{ height: "3.2rem" }} />
          <Typography variant="subtitle1" sx={{ marginLeft: 5 }}>
            {bikeStreakCold} Days <span style={{color: 'grey', fontSize: `14px`}}>Streak Freeze</span>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}
