import styles from "./Leaderboard.module.scss";
import react, { useState } from "react";
import {
  DataGrid,
  GridToolbarFilterButton,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { ThemeProvider, createTheme, CssBaseline } from "@mui/material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ThemeContext from "../../context/ThemeContext";
import { useContext } from "react";

// Columns for the DataGrid
const columns = [
  {
    field: "rank",
    headerName: "Rank",
    width: 90,
    headerAlign: "center",
    align: "center",
    flex: 1,
    renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
  },
  {
    field: "name",
    headerName: "Name",
    width: 200,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "team_name",
    headerName: "Team",
    width: 90,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "activity",
    headerName: "Activity",
    width: 100,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "calories",
    headerName: "Calories",
    width: 100,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
  {
    field: "distance",
    headerName: "Distance",
    width: 100,
    headerAlign: "center",
    align: "center",
    flex: 1,
  },
];

//NOTE : This doesn't respect the D.R.Y. property , but for some reason CustomToolbar having a parameter does not work 
//E.G. I tried CustomToolbar(theme) and CustomToolbar(color), but it just didn't budge

//NOTE : This component seems outdated, since it still contains the SCSS styles
// It also contained a theme defined in here, so I have commented it out

function CustomToolbarLight() {
  return ( 
    <GridToolbarContainer>

          <Link to={"/home"}>

          <ArrowBackIcon 
          sx={{ 
            mr: 2,
            color: "black",
            cursor: "pointer",
            fontSize: "3rem",
            marginRight: "1rem",
          }}
          />
          
          </Link>

          <h1 className={styles.title}>Leaderboard</h1>
          <GridToolbarFilterButton
          variant="outlined"
          sx={{
            borderRadius: "20px",
            margin: "2rem",
            fontSize: "1.2rem",
            fontWeight: "bold",
            textTransform: "none",
            color: "orange",
            borderColor: "orange",
            "&:hover" : {
              borderColor: "orange",
            }
          }}
          />

    </GridToolbarContainer>
    
  );
}

function CustomToolbarDark() {
  return ( 
    <GridToolbarContainer>

          <Link to={"/home"}>

          <ArrowBackIcon 
          sx={{ 
            mr: 2,
            color: "white",
            cursor: "pointer",
            fontSize: "3rem",
            marginRight: "1rem",
          }}
          />
          
          </Link>

          <h1 className={styles.title}>Leaderboard</h1>
          <GridToolbarFilterButton
          variant="outlined"
          sx={{
            borderRadius: "20px",
            margin: "2rem",
            fontSize: "1.2rem",
            fontWeight: "bold",
            textTransform: "none",
            color: "orange",
            borderColor: "orange",
            "&:hover" : {
              borderColor: "orange",
            }
          }}
          />

    </GridToolbarContainer>
    
  );
}

// REMOVED *** - Global theme should be used, not a specific theme for just this component
// DataGrid theme override to orange
// const theme = createTheme({
//   palette: {
//     primary: {
//       main: "#F7941E",
//     },
//   },
//   components: {
//     MuiDataGrid: {
//       styleOverrides: {
//         toolbar: {
//           MuiButton: {
//             styleOverrides: {
//                 borderRadius: "20px",
//                 margin: "2rem",
//                 fontSize: "1.2rem",
//                 fontWeight: "bold",
//                 textTransform: "none",
//                 color: "primary.main",
//             },
//           },
//         },
//       },
//     },
//   },
              
// });
export default function Leaderboard(props) {

  const {theme, toggleTheme} = useContext(ThemeContext) //this is importing the theme , in order to change the button color depending on current theme

  // Page size state for the DataGrid
  const [pageSize, setPageSize] = useState(25);

  // Get team and employee data from the store
  const leaderboard = useSelector(
    (dashboardStore) => dashboardStore.leaderboard.users
  );

  // Create a deep copy of leaderboard, and append a team name and full name to each employee
  // This is passed as the rows prop to the DataGrid
  let rows = [];
  for (let i = 0; i < leaderboard.length; i++) {
    rows.push({
      ...leaderboard[i],
      // rank: i + 1,
      name: leaderboard[i].name,
      team_name: leaderboard[i].team,
      // activity: parseInt(leaderboard[i].activity),
      // activity is in minutes, we want to format as 2h 30m
      activity: leaderboard[i].activity >= 60
        ? Math.floor(leaderboard[i].activity / 60) +
          "h " +
          (parseInt(leaderboard[i].activity % 60)) +
          "m"
        : parseInt(leaderboard[i].activity) + "m",
        
      // format calories by the thousand, i.e 17500 -> 17.5k and 750 -> 750
      calories: leaderboard[i].calories / 1000 >= 1
        ? (leaderboard[i].calories / 1000).toFixed(1) + "k"
        : leaderboard[i].calories,
      // format distance by the km, i.e 17500 -> 17.5km and 750 -> 750m
      distance: leaderboard[i].distance / 1000 >= 1
        ? (leaderboard[i].distance / 1000).toFixed(1) + " km"
        : parseInt(leaderboard[i].distance) + " m",
      
    });
  }

  return (
    <div className={styles.dataGridContainer}>
        {rows && (
          <DataGrid
            // components={{Toolbar: GridToolbarFilterButton}}
            initialState={{
              sorting: {
                sortModel: [{ field: "monthly_usage", sort: "desc" }],
              },
            }}
            components={{ Toolbar: theme === "dark"? CustomToolbarDark : CustomToolbarLight }}
            pageSize={pageSize}
            onPageSizeChange={(newPage) => setPageSize(newPage)}
            pagination
            rowsPerPageOptions={[5, 10, 15, 20, 25]}
            rows={rows}
            columns={columns}
            rowHeight={60}
            text
            // override the Filter button innter text from "filters"
            localeText={{
              toolbarFilters: "Filter",
            }}
            sx={{
              border: "none",
              ".MuiDataGrid-columnHeaderTitle": {
                fontWeight: "bold",
              },
            }}
          />
        )}
    </div>
  );
}
