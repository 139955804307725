import {
  Modal,
  Snackbar,
  Box,
  Stack,
  Button,
  Typography,
  Select,
  MenuItem,
  Paper,
} from "@mui/material";
import React, { useState } from "react";
import useAxios from "../../utils/useAxios";
import AuthContext from "../../context/AuthContext";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { fetchDeskDevices, fetchBikeDevices } from "../Reducers/deviceReducer";

export default function RequestDeviceModal({ deviceList }) {
  // Modal State
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setSelectedDevice(null);
  }

  // Current user
  const { user } = useContext(AuthContext);


  // Snackbar State
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleSnackbarOpen = () => setSnackbarOpen(true);
  const handleSnackbarClose = () => setSnackbarOpen(false);
  const action = (
    <React.Fragment>
      <Button color="primary" size="small" onClick={handleSnackbarClose}>
        Close
      </Button>
    </React.Fragment>
  );

  const [selectedDevice, setSelectedDevice] = useState(null);
  const api = useAxios()
  const dispatch = useDispatch();

  const refreshDevices = () => {
    dispatch(fetchDeskDevices([api, user.company]));
    dispatch(fetchBikeDevices([api, user.company]));
  };

  const handleSubmit = () => {
    api.post(`/device_request/create/`, {
      // if the company is changing, we need to set the dashboard_user_id to null
      dashboard_user: user.user_id,
      device: selectedDevice,
      company: user.company,
    }).then((res) => {
      if (res.status >= 200 && res.status < 300) {
        console.log("Device Updated Successfully")
        setSnackbarMessage("Request Created Successfully");
        handleSnackbarOpen();
        refreshDevices();
         handleClose();
      } 

      }).catch((err) => {
        console.log(err);
        setSnackbarMessage("Request Failed: " + err.response.data.message);
        handleSnackbarOpen();
        handleClose();
      });
  };

  return (
    <>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={action}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      />
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            // should be scrollable if content is too long
            overflow: "auto",
            maxHeight: "90%",
            borderRadius: 2,
          }}
        >
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Typography variant="h4">Request Device Assignment</Typography>
            <Typography variant="body1">Select an available device</Typography>
            <Select
              labelId="device-select-label"
              id="device-select"
              label="Device"
              fullWidth
              value={selectedDevice}
              onChange={(e) => setSelectedDevice(e.target.value)}
            >
              {deviceList.map((device) => (
                <MenuItem key={device.id} value={device.id}>
                  {device.device_name}
                </MenuItem>
              ))}
            </Select>

            {selectedDevice && (
                <>
                <Paper
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        p: 2,
                        borderRadius: 2,
                
                    }}
                >
                    <Typography variant="h6">Device Details</Typography>
                    <Typography variant="body1">Device Name: {deviceList.find(device => device.id === selectedDevice).device_name}</Typography>
                    <Typography variant="body1">Device Serial: {deviceList.find(device => device.id === selectedDevice).serial_number}</Typography>
                    <Typography variant="body1">Status: {deviceList.find(device => device.id === selectedDevice).is_connected ? "Connected" : "Disconnected"}</Typography>

                </Paper>
                            <Button
                            variant="contained"
                            onClick={handleSubmit}
                            sx={{ color: "white" }}
                        >
                            Submit
                        </Button>
                        </>
            )}


          </Stack>
        </Box>
      </Modal>
      <Box sx={{ display: "flex", justifyContent: "center", marginX: "auto" }}>
        <Button
          variant="contained"
          onClick={handleOpen}
          sx={{ color: "white" }}
        >
          Request Device
        </Button>
      </Box>
    </>
  );
}
