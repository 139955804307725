import { Box, Chip, Grid, Stack, Typography } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import ErgoLineChart from "../ErgoLineChart/ErgoLineChart";

export default function DeviceStatusCard({ deviceStatus, userType }) {
  return (
    <Grid container p={3} sx={{ border: 1, borderColor: "divider" }}>
      <Grid item xs={9}>
        <Stack spacing={1} direction="row" alignItems="center">
          <Typography variant="overline">Serial Number</Typography>
          <Typography variant="body">{deviceStatus.serialNumber}</Typography>
        </Stack>
        <Stack spacing={1} direction="row" alignItems="center">
          <Typography variant="overline">asset tag</Typography>
          <Typography variant="body">{deviceStatus.assetTag}</Typography>
        </Stack>
      </Grid>
      <Grid item xs={3}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
                {/* warning label or green dot */}
                {deviceStatus.status === "online" ? (
          <Box
            sx={{
              bgcolor: "success.main",
              width: 16,
              height: 16,
              borderRadius: "50%",
              marginLeft: "auto",
            }}
          />
        ) : (
          <ErrorIcon color="error" />
        )}
        {/* Spacer */}
        <Box sx={{ flexGrow: 1 }} />
        {/* Company Chip */}
        {
          userType == 3 && (
            <Chip
              label={deviceStatus.company_name}
              size="small"
              sx={{
                borderRadius: "5px",
                backgroundColor: "rgba(255, 166, 41, 0.5)",
              }}
            />
          )
        }

      </Grid>
      <Grid item xs={12}>
        <Typography variant="caption">Status check failed (count)</Typography>
      <Box
          sx={{
            width: '100%',
            height: '200px',
          }}
        >
          <ErgoLineChart
          margin={{ top: 0, right: 0, bottom: 0, left: -20 }}
            data={deviceStatus.statusHistory}
            stroke={"#FFA629"} // ergo orange
          />
          </Box>
      </Grid>
    </Grid>
  );
}
