import { Paper, Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ThemeContext from "../../context/ThemeContext";
import LinearProgress from "@mui/material/LinearProgress"; /* NEW */
import ChallengeCard from "./ChallengeCard";

export default function ChallengeGroup({
  challengeGroup,
  img,
  challenge_task_percentages,
}) {
  
  return (
    <Paper
      elevation={3}
      sx={{
        transition: "0.3s",
        "&:hover": {
          transform: "scale(1.02)",
        },
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* add background image to the top of the parent paper element and allow items to overlap it */}
      {/* clip a 100px rectangle off the top of the image */}
      <Box
        sx={{
          backgroundImage: `url(${img})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "200px",
          borderRadius: "5px 5px 0 0",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            // align the text to the center vertically
            display: "flex",
            alignItems: "center",
            paddingX: 2,
            height: "100%",
            textShadow: "0 0 5px #000",
            color: "white",
          }}
        >
          {challengeGroup.challenge_name}
        </Typography>
      </Box>

      {challengeGroup &&
        challenge_task_percentages &&
        challengeGroup.challenges.map((specific_challenge, index) => (
          <ChallengeCard
            key={index}
            challenge={specific_challenge}
            challenge_percentage={Math.floor(challenge_task_percentages[index])}
          />
        ))}
    </Paper>
  );
}
