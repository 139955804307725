import React, { useState, useContext } from "react";
import {
  Typography,
  Modal,
  Button,
  TextField,
  Box,
  Stack,
  Select,
  MenuItem,
  Snackbar,
  InputLabel,
  FormHelperText,
  FormControl,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useAxios from "../../utils/useAxios";
import { Label } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { fetchDashboardUsers } from "../Reducers/dashboardUserReducer";
import { fetchTeamData, fetchTeamRawData } from "../Reducers/teamReducer";
import AuthContext from "../../context/AuthContext";
import PersonIcon from "@mui/icons-material/Person";

export default function EditTeamModal({ team, employees }) {
  const api = useAxios();
  const [modalOpen, setModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(
    "Team Updated Successfully"
  );
  const [editMode, setEditMode] = useState(false);
  const [inputTeamName, setInputTeamName] = useState(team.team_name);
  const dispatch = useDispatch();
  const { user } = useContext(AuthContext);

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => {
    setModalOpen(false);
    setEditMode(false);
  };
  const handleSnackbarOpen = () => setSnackbarOpen(true);
  const handleSnackbarClose = () => setSnackbarOpen(false);

  const action = (
    <React.Fragment>
      <Button color="primary" size="small" onClick={handleSnackbarClose}>
        Close
      </Button>
    </React.Fragment>
  );

  const refreshTeams = () => {
    dispatch(fetchTeamData([api, user.company]));
    dispatch(fetchTeamRawData([user.company, api]));
  };

  const handleSave = () => {
    api
      .put(`/team/${team.id}/`, {
        team_name: inputTeamName,
      })
      .then((res) => {
        if (res.status === 200) {
          console.log("Team Updated Successfully");
          setSnackbarMessage("Team Updated Successfully");
          handleSnackbarOpen();
          handleModalClose();
          refreshTeams();
        }
      })
      .catch((err) => {
        console.log(err);
        setSnackbarMessage("Team Update Failed: " + err.message);
        handleSnackbarOpen();
        handleModalClose();
      });
  };

  const textFieldStyle = {
    width: "100%",
  };

  return (
    <>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={action}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      />

      <Modal open={modalOpen} onClose={handleModalClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: "5px",
            boxShadow: 24,
            p: 4,
          }}
        >
          <CloseIcon
            sx={{
              float: "right",
              cursor: "pointer",
            }}
            onClick={handleModalClose}
          />
          <Stack
            direction="column"
            justifyContent={"center"}
            alignItems={"center"}
            spacing={3}
            width={"100%"}
          >
            <Typography variant="h4" component="h2">
              Team Details
            </Typography>
            <TextField
              label="Team Name"
              variant="outlined"
              value={inputTeamName}
              onChange={(e) => setInputTeamName(e.target.value)}
              disabled={!editMode}
              sx={textFieldStyle}
            />
            <Stack
              direction="row"
              justifyContent={"center"}
              alignItems={"center"}
              spacing={1}
            >
              <PersonIcon />
              <Typography variant="caption" component="caption">
                {team.num_members} Members
              </Typography>
            </Stack>
            <Stack
              direction="column"
              spacing={1}
              sx={{
                overflowY: "scroll",
                borderRadius: "5px",
                border: "1px solid black",
                width: { xs: "100%", md: "100%" },
                height: { xs: "100px", md: "150px" },
              }}
            >
              {employees.map((employee) => {
                if (employee.team === team.id) {
                  return (
                    <Typography variant="caption" component="caption">
                      {employee.first_name} {employee.last_name}
                    </Typography>
                  );
                }
              })}
            </Stack>

            <Button
              // should say "edit" if editMode is false, "save" if editMode is true
              // when clicked, should toggle editMode and if the "save" button is clicked,
              // should update the device details in the database
              onClick={() => {
                if (editMode) {
                  handleSave();
                }
                setEditMode(!editMode);
                // console.log("deviceName: ", deviceName);
              }}
              sx={{ color: "white" }}
            >
              {editMode ? "Save" : "Edit"}
            </Button>
          </Stack>
        </Box>
      </Modal>
      <Button
        variant="contained"
        onClick={handleModalOpen}
        sx={{ color: "white" }}
      >
        Edit
      </Button>
    </>
  );
}
