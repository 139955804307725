import React from 'react'
import { ResponsiveContainer, AreaChart, XAxis, YAxis, Area, Tooltip, CartesianGrid} from "recharts";
import { Container } from '@mui/system';
import { useContext } from "react";
import ThemeContext from "../../context/ThemeContext";
export default function ErgoAreaChart({chartData, yDataKey, xDataKey}) {
  // dummy dataKey = "standingTime"
  // dummyChartData = [
  //   {
  //       "day": "3",
  //       "standingTime": 36,
  //       "sittingTime": 0
  //   },
  //   {
  //       "day": "4",
  //       "standingTime": 54,
  //       "sittingTime": 11
  //   },
  // ]
  const { theme } = useContext(ThemeContext);
  return (
    <>
    {/* Make the AreaChart size to the container size */}
      <ResponsiveContainer width="100%" height={400}>
        <AreaChart data={chartData} margin={{ top: 10, right: 10, left: 10, bottom: 10 }}>
          <defs>
            <linearGradient id="colorGrad" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="orange" stopOpacity={0.8}/>
              <stop offset="95%" stopColor="orange" stopOpacity={0}/>
            </linearGradient>
          </defs>
          <XAxis dataKey={xDataKey} stroke={
            theme === "light" ? "black" : "white"
          }/>
          <YAxis stroke={
            theme === "light" ? "black" : "white"
          }
            />
          <CartesianGrid strokeDasharray="3 3"/>
          <Tooltip 
            contentStyle={{
              backgroundColor: theme === "light" ? "#fff" : "#1e1e1e", 
            }}
          />
          <Area type="linear" dot={{strokeWidth: 2, fill: 'white'}} dataKey={yDataKey} baseLine={10} stroke="orange" strokeWidth={3} fillOpacity={1} fill="url(#colorGrad)" />
        </AreaChart>
      </ResponsiveContainer>
    </>
  )
}
