import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchDeskExportWithOptions = createAsyncThunk(
  "csv_export/fetchDeskExportWithOptions",
  async ([company_id, api]) => {
    let fetch_desk_export = `/desk_csv_export_for_company/${company_id}`;
    const response = await api.get(fetch_desk_export);
    const data = response.data
    return data;
  }
);


export const fetchBikeExportWithOptions = createAsyncThunk(
    "csv_export/fetchBikeExportWithOptions",
    async ([company_id, api]) => {
      let fetch_bike_export = `/bike_csv_export_for_company/${company_id}`;
      const response = await api.get(fetch_bike_export);
      const data = response.data
      return data;
    }
);

const exportDataSlice = createSlice({
  name: 'csv_export',
  initialState: {desk_export:[], bike_export:[]},
  reducers: {
    //this is not necessary, I just put it here for convention
    exportDataFetched(state, action) {
        state = action.payload;
    },
  },
    extraReducers(builder) {
      builder
        .addCase(fetchDeskExportWithOptions.pending, (state, action) => {
            //
        })
        .addCase(fetchDeskExportWithOptions.fulfilled, (state, action) => {
          state.desk_export = action.payload.result;
        })
        .addCase(fetchDeskExportWithOptions.rejected, (state, action) => {
          console.log("error in extra reducers (fetchDeskExportWithOptions)");
        })
        .addCase(fetchBikeExportWithOptions.pending, (state, action) => {
            //
        })
        .addCase(fetchBikeExportWithOptions.fulfilled, (state, action) => {
          state.bike_export = action.payload.result;
        })
        .addCase(fetchBikeExportWithOptions.rejected, (state, action) => {
          console.log("error in extra reducers (fetchBikeExportWithOptions)");
        })
    },
  
});

export const { exportDataFetched } = exportDataSlice.actions;
export default exportDataSlice.reducer;