import React from "react";
import { useContext, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {
  Grid,
  Box,
  Typography,
  Stack,
  Snackbar,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Paper,
} from "@mui/material";
import axios from "axios";
import { store, useSelector } from "react-redux";
import AuthContext from "../../context/AuthContext";
import EditHeightPresets from "../EditHeightPresetsComponent/EditHeightPresets";

/*
WHILE TESTING, GO HERE TO VERIFY MESSAGES ARE GOING THROUGH : 
https://us-east-1.console.aws.amazon.com/iot/home?region=us-east-1#/dashboard
*/

export default function ResetUpdateDeskcontrols() {
  const REACT_APP_API_GATEWAY_URL = String(
    process.env.REACT_APP_API_GATEWAY_URL
  );
  const REACT_APP_API_GATEWAY_KEY = String(
    process.env.REACT_APP_API_GATEWAY_KEY
  );
  const { loading, user } = useContext(AuthContext);
  const desk_devices = useSelector((store) => store.device.desks); //get all the desk devices and then filter (a new endpoint could be built for this, temporary workaround)
  const user_owned_desk_devices = desk_devices.filter(
    (d) => d.dashboard_user == user.user_id
  );

  let [deviceSerial, setDeviceSerial] = useState(""); // using this to test locally - (usdc_depa36egyn)

  /* SNACK BAR STUFF */
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const handleSnackbarOpen = () => setSnackbarOpen(true);
  const handleSnackbarClose = () => setSnackbarOpen(false);

  const handleDeviceSelectedChange = (e) => {
    setDeviceSerial(e.target.value);
  };

  function handleSendFirmwareUpdateMqttMessage() {
    setSnackbarMessage(`Will send message update firmware for selected device`);
    handleSnackbarOpen();

    let firmwareData = {
      topic: "usdc/updates", //should be usdc/preset once it's ready
      payload: {
        device_name: deviceSerial,
        device_serial: deviceSerial,
        version: "5", //Currently hardcoded
      },
    };

    console.log("sendFirmwareUpdateMqttMessage - data : ", firmwareData);

    let config = {
      headers: {
        "x-api-key": REACT_APP_API_GATEWAY_KEY,
      },
    };

    console.log("sendFirmwareUpdateMqttMessage - config : ", config);

    axios
      .post(REACT_APP_API_GATEWAY_URL, firmwareData, config)
      .then((response) => {
        console.log("response.data : ", JSON.stringify(response.data));
        console.log("Success publishing the message :) ");
      })
      .catch((error) => {
        console.log("Error publishing the message :( ");
        console.log(error);
      });
  }
  function handleSendResetMqttMessage() {
    setSnackbarMessage(
      `Will send message to firmare update for selected device`
    );
    handleSnackbarOpen();

    let resetData = {
      topic: "usdc/updates", //should be usdc/preset once it's ready
      payload: {
        device_name: deviceSerial,
        device_serial: deviceSerial,
        reset: true,
      },
    };

    console.log("sendResetChangeMqttMessage - data : ", resetData);

    let config = {
      headers: {
        "x-api-key": REACT_APP_API_GATEWAY_KEY,
      },
    };

    console.log("sendResetChangeMqttMessage - config : ", config);

    axios
      .post(REACT_APP_API_GATEWAY_URL, resetData, config)
      .then((response) => {
        console.log("response.data : ", JSON.stringify(response.data));
        console.log("Success publishing the message :) ");
      })
      .catch((error) => {
        console.log("Error publishing the message :( ");
        console.log(error);
      });
  }

  function alertDeviceNotSet() {
    setSnackbarMessage(`Device not set, can't proceed - please set a device`);
    handleSnackbarOpen();
  }

  return (
    <div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        //action={action}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      />
      <>
        <Paper
          sx={{
            borderRadius: "25px",
            padding: "1rem",
          }}
        >
          <Grid container p={3} spacing={3}>
            <Grid item xs={12} sm={6} p={2}>
              <Stack spacing={2}>
                <Typography variant="h2">Reset and Firmware Update</Typography>

                <Box
                  sx={{
                    borderRadius: "5px",
                    backgroundColor: "rgba(255, 166, 41, 0.5)",
                    padding: "0.5rem",
                    maxWidth: "sm",
                  }}
                >
                  <Typography>Currently in testing 🧪!</Typography>
                </Box>
              </Stack>
              <Stack spacing={2} marginTop={2} sx={{ maxWidth: "sm" }}>
                <Stack spacing={2}>
                  <FormControl fullWidth>
                    <InputLabel id="move-device-dropdown-label">
                      SELECT DEVICE
                    </InputLabel>
                    <Select
                      labelId="move-device-dropdown-label"
                      id="demo-simple-select"
                      value={deviceSerial}
                      label="Device To Move"
                      onChange={handleDeviceSelectedChange}
                    >
                      {user_owned_desk_devices.map((device) => (
                        <MenuItem
                          key={device.serial_number}
                          value={device.serial_number}
                        >
                          {device.device_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Stack direction="row" spacing={2}>
                    <Button
                      onClick={handleSendFirmwareUpdateMqttMessage}
                      sx={{ width: 200, color: "white" }}
                    >
                      Firmware Update
                    </Button>
                    <Button
                      onClick={handleSendResetMqttMessage}
                      sx={{ width: 200, color: "white" }}
                    >
                      Reset Desk
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </>
    </div>
  );
}
