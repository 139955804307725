import ForgotPassword from "../components/ForgotPassword/ForgotPassword";

const ForgotPage = () => {

  return (
    <div>
        <ForgotPassword/>
    </div>
  );
};

export default ForgotPage;