import React from 'react'
import styles from './ErgoButton.module.scss';


export default function ErgoButton({buttonStyle, buttonText, buttonTextStyle, buttonValue, disabled, handleClick}) {
  if(buttonStyle === undefined){
    buttonStyle = styles.defaultButtonStyle;
  }
  if(buttonTextStyle === undefined){
    buttonTextStyle = styles.buttonTextStyle;
  }
  if(buttonValue === undefined){
    buttonValue = "default_button_value";
  }
  return (
    <div>
        <button onClick={handleClick} disabled={disabled} className={buttonStyle} value={buttonValue}>
            <p className={buttonTextStyle} value={buttonValue}>{buttonText}</p>
        </button>
    </div>
  )
}
