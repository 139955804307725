import React from 'react'
import { useState, useContext } from 'react';
import AuthContext from "../context/AuthContext";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import ErgoButton from './ErgoButton/ErgoButton';






export default function RegistrationForm() {
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [company, setCompany] = useState("");
    const [team, setTeam] = useState("");

    const { registerUser } = useContext(AuthContext);

    const handleSubmit = async e => {
      e.preventDefault();
      registerUser(username, password, email, company, team);
    };

  return (
    <div>
        <form onSubmit={handleSubmit}>
           <h1>Register</h1>
        <hr />
        <Container maxWidth='sm'>

        <Stack direction="column" spacing={2}>
        <TextField 
          label="Username" 
          variant="outlined" 
          required 
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField 
          label="Email" 
          variant="outlined" 
          required 
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField 
          label="Company" 
          variant="outlined" 
          required 
          onChange={(e) => setCompany(e.target.value)}
        />
        <TextField 
          label="Team" 
          variant="outlined" 
          required 
          onChange={(e) => setTeam(e.target.value)}
        />
        <TextField 
          label="Password" 
          variant="outlined" 
          required 
          onChange={(e) => setPassword(e.target.value)}
        />
        <TextField 
          label="Password2" 
          variant="outlined" 
          required 
          onChange={(e) => setPassword2(e.target.value)}
        />
        <p>{password2 !== password ? "Passwords do not match" : ""}</p> 
        <Button variant="contained" type='submit'>Register</Button>
        </Stack>
        </Container>
        </form><ErgoButton buttonText={'submit'}/>
        
    </div>
  )
}
