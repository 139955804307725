// import React, { useContext, useEffect, useState } from "react";
// import { useSelector } from "react-redux";
// import { Box, Grid } from "@mui/material";
// import RewardGroup from "../RewardGroup/RewardGroup";
// import { useDispatch } from "react-redux";
// import { fetchChallengeData } from "../Reducers/challengeReducer";
// import { fetchCompletedChallengeData } from "../Reducers/challengeReducer";
// import AuthContext from "../../context/AuthContext";
// import useAxios from "../../utils/useAxios";

// const Rewards = (props) => {

//   const api = useAxios()//added because using axios intercepts the requests and refreshes the token !

//   const { user } = useContext(AuthContext);
//   const dispatch = useDispatch();

//   const completed_challenges = useSelector(
//     (dashboardStore) => dashboardStore.challenge.completed_challenges
//   );
//   const challenges = useSelector(
//     (dashboardStore) => dashboardStore.challenge.challenges
//   );

//   const [
//     current_dashboard_user_completed_challenges,
//     setCurrentDashboardUserCompletedChallenges,
//   ] = useState([]);
//   const [
//     full_information_completed_challenges,
//     setFullInformationCompletedChallenges,
//   ] = useState([]);
//   const [challengeGroups, setChallengeGroups] = useState();

//   // Data fetching
//   useEffect(() => {
//     if (user) {
//       dispatch(fetchChallengeData(api));
//       dispatch(fetchCompletedChallengeData(api));
//     }
//   }, [user]);

//   // useEffect to set values retrieved from the store
//   useEffect(() => {
//     if (completed_challenges && challenges && user) {
//       setCurrentDashboardUserCompletedChallenges(
//         completed_challenges.filter(
//           (challenge) => challenge.dashboard_user === user.user_id
//         )
//       );
//     }
//   }, [completed_challenges, challenges, user]);

//   // useEffect to set information for completed challenges
//   useEffect(() => {
//     setFullInformationCompletedChallenges(
//       challenges.filter((challenge) => {
//         // if there is a matching challenge in current_dashboard_user_completed_challenges with the same challenge_group and task_id, then return true
//         return current_dashboard_user_completed_challenges.some(
//           (completed_challenge) =>
//             completed_challenge.challenge_id === challenge.challenge_group &&
//             completed_challenge.task_id === challenge.task_id
//         );
//       })
//     );
//   }, [challenges, current_dashboard_user_completed_challenges]);

//   // useEffect to set challenge groups from completed challenges
//   useEffect(() => {
//     if (
//       full_information_completed_challenges &&
//       full_information_completed_challenges.length > 0
//     ) {
//       setChallengeGroups(
//         full_information_completed_challenges.reduce((acc, challenge) => {
//           const { challenge_group, challenge_name } = challenge;
//           if (acc[challenge_group]) {
//             acc[challenge_group].challenges.push(challenge);
//           } else {
//             acc[challenge_group] = {
//               challenge_group: challenge_group,
//               challenges: [challenge],
//               challenge_name: challenge_name,
//             };
//           }
//           return acc;
//         }, {})
//       );
//     }
//   }, [full_information_completed_challenges]);

//   return (
//     <>
//       {challengeGroups && (
//         <Box padding={2}>
//           {/* Parent Grid */}
//           <Grid container spacing={3}>
//             {/* Map each reward to a RewardCard */}
//             {Object.values(challengeGroups).map((challengeGroup) => (
//               <Grid item key={challengeGroup.challenge_group}>
//                 <RewardGroup challengeGroup={challengeGroup} />
//               </Grid>
//             ))}
//           </Grid>
//         </Box>
//       )}
//     </>
//   );
// };

// export default Rewards;

import { borderColor, Box } from "@mui/system";
import React, { useContext, useState, useEffect } from "react";
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Paper,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import RewardGroup from "../../components/RewardGroup/RewardGroup";
import useAxios from "../../utils/useAxios";
import {
  fetchChallengeData,
  fetchCompletedChallengeData,
} from "../../components/Reducers/challengeReducer";
import AuthContext from "../../context/AuthContext";

const Rewards = () => {
  const api = useAxios();

  const challenges = useSelector(
    (dashboardStore) => dashboardStore.challenge.challenges
  );
  const completed_challenges = useSelector(
    (dashboardStore) => dashboardStore.challenge.completed_challenges
  );

    const [
    current_dashboard_user_completed_challenges,
    setCurrentDashboardUserCompletedChallenges,
  ] = useState([]);
  const [
    full_information_completed_challenges,
    setFullInformationCompletedChallenges,
  ] = useState([]);

  const [selectedChallengeGroup, setSelectedChallengeGroup] = useState(0);
  const [displayedChallenges, setDisplayedChallenges] = useState([]);
  const [challengeGroups, setChallengeGroups] = useState();

  const handleChallengeGroupChange = (event) => {
    setSelectedChallengeGroup(event.target.value);
  };

  const dispatch = useDispatch();
  const { user } = useContext(AuthContext);

  // This useEffect is for data fetching
  useEffect(() => {
    if (user) {
      if (challenges.length === 0) {
        dispatch(fetchChallengeData(api));
      }
      dispatch(fetchCompletedChallengeData(api));
    }
  }, [user]);

  // useEffect to set values retrieved from the store
  useEffect(() => {
    if (completed_challenges && challenges && user) {
      setCurrentDashboardUserCompletedChallenges(
        completed_challenges.filter(
          (challenge) => challenge.dashboard_user === user.user_id
        )
      );
    }
  }, [completed_challenges, challenges, user]);

  // useEffect to set information for completed challenges
  useEffect(() => {
    setFullInformationCompletedChallenges(
      challenges.filter((challenge) => {
        // if there is a matching challenge in current_dashboard_user_completed_challenges with the same challenge_group and task_id, then return true
        return current_dashboard_user_completed_challenges.some(
          (completed_challenge) =>
            completed_challenge.challenge_id === challenge.challenge_group &&
            completed_challenge.task_id === challenge.task_id
        );
      })
    );
  }, [challenges, current_dashboard_user_completed_challenges]);

  // useEffect to set challenge groups from completed challenges
  useEffect(() => {
    if (
      full_information_completed_challenges &&
      full_information_completed_challenges.length > 0
    ) {
      setChallengeGroups(
        full_information_completed_challenges.reduce((acc, challenge) => {
          const { challenge_group, challenge_name } = challenge;
          if (acc[challenge_group]) {
            acc[challenge_group].challenges.push(challenge);
          } else {
            acc[challenge_group] = {
              challenge_group: challenge_group,
              challenges: [challenge],
              challenge_name: challenge_name,
            };
          }
          return acc;
        }, {})
      );
    }
  }, [full_information_completed_challenges]);

  useEffect(() => {

      setDisplayedChallenges(
        full_information_completed_challenges.filter((c) => c.challenge_group === selectedChallengeGroup)
      );
  }, [selectedChallengeGroup, challengeGroups]);

  return (
    <Box
      sx={{
        height: "150%",
      }}
    >
      {displayedChallenges && (
        <Box padding={2} maxWidth={"md"} marginX="auto">
          {/* TEMPORARY DROPDOWN, THIS IS A PLACEHOLDER TO WHICHEVER WAY THE TEAM WANTS TO SWITCH BETWEEN CHALLENGE GROUPS */}
          <Paper sx={{ padding: 2, mt: 2 }}>
            <FormControl fullWidth>
              <InputLabel
                id="demo-simple-select-label"
                variant="outlined"
              >
                Challenge Group
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedChallengeGroup}
                label="currentChallengeGroup"
                onChange={handleChallengeGroupChange}
              >
                <MenuItem value={0}>Getting Started</MenuItem>
                <MenuItem value={1}>Get Connected</MenuItem>
                <MenuItem value={2}>Keep Moving</MenuItem>
                <MenuItem value={3}>Burn It Off</MenuItem>
                <MenuItem value={4}>New Heights</MenuItem>
                <MenuItem value={5}>Record Setter</MenuItem>
              </Select>
            </FormControl>

            {displayedChallenges.length > 0 && (
              <RewardGroup challenges={displayedChallenges} />
            )}
          </Paper>
        </Box>
      )}
    </Box>
  );
};

export default Rewards;
