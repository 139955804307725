import { useDispatch } from "react-redux";
import useAxios from "../../utils/useAxios"
import AuthContext from "../../context/AuthContext";
import { useContext, useEffect } from "react";
// import all reducers and evaluate the response data 
// if response data isn't as expected, go investigate

import { fetchChallengeData, fetchCompletedChallengeData, fetchChallengePercentages } from "../Reducers/challengeReducer";
import { fetchCompanyData, fetchAllCompanies, fetchCompanySettings } from "../Reducers/companyReducer" //missing - updateCompanyChallengeGroups
import { fetchDashboardUsers, fetchCurrentDashboardUser, fetchAllDashboardUsers, fetchBikeAnalytics, fetchDeskAnalytics} from "../Reducers/dashboardUserReducer"
import { fetchDeskDevices, fetchDeviceRequests, fetchBikeDevices, fetchAllDevices} from "../Reducers/deviceReducer"
import { fetchHourlyBikeUpsert } from "../Reducers/hourlyBikeUpsertReducer"
import { fetchHourlyDeskUpsert } from "../Reducers/hourlyDeskUpsertReducer"
import { fetchUserLeaderboard, fetchTeamLeaderboard} from "../Reducers/leaderboardReducer"
import { fetchStreakData } from "../Reducers/streakReducer"
import { fetchTeamData, fetchTeamRawData, fetchMonthlyTeamActivity, fetchAllTeams } from "../Reducers/teamReducer"

import ChallengeDataMonitoring from "./ChallengeDataMonitoring";
import CompanyDataMonitoring from "./CompanyDataMonitoring";
import DashboardUserDataMonitoring from "./DashboardUserDataMonitoring";
import DeviceDataMonitoring from "./DeviceDataMonitoring";
import HourlyBikeUpsertDataMonitoring from "./HourlyBikeUpsertDataMonitoring";
import HourlyDeskUpsertDataMonitoring from "./HourlyDeskUpsertDataMonitoring";
import LeaderboardDataMonitoring from "./LeaderboardDataMonitoring";
import StreakDataMonitoring from "./StreakDataMonitoring";
import TeamDataMonitoring from "./TeamDataMonitoring";

export default function DataMonitoringPage() {

  const api = useAxios()
  const dispatch = useDispatch()
  const { user, loading } = useContext(AuthContext);

  //this is too bloated, is there a better way ? dispatch on button click ? who knows
  useEffect(() => {
    if (!loading) {
      if (user) {
        
        console.log("user :", user)

        //challenge data fetching
        dispatch(fetchChallengeData(api));
        dispatch(fetchCompletedChallengeData(api));
        dispatch(fetchChallengePercentages([api, user.user_id]));

        //company data fetching
        dispatch(fetchCompanyData([api, user.company]));
        dispatch(fetchAllCompanies(api));
        dispatch(fetchCompanySettings([api, user.company]));

        //dashboard user data fetching
        dispatch(fetchDashboardUsers([user.company, api]))
        dispatch(fetchCurrentDashboardUser([user.user_id, api]))
        dispatch(fetchAllDashboardUsers(api))
        dispatch(fetchBikeAnalytics(api))
        dispatch(fetchDeskAnalytics(api))

        //device data fetching
        dispatch(fetchDeskDevices([api,user.company]))
        dispatch(fetchDeviceRequests([api,user.company]))
        dispatch(fetchBikeDevices([api,user.company]))
        dispatch(fetchAllDevices(api))

        //hourly bike upsert fetching
        dispatch(fetchHourlyBikeUpsert([user.company, api]))

        //hourly desk upsert fetching
        dispatch(fetchHourlyDeskUpsert([user.company, api]))

        //leaderboard data fetching
        dispatch(fetchUserLeaderboard([user.company, api]))
        dispatch(fetchTeamLeaderboard([user.company, api]))

        //streak data fetching
        dispatch(fetchStreakData([user.user_id, api]))

        //team data fetching
        dispatch(fetchTeamData([api, user.company])) //teams 
        dispatch(fetchTeamRawData([user.company, api])) //teams_raw
        dispatch(fetchMonthlyTeamActivity(api)) //team_monthly_activity
        dispatch(fetchAllTeams(api)) //admin_teams

      }
    }
  }, [api,loading, user]);

  return (
    <>
      <ChallengeDataMonitoring/>{/* use useSelector in here to get the data and verify that it contains data (non-empty!) */}
      <CompanyDataMonitoring/>
      <DashboardUserDataMonitoring/>
      <DeviceDataMonitoring />
      <HourlyBikeUpsertDataMonitoring />
      <HourlyDeskUpsertDataMonitoring />
      <LeaderboardDataMonitoring />
      <StreakDataMonitoring />
      <TeamDataMonitoring />
    </>
  );
}
