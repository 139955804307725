import React, {useState} from "react";
import {
  Typography,
  Modal,
  Button,
  TextField,
  Box,
  Stack,
  Select,
  MenuItem,
  Snackbar,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useAxios from "../../utils/useAxios";
import { useDispatch } from "react-redux";
import { fetchAllDevices } from "../Reducers/deviceReducer";
export default function AdminDeviceModal({ device, companies }) {
  /*
        adminDeviceModal returns a button that will open a modal with the device details
        when clicked. The modal contain pre-populated fields with the device details. When
        the fields are changed, a save button will appear. When the save button is clicked,
        the device details will be updated in the database.

        This component exclusively uses MUI v5 components.
    */
  const api = useAxios();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [inputDeviceName, setInputDeviceName] = useState(device.device_name);
  const [selectedCompany, setSelectedCompany] = useState(device.company);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setEditMode(false);
  }
  const [editMode, setEditMode] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("Device Updated Successfully");
    const handleSnackbarOpen = () => setSnackbarOpen(true);
    const handleSnackbarClose = () => setSnackbarOpen(false);
    const action = (
        <React.Fragment>
            <Button color="primary" size="small" onClick={handleSnackbarClose}>
                Close
            </Button>
        </React.Fragment>
    );
    const refreshDevices = () => {
        dispatch(fetchAllDevices(api));
    };

    const handleSave = () => {
      api.put(`/device/${device.id}/`, {
        // if the company is changing, we need to set the dashboard_user_id to null
        device_name: inputDeviceName,
        company: companies.find((company) => company.company_name === selectedCompany).id,
        dashboard_user: selectedCompany === device.company ? device.dashboard_user : null
      }).then((res) => {
        if (res.status === 200) {
          console.log("Device Updated Successfully")
          setSnackbarMessage("Device Updated Successfully");
          handleSnackbarOpen();
          handleClose();
          refreshDevices();
        } 

        }).catch((err) => {
          console.log(err);
          setSnackbarMessage("Device Update Failed: " + err.message);
          handleSnackbarOpen();
          handleClose();
        });

    };

  return (
    <>
    <Snackbar
        // position snackbar absolute to the top of the screen and centered
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        action={action}
    />
    
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          {/* close button in top left with 5 padding */}
          <CloseIcon 
            sx={{position: "absolute", top: 10, left: 10}}
            onClick={() => {
                handleClose();
                setEditMode(false);
              }
            }

          />
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Typography variant="h4" mb={2}>
              Device Details
            </Typography>
            <TextField
              id="outlined-basic"
              label="Device Name"
              variant="outlined"
              fullWidth
              mb={2}
              disabled={!editMode}
              value={inputDeviceName}
              onChange={(e) => setInputDeviceName(e.target.value)}
            />
            <TextField
              id="outlined-basic"
              label="Device ID"
              variant="outlined"
              fullWidth
              mb={2}
              defaultValue={device.id}
              disabled
            />
            <TextField
              id="outlined-basic"
              label="Device Serial Number"
              variant="outlined"
              fullWidth
              mb={2}
              defaultValue={device.serial_number}
              disabled
            />
            <Select
              labelId="select-device-company"
              id="select-device-company"
              label="Company"
              fullWidth
              mb={2}
              value={selectedCompany}
              disabled={!editMode}
              onChange={(e) => setSelectedCompany(e.target.value)}
            >
              {companies.map((company) => (
                <MenuItem key={company.id} value={company.company_name}>
                  {company.company_name}
                </MenuItem>
              ))}

            </Select>
            <Button
              // should say "edit" if editMode is false, "save" if editMode is true
              // when clicked, should toggle editMode and if the "save" button is clicked,
              // should update the device details in the database
              onClick={() => {
                if (editMode) {
                  handleSave();
                }
                setEditMode(!editMode);
                // console.log("deviceName: ", deviceName);
              }}
              sx={{color:"white"}}
            >
              {editMode ? "Save" : "Edit"}
            </Button>
          </Stack>
        </Box>
      </Modal>
      <Button
        variant="contained"
        onClick={handleOpen}
        // text color is white
        sx={{ color: "white" }}
      >
        Settings
      </Button>
    </>
  );
}
