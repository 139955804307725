import {atom} from "jotai";

export const activeChallengeGroupsAtom = atom({
    0: true,
    1: true,
    2: true,
    3: true,
    4: true,
    5: true,
});

export const pendingChangesAtom = atom(false);