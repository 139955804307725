import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from "recharts";
import { useContext } from "react";
import ThemeContext from "../../context/ThemeContext";
export default function ErgoLineChart({ data, margin, stroke, legend}) {
  const { theme } = useContext(ThemeContext);
  const strokeColors = ["#F4766E", "#6EB4F4", "#FFAF51", "#F4766E"];
  if (!margin) {
    margin = { top: 5, right: 15, bottom: 5 };
  }
  // console.log(data)
  return (
    <>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          height="100%"
          width="100%"
          data={data}
          margin={margin}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis >

            <Label
              value="Active Minutes"
              angle={-90}
              position="insideLeft"
              style={{ textAnchor: "middle", fill: theme === "light" ? "#000" : "#fff" }}
            />
          </YAxis>
          <Tooltip 
            contentStyle={{
              backgroundColor: theme === "light" ? "#fff" : "#1e1e1e", 
            }}
          />
          
           { legend && <Legend
            height={25}
            iconSize={20}
            iconType="square"
            wrapperStyle={{ fontSize: "1rem", fontWeight: "bold" }}
          />}
          {Object.keys(data[0]).map((key, index) => {
            if (key !== "date") {
              return (
                <Line
                  key={index}
                  type="monotone"
                  dataKey={key}
                  stroke={
                    stroke ? stroke : strokeColors[index]}
                  strokeWidth={"0.3rem"}
                  activeDot={{ r: 8 }}
                />
              );
            }
          })}
        </LineChart>
      </ResponsiveContainer>
    </>
  );
}
