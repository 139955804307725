import React, { useState } from "react";
import {
  Typography,
  Modal,
  Button,
  TextField,
  Box,
  Stack,
  Select,
  MenuItem,
  Snackbar,
  FormLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useAxios from "../../utils/useAxios";
import { useDispatch } from "react-redux";
import { fetchAllDashboardUsers } from "../Reducers/dashboardUserReducer";

export default function AdminEditEmployeeModal({ employee, companies }) {
  /*
        adminEditEmployeeModal returns a button that will open a modal with the device details
        when clicked. The modal contain pre-populated fields with the device details. When
        the fields are changed, a save button will appear. When the save button is clicked,
        the device details will be updated in the database.

        This component exclusively uses MUI v5 components.
    */
  const api = useAxios();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setEditMode(false);
  };
  const [editMode, setEditMode] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(
    "Employee Updated Successfully"
  );
  const handleSnackbarOpen = () => setSnackbarOpen(true);
  const handleSnackbarClose = () => setSnackbarOpen(false);
  const action = (
    <React.Fragment>
      <Button color="primary" size="small" onClick={handleSnackbarClose}>
        Close
      </Button>
    </React.Fragment>
  );

  const roles = [
    { id: 1, user_type: "Team Member" },
    { id: 2, user_type: "Company Admin" },
    { id: 3, user_type: "Super Admin" },
  ];

  const [inputEmail, setInputEmail] = useState(employee.email);
  const [inputFirstName, setInputFirstName] = useState(employee.first_name);
  const [inputLastName, setInputLastName] = useState(employee.last_name);
  const [selectedCompany, setSelectedCompany] = useState(employee.company_name);
  //
  const [selectedRole, setSelectedRole] = useState(employee.user_type);
  const refreshUsers = () => {
    dispatch(fetchAllDashboardUsers(api));
  };

  console.log("companies: ", companies);
  const handleSave = () => {
    api
      .put(`/dashboard_user/${employee.id}/`, {
        email: inputEmail,
        first_name: inputFirstName,
        last_name: inputLastName,
        company: companies.find(
          (company) => company.company_name === selectedCompany
        ).id,
        // find the id of the role that matches the selected role
        user_type: roles.find((role) => role.user_type === selectedRole).id,
      })
      .then((res) => {
        if (res.status === 200) {
          console.log("User Updated Successfully");
          setSnackbarMessage("User Updated Successfully");
          handleSnackbarOpen();
          handleClose();
          refreshUsers();
        }
      })
      .catch((err) => {
        console.log(err);
        setSnackbarMessage("User Update Failed: " + err.message);
        handleSnackbarOpen();
        handleClose();
      });
  };

  return (
    <>
      <Snackbar
        // position snackbar absolute to the top of the screen and centered
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        action={action}
      />

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          {/* close button in top left with 5 padding */}
          <CloseIcon
            sx={{ position: "absolute", top: 10, left: 10 }}
            onClick={() => {
              handleClose();
              setEditMode(false);
            }}
          />
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Typography variant="h4" mb={2}>
              User Details
            </Typography>
            <TextField
              id="outlined-basic"
              label="Email"
              variant="outlined"
              fullWidth
              mb={2}
              disabled={!editMode}
              value={inputEmail}
              onChange={(e) => setInputEmail(e.target.value)}
            />
            <TextField
              id="outlined-basic"
              label="First Name"
              variant="outlined"
              fullWidth
              mb={2}
              disabled={!editMode}
              value={inputFirstName}
              onChange={(e) => setInputFirstName(e.target.value)}
            />
            <TextField
              id="outlined-basic"
              label="Last Name"
              variant="outlined"
              fullWidth
              mb={2}
              disabled={!editMode}
              value={inputLastName}
              onChange={(e) => setInputLastName(e.target.value)}
            />
            {/* label */}
            <FormLabel component="legend">Company</FormLabel>
            <Select
              labelId="select-user-company"
              id="select-user-company"
              label="Company"
              fullWidth
              mb={2}
              value={selectedCompany}
              disabled={!editMode}
              onChange={(e) => setSelectedCompany(e.target.value)}
            >
              {companies.map((company) => (
                <MenuItem key={company.id} value={company.company_name}>
                  {company.company_name}
                </MenuItem>
              ))}
            </Select>
            {/* label */}
            <FormLabel component="legend">User Type</FormLabel>
            <Select
              labelId="select-user-type"
              id="select-user-type"
              label="Role"
              fullWidth
              mb={2}
              value={selectedRole}
              disabled={!editMode}
              onChange={(e) => setSelectedRole(e.target.value)}
            >
              {roles.map((role) => (
                <MenuItem key={role.id} value={role.user_type}>
                  {role.user_type}
                </MenuItem>
              ))}
            </Select>

            <Button
              // should say "edit" if editMode is false, "save" if editMode is true
              // when clicked, should toggle editMode and if the "save" button is clicked,
              // should update the device details in the database
              onClick={() => {
                if (editMode) {
                  handleSave();
                }
                setEditMode(!editMode);
              }}
              sx={{ color: "white" }}
            >
              {editMode ? "Save" : "Edit"}
            </Button>
          </Stack>
        </Box>
      </Modal>
      <Button
        variant="contained"
        onClick={handleOpen}
        // text color is white
        sx={{ color: "white" }}
      >
        Settings
      </Button>
    </>
  );
}
