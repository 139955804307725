import { AppBar, Box, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { createTheme } from "@mui/material";
import React from "react";
import { format } from "date-fns";
import styles from "./Appbar.module.scss";
import { useHistory, useLocation } from "react-router-dom";

const drawerWidth = 240;

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 960,
      md: 960,
      lg: 1200,
      xl: 1536,
    },
  },
});

const useStyles = makeStyles((theme) => {
  return {
    appbar: {
      [theme.breakpoints.up('md')]: {
        width: `calc(100% - ${drawerWidth}px) !important`,
      }, 
      [theme.breakpoints.down('md')]: {
        width: `100%`,
      }, 
      backgroundColor: "white !important",
      marginBottom: 10,
    },
  };
});

export default function Appbar(props) {
  const classes = useStyles(theme);
  const history = useHistory();
  const location = useLocation();
  
  return (
    <div className={styles.container}>
      <AppBar className={classes.appbar}>
        <div className={styles.header}>
          <p className={styles.companyHeader}>{props.page}</p>
          <p className={styles.companyTimeUpdated}>
            Last Updated on {format(new Date(), "do MMMM Y")}
          </p>
        </div>
        <Box sx={{ flexGrow: 4, display: { xs: "none", md: "flex" } }}>
          {props.menuItems.map((item) => (
            <Button
              key={item.text}
              onClick={() => history.push(item.path)}
              className={
                item.paths.includes(location.pathname)
                  ? styles.appBarBtnActive
                  : styles.appBarBtn
              }
              sx={{
                marginRight: `30px`,
                padding: `15px 30px`,
                display: `block`,
                backgroundColor: `transparent`,
                boxShadow: `none`,
                textTransform: `none`,
                borderRadius: `0`,
              }}
            >
              <p className={styles.buttonText}>{item.text}</p>
            </Button>
          ))}
        </Box>
      </AppBar>
    </div>
  );
}
