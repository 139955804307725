import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

//to use this, you'll have to do 
// dispatch(fetchDashboardUserRoutines(dashboard_user.id, api))
export const fetchDashboardUserRoutines = createAsyncThunk(
  "routine/fetchDashboardUserRoutines",
  async ([dashboard_user_id, api]) => {
    let get_routines_by_dashboard_user = `/routine/get_by_dashboard_user/${dashboard_user_id}/`;
    const response = await api.get(get_routines_by_dashboard_user);
    const data = response.data
    return data;
  }
);


const routineDataSlice = createSlice({
  name: 'routine',
  initialState: {routines:[]},
  reducers: {
    routineDataFetched(state, action) {
      state.push({
        id: action.payload.id,
        routine_name : action.payload.routine_name,
      })
    },
  },
    extraReducers(builder) {
      builder
        .addCase(fetchDashboardUserRoutines.pending, (state, action) => {
          //
        })
        .addCase(fetchDashboardUserRoutines.fulfilled, (state, action) => {
          state.routines = action.payload;
        })
        .addCase(fetchDashboardUserRoutines.rejected, (state, action) => {
          console.log("error in extra reducers (fetchDashboardUserRoutines)");
        });
    },
  
});

export const { routineDataFetched } = routineDataSlice.actions;
export default routineDataSlice.reducer;