
function ProtectedPage() {

  return (
    <div>
      <h1>Protected Page</h1>
    </div>
  );
}

export default ProtectedPage;